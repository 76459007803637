const applicationMode = "development";

const googleAnalyticsIdMapping = {
  production: "UA-117486528-1",
  development: "UA-117486528-1",
};

const messages = {
  uiErrorMessage: "Something went wrong! Please try again.",
  noTitle: "No title available",
  getDateRangeFailed:
    "Fetching Date Range Failed. Please retry after sometime!",
  // getDateRangeFailed:
  //   "We are experiencing some issues in our Query service, Please retry after sometime!",
  getMetricsListFailed:
    "Fetching metrics list failed. Please retry after sometime.",
  getCustomMetricsListFailed:
    "Fetching custom metrics list failed. Please retry after sometime.",
  getDimensionsListFailed:
    "Fetching dimensions list failed. Please retry after sometime.",
  getUserFailed: "Fetching user details failed. Please retry after sometime.",
  getUserAccessListFailed:
    "Fetching user access details failed. Please retry after sometime.",
  duplicateReportError: "Report name already exists in saved reports",
  dsNotAvailable: "Datastory not available for current selection",
  noCharts: "No charts available",
  noGroups: "No groups available",
  noUsers: "No users available",
  noDs: "No datastory available",
  noWs: "No saved workbooks available",
  plotDsUnavailable:
    "Seems like you don't have access to this feature. Please contact your admin.",
  featureUnavailable:
    "Seems like you don't have access to this feature. Please contact your admin.",
  clickToAddChartsCreateDs: "Click on a chart to select",
  noWidgets: "No charts or KPIs added to this datastory",
  rollingDates: "Dates change automatically everyday as per selected presets",
  reports: {
    savedRequest: "Requesting to save report...",
    savedSuccess: "Report has been saved successfully!",
    savedFailure: "Report addition failed!",
    scheduledRequest: "Requesting to schedule report...",
    scheduledSuccess: "Report has been scheduled successfully!",
    scheduledFailure: "Report schedule failed!",
    downloadReportRequest: "Download in progress...",
    downloadReportSuccess: "Report downloaded successfully!",
    downloadReportFailure: "Report download failed!",
    reportOverEmailRequest: "Requesting report over email...",
    reportOverEmailSuccess: "Report emailed successfully!",
    reportOverEmailFailed: "Report over email failed!",
    reportLinkOverEmailRequest: "Requesting report link over email...",
    reportLinkOverEmailSuccess: "Report link emailed successfully!",
    reportLinkOverEmailFailed: "Report link over email failed!",
    reportUpdateRequest: "Requesting updating report...",
    reportUpdateSuccess: "Report updated successfully!",
    reportUpdateFailed: "Report update failed!",
    reportDeleteRequest: "Requesting deleting report...",
    reportDeleteSuccess: "Report deleted successfully!",
    reportDeleteFailed: "Report deletion failed!",
    reportDeleteConfirmation: "Are you sure you want to delete?",
  },
  pivot: {
    noPivot: "No pivots available",
  },
};

const dialogInfo = {
  filtersClose: {
    title: "Discard Changes?",
    subtitle: "This action cannot be undone",
  },
};

const payloadHardCoded = {
  getDataMetricMetadata: {
    title: "",
    chartType: "chart",
    img_thumbnail: "img/chart.png",
  },
};

const joyrideTourSettings = {
  continuous: true,
  locale: {
    back: "Prev",
    close: "Close",
    last: "Finish",
    next: "Next",
    skip: "Exit",
  },
  disableScrollParentFix: false,
  showProgress: true,
  spotlightPadding: 5,
  debug: false,
  disableOverlayClose: true,
  disableCloseOnEsc: true,
  hideCloseButton: true, //didn't work
  showSkipButton: true,
  scrollToFirstStep: false,
  styles: {
    options: {
      beaconSize: 36,
      arrowColor: "#fff",
      primaryColor: "#52baed",
      zIndex: 99999999,
    },
  },
  scrollOffset: 5,
  floaterProps: {
    hideArrow: false,
    placement: "auto",
  },
};

const allTitlesMapping = {
  home: "Homepage",
  dashboard: "Dashboard",
};

const keepStatusTextList = [
  "Upload failed as the file is empty",
  "Upload failed as the file has multiple columns",
  "Upload failed as the file is empty",
  "Upload failed as file size exceeds 1MB",
  "Upload failed as file contains more than 2000 values",
  "File not written into bq table",
  "corrupt File",
  "Requested report name already exists",
  "Upload failed as multiple columns were detected in file",
  "Does not match current password!",
  "Request can't be processed",
];

const includeExcludeJson = [
  { id: "include", name: "Include" },
  { id: "exclude", name: "Exclude" },
];

const initialDimensionData = {
  data: null,
  isLoading: true,
  error: null,
};

const initialFileData = {
  isLoading: false,
  error: false,
  data: null,
};

const adFiltersMaxFileSize = 1 * 1024 * 1024; //1MB
const adFiltersSupportedFileTypes = [
  "text/csv",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const adFiltersFileUpload = {
  adFiltersMaxFileSize,
  adFiltersSupportedFileTypes,
};

const accessListMapping = {
  allAdFilters: [
    {
      featureId: "GlobalFilterBulkUpload",
      featureName: "GlobalFilterBulkUpload",
    },
    {
      featureId: "GlobalFilterAdvancedStringSearch",
      featureName: "GlobalFilterAdvancedStringSearch",
    },
    {
      featureId: "GlobalFilterAdvancedTimestampSearch",
      featureName: "GlobalFilterAdvancedTimestampSearch",
    },
  ],
  datastory: [
    {
      featureId: "dataStory",
      featureName: "DataStory",
    },
  ],
  limitListSigview: [
    {
      limitName: "maxPermittedDaysDashboardCalendar",
      limitValue: 92,
    },
    {
      limitName: "maxPermittedDaysReportCalendar",
      limitValue: 92,
    },
  ],
};

const featureIdMapping = {
  sigview: {
    datastory: "datastory",
    pivot: "pivotx",
    plot: "standaloneChart",
    globalFiltersFileUpload: "GlobalFilterBulkUpload",
    globalFiltersStringMatch: "GlobalFilterAdvancedStringSearch",
    globalFiltersTimestamp: "GlobalFilterAdvancedTimestampSearch",
    daysLimitCalendarDashboard: "maxPermittedDaysDashboardCalendar",
    daysLimitCalendarReports: "maxPermittedDaysReportCalendar",
    maxSavedReports: "maxSavedReports",
    maxScheduledReports: "maxScheduledReports",
    fullReport: "fullReport",
    reportManager: "report",
    download: "downloadNow",
    metricFilters: "metricFilter",
    maxFileUploadRowCount: "maxBulkFilterValues",
    maxFileUploadSizeInMB: "maxBulkFilterFileSize",
    alertManager: "pattern",
    multipleComparisonCalendars: "multipleComparisonCalendars",
    maxCompareCalendarInDashboard: "maxCompareCalendarInDashboard",
    maxCompareCalendarInReportsFeatureId:
      "maxCompareCalendarInReportsFeatureId",
    hourGranularity: "hourGranularity",
    dayGranularity: "dayGranularity",
    monthGranularity: "monthGranularity",
    minHourGranDurationInDays: "minHourGranDurationInDays",
    maxHourGranDurationInDays: "maxHourGranDurationInDays",
    minDayGranDurationInDays: "minDayGranDurationInDays",
    maxDayGranDurationInDays: "maxDayGranDurationInDays",
    minMonthGranDurationInDays: "minMonthGranDurationInDays",
    maxMonthGranDurationInDays: "maxMonthGranDurationInDays",
    reportManagerAsTab: "reportManagerAsTab",
    reportManagerAsSidenav: "reportManagerAsSidenav",
    alertManagerAsTab: "alertManagerAsTab",
    alertManagerAsSidenav: "alertManagerAsSidenav",
    analyze: "workspace",
    chartType: "chartType",
    workspaceShare: "shareUrl",
    chartType: "chartType",
    overlayUpdates: "overlayUpdates",
    forcedRefreshToast: "forcedRefreshToast",
    customMetricInAdmin: "customMetric",
    i18n: "languageInternalization",
    bannerNotification: "bannerNotification",
    bannerLink: "bannerLink",
    maxSavedAlerts: "maxSavedAlerts",
  },
  nonSigview: {
    datastory: "datastory",
    pivot: "pivotx",
    plot: "standaloneChart",
    globalFiltersFileUpload: "globalFilterBulkUpload",
    globalFiltersStringMatch: "globalFilterAdvancedStringSearch",
    globalFiltersTimestamp: "globalFilterAdvancedTimestampSearch",
    daysLimitCalendarDashboard: "maxPermittedDaysDashboardCalendar",
    daysLimitCalendarReports: "maxPermittedDaysReportCalendar",
    maxSavedReports: "maxSavedReports",
    maxScheduledReports: "maxScheduledReports",
    fullReport: "fullReport",
    reportManager: "report",
    download: "downloadNow",
    metricFilters: "metricFilter",
    maxFileUploadRowCount: "maxBulkFilterValues",
    maxFileUploadSizeInMB: "maxBulkFilterFileSize",
    alertManager: "pattern",
    multipleComparisonCalendars: "multipleComparisonCalendars",
    maxCompareCalendarInDashboard: "maxCompareCalendarInDashboard",
    maxCompareCalendarInReportsFeatureId:
      "maxCompareCalendarInReportsFeatureId",
    hourGranularity: "hourGranularity",
    dayGranularity: "dayGranularity",
    monthGranularity: "monthGranularity",
    minHourGranDurationInDays: "minHourGranDurationInDays",
    maxHourGranDurationInDays: "maxHourGranDurationInDays",
    minDayGranDurationInDays: "minDayGranDurationInDays",
    maxDayGranDurationInDays: "maxDayGranDurationInDays",
    minMonthGranDurationInDays: "minMonthGranDurationInDays",
    maxMonthGranDurationInDays: "maxMonthGranDurationInDays",
    reportManagerAsTab: "reportManagerAsTab",
    reportManagerAsSidenav: "reportManagerAsSidenav",
    alertManagerAsTab: "alertManagerAsTab",
    alertManagerAsSidenav: "alertManagerAsSidenav",
    analyze: "workspace",
    chartType: "chartType",
    workspaceShare: "shareUrl",
    chartType: "chartType",
    overlayUpdates: "overlayUpdates",
    forcedRefreshToast: "forcedRefreshToast",
    customMetricInAdmin: "customMetricInAdmin",
    i18n: "languageInternalization",
    bannerNotification: "bannerNotification",
    bannerLink: "bannerLink",
    maxSavedAlerts: "maxSavedAlerts",
  },
};

const adFiltersAllFeatureIds = [
  "globalFiltersFileUpload",
  "globalFiltersStringMatch",
  "globalFiltersTimestamp",
];

const authErrorUrlMapping = {
  sigview: {
    signIn: "/sign-in",
    ssoError: "/sign-in",
    ssoRetry: "/sign-in",
  },
  nonSigview: {
    signIn: "/app/#/openxSignIn",
    ssoError: "/app/#/ssoOpenxErrorMsgPage",
    ssoRetry: "/app/#/ssoOpenxErrorMsgPageRetry",
  },
  nonSigviewOaa: {
    signIn: "/app/#/sso/loginErrorMsg",
    ssoError: "/app/#/sso/loginErrorMsg",
    ssoRetry: "/app/#/sso/loginErrorMsg",
  },
};

const localDevUrlList = [
  "localhost",
  "local-openx.sigview.io",
  "local.sigview.io",
];

const prodURLList = ["sigview.sigmoid.io", "compass.sigmoid.io"];

// ! Why this complicated structure?
// * It's to cater to 2 types of cases:
// 1. For datastory, either of plot or datastory needs to be true
// 2. For reportManagerAsTab, BOHT of reportManager and reportManagerAsTab need to be true
const validRootPageToFeaturesMapping = {
  analyze: { condition: "or", requiredFeatureList: ["analyze"] },
  pivotx: { condition: "or", requiredFeatureList: ["pivot"] },
  datastory: { condition: "or", requiredFeatureList: ["plot", "datastory"] },
  reportManagerAsTab: {
    condition: "or",
    requiredFeatureList: ["reportManager", "reportManagerAsTab"],
  },
  alertManagerAsTab: {
    condition: "or",
    requiredFeatureList: ["reportManager", "alertManagerAsTab"],
  },
};
const getDateRangeRelaodInterval = 15 * 1000 * 60;
const bannerNotificationInetrval = 1 * 1000 * 60;
const CheckFiltersNotificationIntervals = 50 * 1000 * 60;

const gacurrTabMapping = {
  analyze: "Analyze",
  pivotx: "Pivot",
  datastory: "Datastory",
  "sign-in": "Sign-In",
  reset: "Reset",
};

const passwordValidationRules = [
  "Old password should not empty",
  "Should not match the old password",
  "Should contain 1 lower case and 1 upper case character",
  "Should contain minimum one number",
  "Should contain minimum one symbol",
  "Please enter your new password",
  "Should be between 8 to 32 characters",
  "Passwords do not match",
  "Please confirm your new password.",
];

const sigviewUrlUserTypeMapping = {
  "local-openx.sigview.io": "nonSigview",
  "devint-sigview.sigmoid.io": "nonSigview",
  "staging-sigview.sigmoid.io": "nonSigview",
  "openx.sigmoid.io": "nonSigview",
  "openx-beta.sigmoid.io": "nonSigview",
  "qaopenx.sigmoid.io": "nonSigview",
  "local.sigview.io": "sigview",
  "stagging-sigview.sigmoid.io": "sigview",
  "sigview.sigmoid.io": "sigview",
  "kayzen.sigmoid.io": "sigview",
  "inventory.kayzen.io": "sigview",
  "sigview-beta.sigmoid.io": "sigview",
};
const defaultHostnameId = "local.sigview.io";

const hostnameBackendApiMapping = {
  "local-openx.sigview.io": {
    baseUrl: "https://devint-auth.sigmoid.io/api/v1",
    baseUrlOld: "https://devint-auth.sigmoid.io",
    dataUrl: "https://devint-auth.sigmoid.io/api/v1",
  },
  "devint-sigview.sigmoid.io": {
    baseUrl: "https://devint-auth.sigmoid.io/api/v1",
    baseUrlOld: "https://devint-auth.sigmoid.io",
    dataUrl: "https://devint-auth.sigmoid.io/api/v1",
  },
  "staging-sigview.sigmoid.io": {
    baseUrl: "https://staging-auth.sigmoid.io/api/v1",
    baseUrlOld: "https://staging-auth.sigmoid.io",
    dataUrl: "https://staging-auth.sigmoid.io/api/v1",
  },
  "qaopenx.sigmoid.io": {
    baseUrl: "https://qaauthvertex.sigmoid.io/api/v1",
    baseUrlOld: "https://qaauthvertex.sigmoid.io",
    dataUrl: "https://qaauthvertex.sigmoid.io/api/v1",
  },
  "openx.sigmoid.io": {
    baseUrl: "https://auth2.sigmoid.io/api/v1",
    baseUrlOld: "https://auth2.sigmoid.io",
    dataUrl: "https://data.sigmoid.io/api/v1",
  },
  "local.sigview.io": {
    baseUrl: "https://stagging-sigviewauth.sigmoid.io/api/v1",
    baseUrlOld: "https://stagging-sigviewauth.sigmoid.io",
    dataUrl: "https://stagging-sigviewauth.sigmoid.io/api/v1",
    // baseUrl: "https://dev-sigviewauth.sigmoid.io/api/v1",
    // baseUrlOld: "https://dev-sigviewauth.sigmoid.io",
    // dataUrl: "https://dev-sigviewauth.sigmoid.io/api/v1",
  },
  "sigstaging.sigmoid.io": {
    baseUrl: "https://stagging-sigviewauth.sigmoid.io/api/v1",
    baseUrlOld: "https://stagging-sigviewauth.sigmoid.io",
    dataUrl: "https://stagging-sigviewauth.sigmoid.io/api/v1",
  },
  "stagging-sigview.sigmoid.io": {
    baseUrl: "https://stagging-sigviewauth.sigmoid.io/api/v1",
    baseUrlOld: "https://stagging-sigviewauth.sigmoid.io",
    dataUrl: "https://stagging-sigviewauth.sigmoid.io/api/v1",
  },
  "dev-sigview-compass.sigmoid.io": {
    baseUrl: "https://stagging-sigviewauth.sigmoid.io/api/v1",
    baseUrlOld: "https://stagging-sigviewauth.sigmoid.io",
    dataUrl: "https://stagging-sigviewauth.sigmoid.io/api/v1",
  },
  "dev-sigview.sigmoid.io": {
    baseUrl: "https://dev-sigviewauth.sigmoid.io/api/v1",
    baseUrlOld: "https://dev-sigviewauth.sigmoid.io",
    dataUrl: "https://dev-sigviewauth.sigmoid.io/api/v1",
  },
  "sigview-beta.sigmoid.io": {
    baseUrl: "https://dev-sigviewauth.sigmoid.io/api/v1",
    baseUrlOld: "https://dev-sigviewauth.sigmoid.io",
    dataUrl: "https://dev-sigviewauth.sigmoid.io/api/v1",
  },
  "sigview.sigmoid.io": {
    baseUrl: "https://sigviewauth.sigmoid.io/api/v1",
    baseUrlOld: "https://sigviewauth.sigmoid.io",
    dataUrl: "https://sigviewdata.sigmoid.io/api/v1",
  },
  "compass.sigmoid.io": {
    baseUrl: "https://sigviewauth.sigmoid.io/api/v1",
    baseUrlOld: "https://sigviewauth.sigmoid.io",
    dataUrl: "https://sigviewdata.sigmoid.io/api/v1",
  },
  "kayzen.sigmoid.io": {
    baseUrl: "https://sigviewauth.sigmoid.io/api/v1",
    baseUrlOld: "https://sigviewauth.sigmoid.io",
    dataUrl: "https://sigviewdata.sigmoid.io/api/v1",
  },
  "inventory.kayzen.io": {
    baseUrl: "https://sigviewauth.sigmoid.io/api/v1",
    baseUrlOld: "https://sigviewauth.sigmoid.io",
    dataUrl: "https://sigviewdata.sigmoid.io/api/v1",
  },
  "openx-beta.sigmoid.io": {
    baseUrl: "https://devint-auth.sigmoid.io/api/v1",
    baseUrlOld: "https://devint-auth.sigmoid.io",
    dataUrl: "https://devint-auth.sigmoid.io/api/v1",
  },
};

const convertAllTablesToMultiTable = (arr) => {
  const updArr = arr.map((row) => {
    if (row.chartObject.metadata.chartType !== "table") return row;
    return {
      ...row,
      chartObject: {
        ...row.chartObject,
        metadata: { ...row.chartObject.metadata, chartType: "multitable" },
      },
    };
  });
  return updArr;
};

const whiteListEmailsForGa = [
  "dakshraj.goyal+bbca0a92-7a68-469b-a03f-40f09ba54639@openx.com",
  "chandan.pandey+bbca0a92-7a68-469b-a03f-40f09ba54639@openx.com",
  "sundeep.kumar+bbca0a92-7a68-469b-a03f-40f09ba54639@openx.com",
  "abhishek.singh+bbca0a92-7a68-469b-a03f-40f09ba54639@openx.com",
  "prakriti.patra+bbca0a92-7a68-469b-a03f-40f09ba54639@openx.com",
  "shubh.ambaly+51e220cb-8c97-4a65-b047-91c933b79b78@openx.com",
  "sai.prathyusha+bbca0a92-7a68-469b-a03f-40f09ba54639@openx.com",
  "shubh.ambaly+bbca0a92-7a68-469b-a03f-40f09ba54639@openx.com",
  "shubh.ambaly+26e53f82-d199-49df-9eca-7b350c0f9646@openx.com",
  "vasa.prashanth+bbca0a92-7a68-469b-a03f-40f09ba54639@openx.com",
  "shubh.ambaly+152d07b1-da41-4c40-ad18-fe74c269534a@openx.com",
  "shubh.ambaly+eab9ff18-1f65-4a26-8531-cb990181cabb@openx.com",
  "shubh+88a16813-d8e7-4154-ad67-f037edb4bbf4@sigmoidanalytics.com",
  "shubh.ambaly+f40e5a82-ec60-40c1-a9a3-f8ee80fedd1c@openx.com",
  "rohit.mehrotra+bbca0a92-7a68-469b-a03f-40f09ba54639@openx.com",
  "binay.lohia+bbca0a92-7a68-469b-a03f-40f09ba54639@openx.com",
];

const defaultDataLimitData = [
  {
    id: 10,
    name: 10,
    disabled: false,
  },
  {
    id: 25,
    name: 25,
    disabled: false,
  },
  {
    id: 50,
    name: 50,
    disabled: false,
  },
  {
    id: 100,
    name: 100,
    disabled: false,
  },
];
const defaultRollingDatesData = [
  {
    id: "yes",
    name: "Yes",
    disabled: false,
  },
  {
    id: "no",
    name: "No",
    disabled: false,
  },
];
const compareDateTypeBackendMapping = {
  abs_change: "_trueDelta",
  rel_change: "_deltaPercentage",
  both: "_Both",
};
const compareDateTypeBackendMappingInverse = {
  _trueDelta: "abs_change",
  _deltaPercentage: "rel_change",
  _Both: "both",
};
const defaultUserPreferredLanguage = "en";
const defaultWorkspaceAutoSaveStatus = false;
// week is not a valid datetime dim id; hence not included in this list
const validDatetimeDimIds = ["hour", "day", "month"];
const defaultMetricGranData = [
  { id: "hour", name: "Hour", disabled: false },
  { id: "day", name: "Day", disabled: false },
];
// If anyone changes, please change both granularityIdMapping and granularityIdMappingInverse
const granularityIdMapping = {
  D001: "hour",
  D002: "day",
  D003: "week",
  D150: "month",
};
const granularityIdMappingInverse = {
  hour: "D001",
  day: "D002",
  week: "D003",
  month: "D150",
};
const msvColorArr = {
  light: ["#267EE6", "#ffd700", "#5EC7AA", "#D369B6", "#03dffc"],
  dark: ["#267EE6", "#ffd700", "#5EC7AA", "#D369B6", "#03dffc"],
};

const hardCoded = {
  userPreferredLanguage: "en",
  payload: payloadHardCoded,
  datastoryConfig: { rowHeight: 30, margin: [10, 10] },
  logoUrl: "https://logo.sigmoid.io/openx/openx-cpr.png?v=r23_p1",
  datastoryId: "datastory",
  reactNavList: ["charts", "datastory", "pivot", "analyze"],
  sampleDashboardUrl:
    "https://storage.googleapis.com/datastory-thumbnail-images/sampleDashboard.png",
  widgetPadding: 10,
  datetimeFormat: "ddd MMMM DD, YYYY HH:mm:ss ZZ",
  dateFormatFilterMenu: "MMM DD, YYYY",
  reportManagerQueryFormat: "MMM DD, YYYY HH:mm:ss",
  defaultProfileTimestampFormat: "MM_DD_YYYY_HH_mm_ss",
  minMaxDateFormat: "MMM DD, YYYY HH:mm",
  defaultDsTimestampFormat: "MM_DD_YYYY_HH_mm_ss",
  defaultStandaloneChartTimestampFormat: "MM_DD_YYYY_HH_mm_ss",
  reactDatePickerKey: "selection",
  applicationMode,
  gaId: googleAnalyticsIdMapping[applicationMode],
  dataLimit: 500,
  angularAnalyzeId: "sigview",
  uiErrorMessage: "Something went wrong! Please try again.",
  queryCancelled: "Query Cancelled! Please try again!",
  darkModeIconClass: "dark_mode",
  lightModeIconClass: "light_mode",
  joyrideTourSettings,
  allTitlesMapping,
  stringDataType: "string",
  getDimensionValuesDataLimit: 50,
  dimensionValuesDimSearchType: "selected",
  queryCancelled: "Query Cancelled! Please try again!",
  initialDimensionData,
  includeExcludeJson,
  advancedFiltersIncExcMapping: {
    include: "Select",
    exclude: "Exclude",
  },
  incExcTitleMapping: {
    include: "Include",
    exclude: "Exclude",
  },
  advancedFiltersDatatypeMapping: {
    string: "that",
    timestamp: "dates",
  },
  exactlyMatchesObject: {
    id: "exactlyMatches",
    name: "Exactly Matches",
    disabled: false,
    maxLimit: 1,
    partnerIds: [],
  },
  filterDimTypeList: ["string", "timestamp"],
  keepStatusTextList,
  adFiltersCalendarMinDateEpoch: 946684800000, //Jan 1st 2000 UTC
  adFiltersMaxLimit: 10,
  adFiltersFileUpload,
  initialFileData,
  adminPassword: "rohit_loves_automation",
  accessListMapping,
  featureIdMapping,
  adFiltersAllFeatureIds,
  authErrorUrlMapping,
  sigviewUrlUserTypeMapping,
  hostnameBackendApiMapping,
  defaultLimitList: {
    daysLimitCalendarDashboard: 92,
    daysLimitCalendarReports: 92,
    maxSavedReports: 50,
    maxScheduledReports: 50,
    maxFileUploadRowCount: 2000,
    maxFileUploadSizeInMB: 1,
    maxCompareCalendarInDashboard: 3,
    maxCompareCalendarInReports: 1, // ! This should always be one because multiple-compare doesn't make sense in reports; It's only for metric charts
    minHourGranDurationInDays: 1,
    maxHourGranDurationInDays: 92,
    minDayGranDurationInDays: 2,
    maxDayGranDurationInDays: 9999,
    minMonthGranDurationInDays: 32, //! Month granularity is coming when user select more than 31 days
    maxMonthGranDurationInDays: 99999,
    maxSavedAlerts: 10,
  },
  metricFilterTypesBetweenId: "between",
  chartTypesBaseUrl: "https://storage.googleapis.com/sigview-icons/charts",
  convertAllTablesToMultiTable,
  cloneOfPreText: "Clone of",
  chartsMaxLimitInDs: 10,
  kpisMaxLimitInDs: 6,
  voidUrl:
    "https://storage.googleapis.com/sigview-icons/illustrations/noDatastory.svg",
  // chartTypesBaseUrl: "./chart-icons",
  whiteListEmailsForGa,
  kvrKeyValueDimId: "CustomValue",
  kvrId: "KeyValueReport",
  prebidId: "PrebidAnalytics",
  prebidAdUnitCodeId: "AdUnitCode",

  attributes: {
    dimDatatype: [
      { id: "String", name: "String" },
      { id: "Range", name: "Range" },
      { id: "timestamp", name: "timestamp" },
      { id: "dateTime", name: "dateTime" },
    ],

    metricDatatype: [
      { id: "String", name: "String" },
      { id: "currency", name: "Currency" },
      { id: "percent", name: "Percent" },
      { id: "percentile", name: "Percentile" },
    ],

    measureType: [
      { id: "sum", name: "Sum" },
      { id: "approxCountDistinct", name: "Approx Count Distinct" },
      { id: "approxPercentile", name: "Approx Percentile" },
    ],

    metricDataSymbol: {
      String: [{ id: "String", name: "String" }],
      currency: [
        { id: "$", name: "$" },
        { id: "£", name: "£" },
        { id: "€", name: "€" },
        { id: "¥", name: "¥" },
        { id: "₹", name: "₹" },
        { id: "₩", name: "₩" },
        { id: "₱", name: "₱" },
        { id: "₽", name: "₽" },
        { id: "฿", name: "฿" },
        { id: "₺", name: "₺" },
      ],
      percent: [{ id: "%", name: "%" }],
      percentile: [{ id: "%", name: "%" }],
    },
  },
  admin: {
    cprMetricIdList: [],
    cprCustomMetricIdList: [
      "CM015",
      "CM029",
      "CM014",
      "CM048",
      "CM013",
      "CM009",
    ],
    realTimeCprMetricIdList: [],
    realTimeCprCustomMetricIdList: [
      "CM015",
      "CM029",
      "CM014",
      "CM048",
      "CM013",
      "CM009",
    ],
  },
  adminGroupNameRules: [
    "It should not be an existing name",
    "It can contain only alphabets, numbers and underscore",
    "It cannot contain spaces",
    "It should be atleast 3 characters long",
    "It should not be more than 20 characters long",
  ],
  reportNameRules: [
    "It should not be an existing name",
    "It should be atleast one character long",
    "It should not be more than 100 characters long",
  ],
  attributeNameRules: [
    "It should not be an existing name",
    "It should be atleast 2 characters long",
  ],
  attributeDescriptionRules: [
    "Description should be less than 100 characters",
  ],
  attributeNumbersRules: [
    "Number should allow till 2 decimal values. Ex- 0.1 to .99",
  ],
  attributeBackendNameRules: [
    "It should not be an existing name",
    "It can contain only alphabets, numbers and underscore, _",
    "It cannot contain spaces",
    "It should be atleast 2 characters long",
  ],
  rootUrlOnLogin: "/analyze/dashboard",
  rootNonSigviewUrlOnLogin: "/analyze/dashboard",
  defaultSidenavItemsOrderInfo: { home: 1, reports: 2, alerts: 3 },
  defaultDataLimitData,
  defaultRollingDatesData,
  compareDateTypeBackendMapping,
  compareDateTypeBackendMappingInverse,
  defaultUserPreferredLanguage,
  defaultWorkspaceAutoSaveStatus,
  publicUrls: ["/reset/token"],
  localDevUrlList,
  validDatetimeDimIds,
  defaultMetricGranData,
  granularityIdMapping,
  granularityIdMappingInverse,
  defaultHostnameId,
  prodURLList,
  passwordValidationRules,
  openxIframeBaseUrl: "https://token.sigmoid.io/sigview-auth-check.html",
  validRootPageToFeaturesMapping,
  getDateRangeRelaodInterval,
  msvColorArr,
  bannerNotificationInetrval,
  CheckFiltersNotificationIntervals,
  gacurrTabMapping,
  noDataAvailableMessage:
    "Data not available. Please reach out to sigviewsupport@sigmoidanalytics.com",
  noDataAvailableMessageSigview:
    "Data not available. Please reach out to sigviewsupport@sigmoidanalytics.com",
  noDataAvailableMessageNonSigview:
    "Data not available. Please raise a ticket in Salesforce or reach out to support@openx.com.",
  appBuilderUrl:
    "https://storage.googleapis.com/sigview-icons/illustrations/app-builder.svg",
  internalServerErrorSigview:
    "Internal server error! Please retry after sometime.",
};

const apiEndpoints = {
  // ! AUTHENTICATION - HOLD
  sigviewSignIn: "/signIn",
  oAuthSignIn: "/oAuthSignIn",
  sendResetPasswordLink: "/sendResetPasswordLink",
  sigviewSignInSSO: "/ssoSignIn",
  resetPassword: "/resetPassword",
  changePassword: "/changePassword",
  isTokenValid: "/isTokenValid",

  // USER - Tested Done
  get: "/app/v1/common/version/get", // Done
  getUser: "/getUser", // Done
  getUserEmbedded: "/ed/user/get", // Done
  getUserAccessList: "/getUserAccessList", // Done
  updateAutoSaveStatus: "/updateAutoSaveStatus", // Done
  signOut: "/api/v1/signOut",

  // DIMENSIONS, METRICS, DATE RANGE - Tested Done
  getMetricList: "/getMetricList", // Done
  getDimensionList: "/getDimensionList", // Done
  getCustomMetricList: "/getCustomMetricList", // Done

  // UTILS - Tested Done
  updateView: "/updateUserV2", // Done

  // DATE QE - Tested Done
  getData: "/getData", // Done
  getComparisonData: "/getComparisonData", // Done
  getDimensionValues: "/getDimensionValues", // Done
  getDateRange: "/getDateRange", // ! HOLD

  // FILE UPLOAD - HOLD
  getFileUploadLocationNonSigview: "/bulkFilters/getUploadLocation", // ! Hold Openx migration taken care
  getFileUploadLocationSigview: "/preSignedUrl", // Done
  uploadFileMetadataToServer: "/upload", // Done

  // DATASTORY - Done
  datastorySample: "/datastory/sample/all", // Done
  datastorySaved: "/datastory/saved/all", // Done
  datastoryShared: "/datastory/shared/bookmark/all", // ! can't find the path
  datastorySampleById: "/datastory/sample", // Done
  datastorySavedById: "/datastory/saved", // Done
  datastorySharedById: "/datastory/shared/bookmark", // ! can't find the path
  saveDs: "/datastory/saved/create", // Done
  renameDs: "/datastory/saved/rename", // Done
  updateDs: "/datastory/saved/update", // Done
  deleteDs: "/datastory/saved/delete", // Done // API ENDPOINT: /datastory/saved/delete/<ds_id>
  dsNameCheck: "/datastory/saved/name-check", // Done

  // REPORTS  - Tested Done
  getAllScheduledReports: "/listAllScheduledReports", // Done
  getAllSavedReports: "/listAllSavedReports", // Done
  getReportsDownloadHistory: "/download/getDownloadHistory", // Done
  updateScheduledReport: "/editScheduledReport", // Done
  getReportOverEmail: "/getReportOverEmail", // Done
  instantDownload: "/downloadRequest", // Done
  addScheduledReport: "/addScheduledReport", // Done
  getReportLinkOverEmail: "/getFileReport", // Done
  addSavedReport: "/addSavedReport", // Done
  updateSavedReport: "/editSavedReport", // Done
  deleteSavedReport: "/deleteSavedReport", // Done // API ENDPOINT: /deleteSavedReport/<report_id>
  deleteScheduledReport: "/deleteScheduledReport", // Done // API ENDPOINT: /deleteScheduledReport/<report_id>

  // CHARTS - Done
  getChartObjectList: "/getChartObjectList", // Done
  renameChart: "/renameChart", // Done
  saveChart: "/saveChartObject", // Done
  updateChart: "/updateChartObject", // Done
  deleteChart: "/deleteChartObject", // Done// API ENDPOINT: deleteChartObject?id=<chart_id>
  chartNameCheck: "/isChartTitleExist", // Done

  // WORKSPACE - Tested Done
  worksheetById: "/getWorksheetById", // Done
  getAllWorkbooks: "/getAllWorkbooks", // Done
  checkWorksheetName: "/checkWorksheetName", // Done
  deleteWorksheet: "/deleteWorksheet", // Done
  renameWorksheet: "/renameWorksheet", // Done
  addWorksheet: "/addWorksheet", // Done
  updateWorksheet: "/updateWorksheet", // Done
  updateWorksheetStatus: "/updateWorksheetStatus", // Done
  getGroupDefaultWorksheet: "/getGroupDefaultWorksheet", //  Done

  // WORKSPACE SHARE - Tested Done
  getBookmark: "/getBookmark", //  Done
  UpdateViewShareURL: "/UpdateViewShareURL",
  saveBookmark: "/saveBookmark", // Done

  // Non-Sigview Admin (OPENX)
  nonSigviewAdminViews: "/admin/views",
  nonSigviewAdminFormula: "/admin/formula/get",
  nonSigviewAdminGroups: "/admin/groups",
  nonSigviewAdminAttributeGroups: "/admin/group/attribute",
  nonSigviewAdminUsers: "/admin/users",
  nonSigviewAdminGroup: "/admin/group",
  nonSigviewAdminUser: "/admin/user",
  nonSigviewAdminCreateAttribute: "/admin",
  nonSigviewAdminUpdateAttribute: "/admin",
  nonSigviewAdminUpdateAttributeGroup: "/admin/group/modifyAttribute",
  nonSigviewAdminCreateGroup: "/admin/group",
  nonSigviewAdminUpdateGroup: "/admin/group",
  nonSigviewAdminNameCheckGroup: "/admin/group/name-check",
  nonSigviewAdminNameCheckAttribute: "/admin/attribute/name-check",
  nonSigviewAdminReadUser: "/admin/user",
  nonSigviewAdminUpdateUser: "/admin/user",
  nonSigviewAdminCreateUser: "/admin/user",
  nonSigviewAdminCheckUserEmail: "/admin/user/email-check",
  nonSigviewAdminReadAllGroupsWithView: "/admin/getAllGroupsWithView",
  adminAttributeSourceInfo: "/admin/attribute/sourceInfo/",

  // Sigview Admin (AWS) - Non-Centralized
  sigviewAdminViews: "/admin/views",
  sigviewAdminFormula: "/common/formula/get",
  sigviewAdminUsers: "/getUserListByGroup",
  sigviewAdminViewGroupList: "/getViewGroupList",
  sigviewAdminCreateAttribute: "/create",
  sigviewAdminUpdateAttribute: "/update",
  sigviewAdminReadUser: "/getAnyUser",
  sigviewAdminDeleteUser: "/deleteUser",
  sigviewAdminAttributeNameCheck: "/admin/attribute/name-check",
  sigviewAdminCreateUser: "/createUser",
  sigviewAdminUpdateUser: "/updateUser",
  sigviewAdminCheckUserEmail: "/checkUser",
  sigviewAdminEmailVerificationLink: "/sendEmailVerificationLink",

  // ALERTS - Done
  readAlerts: "/listAllPatterns", // Done
  addAlert: "/addPattern", // Done
  updateAlert: "/editPattern", // Done
  deleteAlert: "/deletePattern", // Done

  // BANNER
  getFeatureInfo: "/getFeatureInfo",

  //POLL OPENX SSO EVERY 50 MINUTES TO CHECK IF SECURITY FILTERS OF A USER ARE CHANGED
  refreshToken: "/refreshToken",
  //Version
  updateVersion: "/users/version/update",
};

const config = {
  localStorageReduxStoreName: "sigviewReduxStore",
  messages,
  hardCoded,
  dialogInfo,
  apiEndpoints,
};

export { config };

// Import required libraries
import { v4 } from "uuid";
import moment from "moment";
import { invalid } from "moment";

// Import required functions
import { transformFiltersBackendToUi } from "./filtersUtils";
import { transformFiltersUiToBackend } from "./filtersUtils";
import { config } from "../config/config";
import { getMetricTypeForChartObject } from "./plotlyUtils";

// Import utils/data
import { store } from "../redux/storeInitializer";
import { getSigviewUserType } from "../utils/utils";

import allTimezonesData from "../../assets/data/allTimezones.json";

//Defining required functions
const allTimezonesMod = allTimezonesData.map((row) => ({
  ...row,
  id: row.name,
}));

const getAllPlotlyMetric = (allData) => {
  const allplotlyMetric = allData.plotlyMetrics.map((row) => ({
    id: row._id,
    name: row._title,
    disabled: false,
  }));
  return allplotlyMetric;
};

const getAllPlotlyDimension = (allData) => {
  const allplotlyDimension = allData.plotlyDimensions.map((row) => ({
    id: row._id,
    name: row._title,
    disabled: false,
  }));
  return allplotlyDimension;
};

// ! All data of the Alert From
const alertFormData = {
  alertType: {
    data: [
      {
        id: "metric",
        name: "Metric Alert",
        disabled: false,
      },
      {
        id: "dimension",
        name: "Dimension Alert",
        disabled: false,
      },
    ],
  },
  alertCriteriaMetric: {
    data: {
      changeCriteria: [
        {
          id: "total",
          name: "Total",
          disabled: false,
        },
        {
          id: "percentageChange",
          name: "Percentage Change",
          disabled: false,
        },
        // {
        //   id: "absoluteChange",
        //   name: "Absolute Change",
        //   disabled: false,
        // },
      ],
      condition: {
        relativeComparison: [
          {
            id: "increasesBy",
            name: "Increases By",
            disabled: false,
          },
          {
            id: "decreasesBy",
            name: "Decreases By",
            disabled: false,
          },
          {
            id: "changesBy",
            name: "Changes By",
            disabled: false,
          },
        ],
        absoluteComparison: [
          {
            id: "isLessThan",
            name: "Is Less Than",
            disabled: false,
          },
          {
            id: "isGreaterThan",
            name: "Is Greater than",
            disabled: false,
          },
          {
            id: "isEqualTo",
            name: "Is Equal to",
            disabled: false,
          },
          {
            id: "isGreaterThanOrEqualTo",
            name: "Is Greater Than Or Equal To",
            disabled: false,
          },
          {
            id: "isLessThanOrEqualTo",
            name: "Is Less Than Or Equal To",
            disabled: false,
          },
        ],
      },
      logicalCondition: {
        data: [
          {
            id: "and",
            name: "AND",
            disabled: false,
          },
          {
            id: "or",
            name: "OR",
            disabled: false,
          },
        ],
      },
    },
  },

  languageOptionsArr: {
    NonSigview: [
      {
        id: "en",
        name: "English",
        disabled: false,
      },
      {
        id: "ja",
        name: "Japanese",
        disabled: false,
      },
    ],
    Sigview: [
      {
        id: "en",
        name: "English",
        disabled: false,
      },
    ],
  },
  alertCriteriaDimension: {
    data: {
      dimensionRow: {
        change: [
          {
            id: "change",
            name: "Change",
            disabled: false,
          },
        ],
        add: [
          {
            id: "add",
            name: "Add",
            disabled: false,
          },
        ],
        delete: [
          {
            id: "delete",
            name: "Delete",
            disabled: false,
          },
        ],
      },
      changeCriteria: [
        {
          id: "total",
          name: "Total",
          disabled: false,
        },
        {
          id: "percentageChange",
          name: "Percentage Change",
          disabled: false,
        },
        // {
        //   id: "absoluteChange",
        //   name: "Absolute Change",
        //   disabled: false,
        // },
      ],
      groupComparison: [
        {
          id: "bottomTen",
          name: "Bottom 10",
          disabled: false,
        },
        {
          id: "bottomTwenty",
          name: "Bottom 20",
          disabled: false,
        },
        {
          id: "topTen",
          name: "Top 10",
          disabled: false,
        },
        {
          id: "topTwenty",
          name: "Top 20",
          disabled: false,
        },
        {
          id: "any",
          name: "Any",
          disabled: false,
        },
      ],
      condition: {
        relativeComparison: [
          {
            id: "increasesBy",
            name: "Increases By",
            disabled: false,
          },
          {
            id: "decreasesBy",
            name: "Decreases By",
            disabled: false,
          },
          {
            id: "changesBy",
            name: "Changes By",
            disabled: false,
          },
        ],
        absoluteComparison: [
          {
            id: "isLessThan",
            name: "Is Less Than",
            disabled: false,
          },
          {
            id: "isGreaterThan",
            name: "Is Greater than",
            disabled: false,
          },
          {
            id: "isEqualTo",
            name: "Is Equal to",
            disabled: false,
          },
          {
            id: "isGreaterThanOrEqualTo",
            name: "Is Greater Than Or Equal To",
            disabled: false,
          },
          {
            id: "isLessThanOrEqualTo",
            name: "Is Less Than Or Equal To",
            disabled: false,
          },
        ],
      },
    },
  },
  timeZone: {
    data: allTimezonesMod,
  },
  basis: {
    data: [
      {
        id: "daily",
        name: "Daily",
        disabled: false,
      },
      {
        id: "hourly",
        name: "Hourly",
        disabled: false,
      },
    ],
  },
  comparedWith: {
    onDailyBasis: [
      {
        id: "prevDay",
        name: "Previous Day",
        disabled: false,
      },
      {
        id: "prevWeekSameDay",
        name: "Previous Week Same Day",
        disabled: false,
      },
      {
        id: "prevMonthSameDay",
        name: "Previous Month Same Day",
        disabled: false,
      },
    ],
    onHourlyBasis: [
      {
        id: "prevHour",
        name: "Previous Hour",
        disabled: false,
      },
      {
        id: "prevDaySameHour",
        name: "Previous Day Same Hour",
        disabled: false,
      },
      {
        id: "prevWeekSameHour",
        name: "Previous Week Same Hour",
        disabled: false,
      },
    ],
  },
  duration: {
    data: [
      {
        id: "oneWeek",
        name: "1 Week",
        disabled: false,
      },
      {
        id: "thirtyDays",
        name: "30 Days",
        disabled: false,
      },
      {
        id: "never",
        name: "Never",
        disabled: false,
      },
    ],
  },
};

// ! Selection Data of the alert form
const userSelection = {
  id: {
    message: "",
    value: "",
    status: "valid",
  },
  name: {
    message: "",
    value: "Pattern 1",
    status: "valid",
  },
  alertType: {
    message: "",
    value: "metric",
    status: "valid",
  },
  alertCriteriaMetric: {
    message: "",
    status: "invalid",
    value: [
      {
        id: v4(),
        changeCriteria: {
          message: "",
          value: "total",
          status: "valid",
        },
        metric: {
          message: "Please select a metric",
          value: "selectMetric",
          status: "invalid",
        },
        condition: {
          relativeComparison: {
            message: "",
            value: "increasesBy",
            status: "valid",
          },
          absoluteComparison: {
            message: "",
            value: "isLessThan",
            status: "valid",
          },
        },
        conditionValue: {
          message: "Please enter a value",
          value: "",
          status: "invalid",
        },
        logicalCondition: {
          message: "",
          value: "and",
          status: "valid",
        },
      },
    ],
  },
  alertCriteriaDimension: {
    message: "",
    status: "invalid",
    value: {
      changeCriteria: {
        message: "",
        value: "total",
        status: "valid",
      },
      metric: {
        message: "Please select a metric",
        value: "selectMetric",
        status: "invalid",
      },
      group: {
        message: "",
        value: "topTen",
        status: "valid",
      },
      dimensionChange: {
        message: "Please select a dimension",
        value: "selectDimension",
        status: "invalid",
      },
      condition: {
        relativeComparison: {
          message: "",
          value: "increasesBy",
          status: "valid",
        },
        absoluteComparison: {
          message: "",
          value: "isLessThan",
          status: "valid",
        },
      },
      conditionValue: {
        message: "Please enter a value",
        value: "",
        status: "invalid",
      },
      dimensionAdd: {
        message: "Please select a dimension",
        value: "selectDimension",
        status: "invalid",
      },
      dimensionDelete: {
        message: "Please select a dimension",
        value: "selectDimension",
        status: "invalid",
      },
      dimensionRow: {
        message: "",
        value: "change",
        status: "valid",
      },
    },
  },
  timeZone: {
    message: "",
    value: "IST (+05:30)",
    status: "valid",
  },
  basis: {
    message: "",
    value: "daily",
    status: "valid",
  },
  comparedWith: {
    message: "",
    value: "prevDay",
    status: "valid",
  },
  dimensionFilters: {
    message: "",
    value: [],
    status: "valid",
  },
  duration: {
    message: "",
    value: "never",
    status: "valid",
  },
  emailRecipients: {
    message: "",
    status: "valid",
    value: [
      {
        id: "shubh@sigmoidanalytics.com",
        name: "shubh@sigmoidanalytics.com",
        deletable: false,
      },
    ],
  },
  userPreferredLanguage: {
    message: "",
    value: "en",
    status: "valid",
  },
  active: {
    message: "",
    value: true,
    status: "valid",
  },

  actualPayload: {
    message: "",
    value: {},
    status: "valid",
  },
  crudOperation: {
    message: "",
    value: "create",
    status: "valid",
  },
};

// Func for the validation of name
const validateAlertName = (props) => {
  // console.log("props of validateAlertName", props);
  const { value = "" } = props;

  const sigviewUserType = getSigviewUserType();
  const runCheckFlag =
    sigviewUserType === "sigview"
      ? !/^[A-z0-9-_ ]*$/.test(value)
      : !/^[a-zA-Z0-9-_!@#$%^&*()+=~|:;',./<>? \u3040-\u309F\u30A0-\u30FF-\u31F0-\u31FF-\u2E80-\u2EFF-\u2F00-\u2FDF-\u3000-\u303F-\u31C0-\u31EF-\u3200-\u32FF-\u3300-\u33FF-\u3400-\u3FFF-\u4000-\u4DBF-\u4E00-\u4FFF]*$/.test(
          value
        );

  //Common Validations
  if (runCheckFlag)
    return {
      flag: true,
      status: "invalid",
      message: "Alert name cannot have special characters",
    };

  if (value.length < 1)
    return {
      flag: true,
      status: "invalid",
      message: "Alert name must have at least 1 character",
    };

  if (value.length > 100)
    return {
      flag: true,
      status: "invalid",
      message: "Alert name cannot exceed 100 characters",
    };

  return { flag: false, message: "" };
};

// Helper Function
const getMetricTitleFormId = (id, arr) => {
  const row = arr.find((el) => el.id === id);
  return row;
};

const getAlertCriteriaFormulaObj = (state) => {
  let { alertType, alertCriteriaMetric, alertCriteriaDimension } = state;

  if (alertType.value === "dimension") {
    const { changeCriteria, conditionValue, condition, metric } =
      alertCriteriaDimension.value;
    let criteriaFormulaObj = {};

    if (changeCriteria.value === "percentageChange") {
      criteriaFormulaObj = {
        formula: {
          changeCriteria:
            changeCriteria.value === "total"
              ? "Total"
              : changeCriteria.value === "percentageChange"
              ? "Percentage Change"
              : "Absolute Change",
          metric: metric.value,
          condition:
            condition.relativeComparison.value === "increasesBy"
              ? ">="
              : condition.relativeComparison.value === "decreasesBy"
              ? "<="
              : "~",
          conditionValue: conditionValue.value,
        },
      };
    } else {
      criteriaFormulaObj = {
        formula: {
          changeCriteria:
            changeCriteria.value === "total"
              ? "Total"
              : changeCriteria.value === "percentageChange"
              ? "Percentage Change"
              : "Absolute Change",
          metric: metric.value,
          condition:
            condition.absoluteComparison.value === "isLessThan"
              ? "<"
              : condition.absoluteComparison.value === "isGreaterThan"
              ? ">"
              : condition.absoluteComparison.value === "isLessThanOrEqualTo"
              ? "<="
              : condition.absoluteComparison.value === "isGreaterThanOrEqualTo"
              ? ">="
              : "=",
          conditionValue: conditionValue.value,
        },
      };
    }
    // console.log("criteriaFormulaObj sonu", criteriaFormulaObj);

    return criteriaFormulaObj;
  } else {
    const alertCriteriaFormulaObj = alertCriteriaMetric.value.map((el, i) => {
      const {
        changeCriteria,
        conditionValue,
        id,
        logicalCondition,
        condition,
        metric,
      } = el;
      if (changeCriteria.value === "percentageChange") {
        var criteriaFormulaObj = {
          formula: {
            changeCriteria:
              changeCriteria.value === "total"
                ? "Total"
                : changeCriteria.value === "percentageChange"
                ? "Percentage Change"
                : "Absolute Change",
            metric: metric.value,
            condition:
              condition.relativeComparison.value === "increasesBy"
                ? ">="
                : condition.relativeComparison.value === "decreasesBy"
                ? "<="
                : "~",
            conditionValue: conditionValue.value,
          },
          logicalCondition: logicalCondition.value.toUpperCase(),
        };
      } else {
        var criteriaFormulaObj = {
          formula: {
            changeCriteria:
              changeCriteria.value === "total"
                ? "Total"
                : changeCriteria.value === "percentageChange"
                ? "Percentage Change"
                : "Absolute Change",
            metric: metric.value,
            condition:
              condition.absoluteComparison.value === "isLessThan"
                ? "<"
                : condition.absoluteComparison.value === "isGreaterThan"
                ? ">"
                : condition.absoluteComparison.value === "isLessThanOrEqualTo"
                ? "<="
                : condition.absoluteComparison.value ===
                  "isGreaterThanOrEqualTo"
                ? ">="
                : "=",
            conditionValue: conditionValue.value,
          },
          logicalCondition: logicalCondition.value.toUpperCase(),
        };
      }

      return criteriaFormulaObj;
    });
    return alertCriteriaFormulaObj;
  }
};

const getCriteriaFormulae = (state) => {
  let alertCriteriaFormulaObj = getAlertCriteriaFormulaObj(state);
  if (state.alertType.value === "metric") {
    let finalArrOfCriteriaFormula = [];
    let idx = 0;
    let tempArr = [];
    for (const el of alertCriteriaFormulaObj) {
      if (el.logicalCondition === "AND") {
        tempArr.push(el);
        finalArrOfCriteriaFormula[idx++] = tempArr;
        tempArr = [];
      } else {
        tempArr.push(el);
      }
    }
    return finalArrOfCriteriaFormula;
  } else return alertCriteriaFormulaObj;
};

const alertCriteriaFormula = (props) => {
  const { state, allData, idFlag } = props;
  const criteriaFormula = getCriteriaFormulae(state);
  //   "[ Total(CM019)<25 ] AND [ Percentage Change(M002)~25 OR Total(M002)<25 ]"
  if (state.alertType.value === "metric") {
    const exArr = [];
    for (const el of criteriaFormula) {
      // console.log(el);
      const inArr = [];
      for (const el2 of el) {
        const { formula } = el2;
        const { changeCriteria, metric, condition, conditionValue } = formula;
        // ${changeCriteria}(${metric})${condition}${conditionValue}
        const allPlotlyMetrics = getAllPlotlyMetric(allData);
        const dataType = getMetricTitleFormId(
          metric,
          allPlotlyMetrics
        ).dataUnitValue;
        // console.log(dataType);
        const criteria =
          changeCriteria === "Absolute Change" ? "" : changeCriteria;
        const str = `${criteria}(${
          idFlag
            ? getMetricTitleFormId(metric, allPlotlyMetrics).id
            : getMetricTitleFormId(metric, allPlotlyMetrics).name
        })${condition}${dataType === "$" ? "$" : ""}${conditionValue}${
          dataType === "%" ? "%" : ""
        }`;
        // console.log(str);
        inArr.push(str);
      }
      // console.log(inArr.join(" OR "));
      exArr.push(`[ ${inArr.join(" OR ")} ]`);
    }
    // console.log(exArr.join(" AND "));
    const formulaString = exArr.join(" AND ");
    return formulaString;
  } else {
    const { formula } = criteriaFormula;
    const { changeCriteria, metric, condition, conditionValue } = formula;
    // ${changeCriteria}(${metric})${condition}${conditionValue}
    const allPlotlyMetrics = getAllPlotlyMetric(allData);
    const dataType = getMetricTitleFormId(
      metric,
      allPlotlyMetrics
    ).dataUnitValue;
    // console.log(dataType);
    const criteria = changeCriteria === "Absolute Change" ? "" : changeCriteria;
    const str = `${criteria}(${
      idFlag
        ? getMetricTitleFormId(metric, allPlotlyMetrics).id
        : getMetricTitleFormId(metric, allPlotlyMetrics).name
    })${condition}${dataType === "$" ? "$" : ""}${conditionValue}${
      dataType === "%" ? "%" : ""
    }`;
    return `[ ${str} ]`;
  }
};

// Func for the validation of Dimension alert
const validateAlertCriteriaDimension = (alertCriteriaDimension) => {
  let flag = false;
  if (alertCriteriaDimension.value.dimensionRow.value === "change") {
    if (
      alertCriteriaDimension.value.dimensionChange.value === "selectDimension"
    ) {
      flag = true;
      var validatedRowChange = {
        flag: true,
        ...alertCriteriaDimension.value.dimensionChange,
        message: "Please select a dimension",
      };
    }
    alertCriteriaDimension.value.dimensionChange = {
      ...alertCriteriaDimension.value.dimensionChange,
      validatedRowChange,
    };
    if (alertCriteriaDimension.value.metric.value === "selectMetric") {
      flag = true;
      var validateMetric = {
        flag: true,
        message: "Please select a metric",
      };

      alertCriteriaDimension.value.metric = {
        ...alertCriteriaDimension.value.metric,
        ...validateMetric,
      };
    }

    if (
      alertCriteriaDimension.value.conditionValue.value === "" ||
      isNaN(alertCriteriaDimension.value.conditionValue.value)
    ) {
      flag = true;
      var validateConditionValue = {
        flag: true,
        message: "Please enter a condition in numeric value",
      };

      alertCriteriaDimension.value.conditionValue = {
        ...alertCriteriaDimension.value.conditionValue,
        ...validateConditionValue,
      };
    }
  } else if (alertCriteriaDimension.value.dimensionRow.value === "add") {
    if (alertCriteriaDimension.value.dimensionAdd.value === "selectDimension") {
      flag = true;
      var validateRowAdd = {
        ...alertCriteriaDimension.value.dimensionAdd,
        flag: true,
        message: "Please select a dimension",
      };
    }
    alertCriteriaDimension.value.dimensionAdd = {
      ...alertCriteriaDimension.value.dimensionAdd,
      validateRowAdd,
    };
  } else if (alertCriteriaDimension.value.dimensionRow.value === "delete") {
    if (
      alertCriteriaDimension.value.dimensionDelete.value === "selectDimension"
    ) {
      flag = true;
      var validatedRowDelete = {
        ...alertCriteriaDimension.value.dimensionDelete,
        flag: true,
        message: "Please select a dimension",
      };
    }

    alertCriteriaDimension.value.dimensionDelete = {
      ...alertCriteriaDimension.value.dimensionDelete,
      ...validatedRowDelete,
    };
  }

  return { ...alertCriteriaDimension, flag };
};

// Func for the validation of Metric alert
const validateAlertCriteriaMetric = (alertCriteriaMetric) => {
  let flag = false;

  var metricRowValue = alertCriteriaMetric.value.map((metricRow) => {
    let { metric, conditionValue } = { ...metricRow };

    if (metricRow.metric.value === "selectMetric") {
      flag = true;
      alertCriteriaMetric = { ...alertCriteriaMetric, flag: true };
      metric = {
        ...metricRow.metric,
        flag: true,
        message: "Please select a metric",
      };
    }

    if (
      metricRow.conditionValue.value === "" ||
      isNaN(metricRow.conditionValue.value)
    ) {
      flag = true;
      alertCriteriaMetric = { ...alertCriteriaMetric, flag: true };
      conditionValue = {
        ...metricRow.conditionValue,
        flag: true,
        message: "Please enter a condition in numeric value",
      };
    }

    return {
      ...metricRow,
      metric: { ...(metricRow.metric = metric) },
      conditionValue: { ...(metricRow.conditionValue = conditionValue) },
    };
  });

  return { metricRowValue, flag };
};
const validateAlertFormData = (props) => {
  let { name, alertType, alertCriteriaMetric, alertCriteriaDimension } = props;
  let validateName = validateAlertName(name);
  name = { ...name, ...validateName };

  if (alertType.value === "metric") {
    let alertCriteriaMetricValue =
      validateAlertCriteriaMetric(alertCriteriaMetric);

    alertCriteriaMetric = {
      ...alertCriteriaMetric,
      value: alertCriteriaMetricValue.metricRowValue,
      flag: alertCriteriaMetricValue.flag,
    };
    alertCriteriaDimension = { ...alertCriteriaDimension, flag: false };
  }

  if (alertType.value === "dimension") {
    alertCriteriaDimension = validateAlertCriteriaDimension(
      alertCriteriaDimension
    );

    alertCriteriaMetric = { ...alertCriteriaMetric, flag: false };
  }

  const flag =
    name.flag || alertCriteriaMetric.flag || alertCriteriaDimension.flag;

  const response = {
    flag,
  };
  return response.flag;
};

// * Func to backend data into UI Friendly data
function unWrapperFunc(dataarr, allData, user) {
  const {
    globalFiltersFileUpload = false,
    globalFiltersStringMatch = false,
    globalFiltersTimestamp = false,
  } = user.uiFeatureList;
  let unwrappedValue = [];

  dataarr.map((row) => {
    const mapping = {
      "Previous Day": "prevDay",
      "Previous Week Same Day": "prevWeekSameDay",
      "Previous Month Same Day": "prevMonthSameDay",
      "Previous Hour": "prevHour",
      "Previous Day Same Hour": "prevDaySameHour",
      "Previous Week Same Hour": "prevWeekSameHour",

      "1 Week": "oneWeek",
      "30 Days": "thirtyDays",
      Indefinite: "never",

      "<": "isLessThan",
      ">": "isGreaterThan",
      "=": "isEqualTo",
      ">=": "increasesBy",
      ">=": "isGreaterThanOrEqualTo",

      "<=": "decreasesBy",
      "<=": "isLessThanOrEqualTo",
      "~": "changesBy",
      percentChange: {
        ">=": "increasesBy",
        "<=": "decreasesBy",
        "~": "changesBy",
      },
      totalChange: {
        "<": "isLessThan",
        ">": "isGreaterThan",
        "=": "isEqualTo",
        "<=": "isLessThanOrEqualTo",
        ">=": "isGreaterThanOrEqualTo",
      },

      absolute: "total",
      percent: "percentageChange",

      "Top 10": "topTen",
      "Bottom 10": "bottomTen",
      "Top 20": "topTwenty",
      "Bottom 20": "bottomTwenty",

      Any: "any",

      null: "",
    };

    const actualPayloadvalue = row;
    const idvalue = row._id;
    const namevalue = row.patternName;
    const alertTypevalue = row.alertType;

    // Default Dimension in case no values are coming from API
    const defaultDim = {
      changeCriteria: {
        message: "",
        value: "total",
        status: "valid",
      },
      metric: {
        message: "Please select a metric",
        value: "selectMetric",
        status: "invalid",
      },
      group: {
        message: "",
        value: "topTen",
        status: "valid",
      },
      dimensionChange: {
        message: "Please select a dimension",
        value: "selectDimension",
        status: "invalid",
      },
      condition: {
        relativeComparison: {
          message: "",
          value: "increasesBy",
          status: "valid",
        },
        absoluteComparison: {
          message: "",
          value: "isLessThan",
          status: "valid",
        },
      },
      conditionValue: {
        message: "Please enter a value",
        value: "",
        status: "invalid",
      },
      dimensionAdd: {
        message: "Please select a dimension",
        value: "selectDimension",
        status: "invalid",
      },
      dimensionDelete: {
        message: "Please select a dimension",
        value: "selectDimension",
        status: "invalid",
      },
      dimensionRow: {
        message: "",
        value: "change",
        status: "valid",
      },
    };

    // Default Metric in case no values are coming from API
    const defaultMetric = [
      {
        id: "200c8e53-ad50-4e68-b200-e79407753290",
        changeCriteria: {
          message: "",
          value: "total",
          status: "valid",
        },
        metric: {
          message: "Please select a metric",
          value: "selectMetric",
          status: "invalid",
        },
        condition: {
          relativeComparison: {
            message: "",
            value: "increasesBy",
            status: "valid",
          },
          absoluteComparison: {
            message: "",
            value: "isLessThan",
            status: "valid",
          },
        },
        conditionValue: {
          message: "Please enter a value",
          value: "10",
          status: "invalid",
        },
        logicalCondition: {
          message: "",
          value: "and",
          status: "valid",
        },
      },
    ];

    // Email recipients list
    let emailRecipientsvalue = [];
    for (let e = 0; e < row.recipients.length; e++) {
      if (e === 0) {
        emailRecipientsvalue.push({
          id: row.recipients[e],
          name: row.recipients[e],
          deletable: false,
        });
      } else {
        emailRecipientsvalue.push({
          id: row.recipients[e],
          name: row.recipients[e],
          deletable: true,
        });
      }
    }
    const basisvalue = row.triggerType;
    const durationvalue = row.expirationInfo.timePeriod;

    // Validity of the alert in epoch required in Side Nav
    const validTillvalue = row.expirationInfo.timestamp;
    const timeZonevalue = row.payload.requestParam.timeZone.name;
    const metricNodeList = row.expression.metricNodeList;
    const dimData = row.expression.dimNodeList;

    let alertCriteriaRowDimension;
    let comparedWithvalue;
    let alertCriteriaRowMetric;

    // If Metric is selected and not Dimension
    if (row.expression.metricNodeList !== null) {
      let arr = [];
      let finalarr = [];
      for (let i = 0; i < metricNodeList.length; i++) {
        let logicalConditionvalue;

        // In API structure if one object in one array, logicalCondition will be AND, if multiple objects in one array, logicalCondition will be OR
        metricNodeList[i].length > 1
          ? (logicalConditionvalue = "or")
          : (logicalConditionvalue = "and");

        for (let j = 0; j < metricNodeList[i].length; j++) {
          // If meric is terminal i.e. last object in last array, the locicalCondition and i is still less then metricNodeList.lenght
          if (
            (!metricNodeList[i + 1] || !metricNodeList[i][j + 1]) &&
            i < metricNodeList.length - 1
          ) {
            logicalConditionvalue = "and";
          }
          let idvalue = metricNodeList[i][j].blockId;
          let changeCriteriavalue = metricNodeList[i][j].calculationType;
          let metricConditionvalue = metricNodeList[i][j].operatorType;
          let metricCondition;
          // Relative Comparison depending on whether Change Criteria is "Percent" or "Absolute"
          if (changeCriteriavalue === "percent") {
            metricCondition = {
              relativeComparison: {
                message: "",
                value: mapping.percentChange[metricConditionvalue],
                status: "valid",
              },

              absoluteComparison: {
                message: "",
                value: mapping.percentChange[metricConditionvalue],
                status: "valid",
              },
            };
          } else {
            metricCondition = {
              absoluteComparison: {
                message: "",
                value: mapping.totalChange[metricConditionvalue],
                status: "valid",
              },
              relativeComparison: {
                message: "",
                value: mapping.totalChange[metricConditionvalue],
                status: "valid",
              },
            };
          }

          comparedWithvalue =
            mapping[metricNodeList[i][j].comparisonInfo.dateRange];

          let conditionValuevalue = metricNodeList[i][j].threshold;
          let metricIdvalue = metricNodeList[i][j].metricId;
          let metricvalue = metricNodeList[i][j].metricType;

          arr.push({
            id: idvalue,
            changeCriteria: {
              message: "",
              value: mapping[changeCriteriavalue],
              status: "valid",
            },
            conditionValue: {
              message: "",
              value: conditionValuevalue,
              status: "valid",
            },
            logicalCondition: {
              message: "",
              value: logicalConditionvalue,
              status: "valid",
            },
            condition: { ...metricCondition },
            metric: {
              message: "",
              value: metricIdvalue,
              status: "valid",
            },
          });
        }
        // For each itretion of metricNodeList, the arr will be pushed to finalarr
        for (let x = 0; x < arr.length; x++) {
          finalarr.push(arr[x]);
        }
        // for each itretion the arr will be empty after pushing itself to finalarr
        arr = [];
      }
      alertCriteriaRowMetric = finalarr;

      // If Dimension is selected and not Metric
    } else {
      let idvalue = dimData.blockId;
      let changeCriteriavalue = dimData.calculationType;
      let dimensionvalue = dimData.dimensionType;
      var groupvalue = dimData.dimensionsizeType;
      let conditionValuevalue = dimData.threshold;
      let metricIdvalue =
        dimData.metricId === null ? "selectMetric" : dimData.metricId;
      let metricvalue = dimData.metricType;

      let dimensionRowvalue = dimData.changeType;
      let dimConditionvalue = dimData.operatorType;

      comparedWithvalue = mapping[dimData.comparisonInfo.dateRange];

      let dimensionChangeIdvalue;
      let dimensionAddIdvalue;
      let dimensionDeleteIdvalue;
      if (dimensionRowvalue === "change") {
        dimensionChangeIdvalue = dimData.dimensionId;
        dimensionAddIdvalue = "selectDimension";
        dimensionDeleteIdvalue = "selectDimension";
      } else if (dimensionRowvalue === "add") {
        dimensionAddIdvalue = dimData.dimensionId;
        dimensionChangeIdvalue = "selectDimension";
        dimensionDeleteIdvalue = "selectDimension";
      } else if (dimensionRowvalue === "delete") {
        dimensionDeleteIdvalue = dimData.dimensionId;
        dimensionAddIdvalue = "selectDimension";
        dimensionChangeIdvalue = "selectDimension";
      }

      let dimCondition;
      if (changeCriteriavalue === "percent") {
        dimCondition = {
          relativeComparison: {
            message: "",
            value: mapping.percentChange[dimConditionvalue],
            status: "valid",
          },
          absoluteComparison: {
            message: "",
            value: "isLessThan",
            status: "valid",
          },
        };
      } else {
        dimCondition = {
          absoluteComparison: {
            message: "",
            value: mapping.totalChange[dimConditionvalue],
            status: "valid",
          },
          relativeComparison: {
            message: "",
            value: "increasesBy",
            status: "valid",
          },
        };
      }

      alertCriteriaRowDimension = {
        id: idvalue,
        changeCriteria: {
          message: "",
          value: mapping[changeCriteriavalue],
          status: "valid",
        },
        dimensionChange: {
          message: "",
          value: dimensionChangeIdvalue,
          status: "valid",
        },
        dimensionAdd: {
          message: "",
          value: dimensionAddIdvalue,
          status: "valid",
        },
        dimensionDelete: {
          message: "",
          value: dimensionDeleteIdvalue,
          status: "valid",
        },
        group: {
          message: "",
          value: mapping[groupvalue],
          status: "valid",
        },
        conditionValue: {
          message: "",
          value: conditionValuevalue,
          status: "valid",
        },
        condition: { ...dimCondition },
        metric: {
          message: "",
          value: metricIdvalue,
          status: "valid",
        },
        dimensionRow: {
          message: "",
          value: dimensionRowvalue,
          status: "valid",
        },
      };
    }

    let id = {
      message: "",
      value: idvalue,
      status: "valid",
    };

    let name = {
      message: "",
      value: namevalue,
      status: "valid",
    };

    let alertType = {
      message: "",
      value: alertTypevalue,
      status: "valid",
    };

    let timeZone = {
      message: "",
      value: timeZonevalue,
      status: "valid",
    };

    let basis = {
      message: "",
      value: basisvalue,
      status: "valid",
    };

    let comparedWith = {
      message: "",
      value: comparedWithvalue,
      status: "valid",
    };

    let duration = {
      message: "",
      value: mapping[durationvalue],
      status: "valid",
    };

    let emailRecipients = {
      message: "",
      value: emailRecipientsvalue,
      status: "valid",
    };

    let dimensionFilters = {
      message: "",
      value: transformFiltersBackendToUi(
        row.payload.requestParam.filter || [],
        allData.plotlyDimensions
      ),
      status: "valid",
    };
    //** Backward Compatibility Code Start Here**//
    if (
      !globalFiltersStringMatch &&
      !globalFiltersFileUpload &&
      !globalFiltersTimestamp &&
      dimensionFilters.value.length > 0
    ) {
      var validityFlags = dimensionFilters.value.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) =>
              ![
                "startsWith",
                "exactlyMatches",
                "endsWith",
                "containsString",
                "containsWholeWord",
                "from",
                "on",
                "between",
                "before",
              ].includes(item.type)
          ),
        };
        return newRow;
      });
    } else if (
      globalFiltersStringMatch &&
      !globalFiltersFileUpload &&
      !globalFiltersTimestamp &&
      dimensionFilters.value.length > 0
    ) {
      var validityFlags = dimensionFilters.value.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) =>
              !["exactlyMatches", "from", "on", "between", "before"].includes(
                item.type
              )
          ),
        };
        return newRow;
      });
    } else if (
      !globalFiltersStringMatch &&
      globalFiltersFileUpload &&
      !globalFiltersTimestamp &&
      dimensionFilters.value.length > 0
    ) {
      var validityFlags = dimensionFilters.value.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) =>
              ![
                "startsWith",
                "endsWith",
                "containsString",
                "containsWholeWord",
                "from",
                "on",
                "between",
                "before",
              ].includes(item.type)
          ),
        };
        return newRow;
      });
    } else if (
      !globalFiltersStringMatch &&
      !globalFiltersFileUpload &&
      globalFiltersTimestamp &&
      dimensionFilters.value.length > 0
    ) {
      var validityFlags = dimensionFilters.value.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) =>
              ![
                "startsWith",
                "exactlyMatches",
                "endsWith",
                "containsString",
                "containsWholeWord",
              ].includes(item.type)
          ),
        };
        return newRow;
      });
    } else if (
      globalFiltersFileUpload &&
      !globalFiltersStringMatch &&
      globalFiltersTimestamp &&
      dimensionFilters.value.length > 0
    ) {
      var validityFlags = dimensionFilters.value.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) =>
              ![
                "startsWith",
                "endsWith",
                "containsString",
                "containsWholeWord",
              ].includes(item.type)
          ),
        };
        return newRow;
      });
    } else if (
      !globalFiltersFileUpload &&
      globalFiltersStringMatch &&
      globalFiltersTimestamp &&
      dimensionFilters.value.length > 0
    ) {
      var validityFlags = dimensionFilters.value.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) => !["exactlyMatches"].includes(item.type)
          ),
        };
        return newRow;
      });
    } else if (
      globalFiltersFileUpload &&
      globalFiltersStringMatch &&
      !globalFiltersTimestamp &&
      dimensionFilters.value.length > 0
    ) {
      var validityFlags = dimensionFilters.value.map((row) => {
        const newRow = {
          ...row,
          advancedFilters: row.advancedFilters.filter(
            (item) => !["from", "on", "between", "before"].includes(item.type)
          ),
        };
        return newRow;
      });
    }
    if (validityFlags) {
      var finalValidateFilterArray = validityFlags.filter((row) => {
        if (
          (row.values.length !== 0 && row.advancedFilters.length === 0) ||
          (row.values.length === 0 && row.advancedFilters.length !== 0) ||
          (row.values.length !== 0 && row.advancedFilters.length !== 0)
        ) {
          return row;
        }
      });
      dimensionFilters.value = finalValidateFilterArray;
    }
    //** Backward Compatibility Code Ends Here **//

    let active = {
      message: "",
      value: row.patternStatus === "active" ? true : false,
      status: "valid",
    };

    if (
      alertCriteriaRowMetric === null ||
      alertCriteriaRowMetric === undefined
    ) {
      alertCriteriaRowMetric = defaultMetric;
    }

    let alertCriteriaMetric = {
      message: "",
      value: alertCriteriaRowMetric,
      status: "valid",
    };

    if (
      alertCriteriaRowDimension === null ||
      alertCriteriaRowDimension === undefined
    ) {
      alertCriteriaRowDimension = defaultDim;
    }
    let alertCriteriaDimension = {
      message: "",
      value: alertCriteriaRowDimension,
      status: "valid",
    };
    let actualPayload = {
      message: "",
      value: actualPayloadvalue,
      status: "valid",
    };

    let crudOperation = {
      message: "",
      value: "update",
      status: "valid",
    };
    let userPreferredLanguage = {
      message: "",
      value:
        row.userPreferredLanguage === null ? "en" : row.userPreferredLanguage,
      status: "valid",
    };
    unwrappedValue.push({
      id,
      name,
      alertType,
      emailRecipients,
      basis,
      duration,
      timeZone,
      alertCriteriaMetric,
      alertCriteriaDimension,
      comparedWith,
      actualPayload,
      dimensionFilters,
      active,
      crudOperation,
      userPreferredLanguage,
    });
  });

  return unwrappedValue;
}

const wrapper = (row, userEmail, allData, globalFilters) => {
  // console.log("sonu wrapper", row, userEmail);

  const getMetircObjFromId = (metricId) => {
    var metricObj = allData.plotlyMetrics.find((el) => el._id === metricId);
    return metricObj;
  };

  const getDimensionObjFromId = (dimId) => {
    var metricObj = allData.plotlyDimensions.find((el) => el._id === dimId);
    return metricObj;
  };

  const reverseMapping = {
    prevDay: "Previous Day",
    prevWeekSameDay: "Previous Week Same Day",
    prevMonthSameDay: "Previous Month Same Day",
    prevHour: "Previous Hour",
    prevDaySameHour: "Previous Day Same Hour",
    prevWeekSameHour: "Previous Week Same Hour",

    oneWeek: "1 Week",
    thirtyDays: "30 Days",
    never: "Indefinite",

    isLessThan: "<",
    isGreaterThan: ">",
    isEqualTo: "=",
    isGreaterThanOrEqualTo: ">=",
    isLessThanOrEqualTo: "<=",

    decreasesBy: "<=",

    increasesBy: ">=",

    changesBy: "~",

    total: "absolute",
    percentageChange: "percent",

    topTen: "Top 10",
    bottomTen: "Bottom 10",
    topTwenty: "Top 20",
    bottomTwenty: "Bottom 20",
    any: "Any",

    "": null,
  };

  const getTimeZoneLocation = (value) => {
    const finalLocation = allTimezonesMod.find((el) => el.name === value);
    if (finalLocation === undefined || finalLocation === null) return value;
    else return finalLocation.location;
  };
  const cachedGetTimeZoneLocation = getTimeZoneLocation(row.timeZone.value);
  const cachedEpoch = Date.now().toString();

  let _id = row.id.value;

  let patternName = row.name.value;
  let patternStatus = row.active.value ? "active" : "inactive";
  // ! TODO: Check the priority hardcoded is done here need to verify
  let priority = 3;
  let triggerType = row.basis.value;
  // ! DONE: wrong with metric alert triggerTime is null for metric alert why these hardcoded is done here need to verify
  let triggerTime = { hours: 9, minutes: 0 };
  // let triggerTime = null;
  //  DONE: Going wrong in payload
  let timeZone = {
    name: row.timeZone.value,
    location: cachedGetTimeZoneLocation,
  };
  // ! Hardcoded for Testing uncomment the code
  let createdOn =
    Object.keys(row.actualPayload.value).length == "0"
      ? cachedEpoch
      : row.actualPayload.value.createdOn;
  let modifiedOn = cachedEpoch;
  // let createdOn = "1666717463000";
  // let modifiedOn = "1666717463000";

  let dateRangevalue = reverseMapping[row.comparedWith.value];

  let timestampvalue;

  if (row.duration.value === "oneWeek") {
    timestampvalue = Number(modifiedOn) + 604800000;
  } else if (row.duration.value === "thirtyDays") {
    timestampvalue = Number(modifiedOn) + 2592000000;
  } else {
    timestampvalue = 0;
  }

  let expirationInfo = {
    timestamp: timestampvalue.toString(),
    timePeriod: reverseMapping[row.duration.value],
  };

  let alertType = row.alertType.value;

  let loginInfo = {
    providerID: userEmail,
    providerKey: userEmail,
  };

  let recipients = row.emailRecipients.value.map(
    (recipientList) => recipientList.id
  );

  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  let organization = globalReduxUser?.reqMetadata?.organization;
  let view = globalReduxUser?.reqMetadata?.view;

  let orgViewReq = {
    organization: organization,
    view: view,
  };

  let lastJobSubmissionTime =
    row.crudOperation.value === "create" ? "0" : cachedEpoch;
  // let lastJobSubmissionTime = "0";
  let summary;

  if (
    row.alertType.value === "dimension" &&
    (row.alertCriteriaDimension.value.dimensionRow.value === "add" ||
      row.alertCriteriaDimension.value.dimensionRow.value === "delete")
  ) {
    summary = null;
  } else {
    summary = alertCriteriaFormula({
      state: row,
      allData,
      idFlag: true,
    });
  }

  // ! TODO: we have to check for this because of hardecode to everyday
  let triggerDay = "everyday";
  // ! DONE: we have to check for this because of hardecode to 0
  let statusToggleTime =
    row.crudOperation.value === "create" ? "0" : cachedEpoch;

  var allMetricIds = [];

  // metric array AND/OR defined
  var metricArr = [];
  var metricArrAnd = [];
  var metricArrOr = [];
  var idVal = 10;

  var flag;

  let yAxis = [],
    specialCalculation = [],
    approxCountDistinct = [];

  // ! If alertType is "metric"
  if (alertType === "metric") {
    //   row.alertCriteriaMetric.value.map

    for (let i = 0; i < row.alertCriteriaMetric.value.length; i++) {
      let metricRowVal = row.alertCriteriaMetric.value;

      // For the first metic row it will depend on the subsequent logical condition value
      if (i === 0) {
        if (row.alertCriteriaMetric.value[i].logicalCondition.value === "and") {
          // If OR array is not empty push to metric array and make it empty for the next iteration

          allMetricIds.push(metricRowVal[i].metric.value);
          if (metricArrOr.length !== 0) {
            metricArr.push(metricArrOr);
          }
          metricArrOr = [];

          // If metric ID starts with CM it is custom, and if it  starts with M, it is default
          var metricTypevalue = metricRowVal[i].metric.value.includes("CM")
            ? "custom"
            : "default";

          const metricObj = getMetircObjFromId(metricRowVal[i].metric.value);
          // console.log("sonu", metricObj);
          const metricType = getMetricTypeForChartObject(metricObj);

          if (metricType === "base_sum") {
            yAxis.push(metricRowVal[i].metric.value);
          } else if (metricType === "custom") {
            specialCalculation.push(metricRowVal[i].metric.value);
          } else if (metricType === "base_approxCountDistinct") {
            approxCountDistinct.push(metricRowVal[i].metric.value);
          }

          var operatorTypevalue;
          var isComparisonStatus;

          if (metricRowVal[i].changeCriteria.value === "total") {
            // console.log("if");
            isComparisonStatus = false;
            operatorTypevalue =
              reverseMapping[
                metricRowVal[i].condition.absoluteComparison.value
              ];
          } else if (
            metricRowVal[i].changeCriteria.value === "percentageChange"
          ) {
            // console.log("iffff");
            isComparisonStatus = true;
            operatorTypevalue =
              reverseMapping[
                metricRowVal[i].condition.relativeComparison.value
              ];
          }

          // metric object for AND is created

          var metricObjAnd = {
            // TODO: Need to send this metric and dimension dynamically
            metric: {
              title: metricObj._title,
              _id: metricObj.measureID,
            },

            calculationType:
              reverseMapping[metricRowVal[i].changeCriteria.value],
            isComparison: isComparisonStatus,
            operatorType: operatorTypevalue,
            threshold: metricRowVal[i].conditionValue.value,
            functionType: "sum",
            metricId: metricRowVal[i].metric.value,
            blockId: idVal,
            // TODO: need to check is hardcoded is correct for all cases
            timeRange: 1,
            aggFunc: "sum",
            comparisonInfo: {
              dateRange: dateRangevalue,
              aggFunc: "sum",
              // TODO: need to check is hardcoded is correct for all cases
              timeRange: 1,
            },

            // DONE: Going wrong need to check this for all metrics
            metricType: metricTypevalue,
          };

          metricArrAnd.push({ ...metricObjAnd });
          metricArr.push(metricArrAnd);
          metricArrAnd = [];
        }

        if (row.alertCriteriaMetric.value[i].logicalCondition.value === "or") {
          allMetricIds.push(metricRowVal[i].metric.value);

          metricArrAnd = [];
          var operatorTypevalue;
          var isComparisonStatus;

          if (metricRowVal[i].changeCriteria.value === "total") {
            // console.log("if");
            isComparisonStatus = false;
            operatorTypevalue =
              reverseMapping[
                metricRowVal[i].condition.absoluteComparison.value
              ];
          } else if (
            metricRowVal[i].changeCriteria.value === "percentageChange"
          ) {
            // console.log("iffff");
            isComparisonStatus = true;
            operatorTypevalue =
              reverseMapping[
                metricRowVal[i].condition.relativeComparison.value
              ];
          }

          var metricTypevalue = metricRowVal[i].metric.value.includes("CM")
            ? "custom"
            : "default";
          const metricObj = getMetircObjFromId(metricRowVal[i].metric.value);
          const metricType = getMetricTypeForChartObject(metricObj);

          if (metricType === "base_sum") {
            yAxis.push(metricRowVal[i].metric.value);
          } else if (metricType === "custom") {
            specialCalculation.push(metricRowVal[i].metric.value);
          } else if (metricType === "base_approxCountDistinct") {
            approxCountDistinct.push(metricRowVal[i].metric.value);
          }

          // metric object for OR is created

          var metricObjOr = {
            // TODO: Need to send this metric and dimension dynamically
            metric: {
              title: metricObj._title,
              _id: metricObj.measureID,
            },

            calculationType:
              reverseMapping[metricRowVal[i].changeCriteria.value],
            isComparison: isComparisonStatus,
            operatorType: operatorTypevalue,
            threshold: metricRowVal[i].conditionValue.value,
            // TODO: hardcoded need to verify
            functionType: "sum",
            metricId: metricRowVal[i].metric.value,
            blockId: idVal,
            timeRange: 1,
            // TODO: Need to check if this hardcoding is correct for all if not then changes for comparisionInfo
            aggFunc: "sum",
            comparisonInfo: {
              dateRange: dateRangevalue,
              aggFunc: "sum",
              timeRange: 1,
            },
            metricType: metricTypevalue,
          };

          // Metric array OR is pushed to metric array OR

          metricArrOr.push({ ...metricObjOr });
        }
      }
      // For the remaining rows it will depend on the previous logical condition value(i-1)
      else if (
        row.alertCriteriaMetric.value[i - 1].logicalCondition.value === "and"
      ) {
        // If OR array is not empty push to metric array and make it empty for the next iteration
        allMetricIds.push(metricRowVal[i].metric.value);
        if (metricArrOr.length !== 0) {
          metricArr.push(metricArrOr);
        }
        metricArrOr = [];

        var metricTypevalue = metricRowVal[i].metric.value.includes("CM")
          ? "custom"
          : "default";

        const metricObj = getMetircObjFromId(metricRowVal[i].metric.value);
        const metricType = getMetricTypeForChartObject(metricObj);

        if (metricType === "base_sum") {
          yAxis.push(metricRowVal[i].metric.value);
        } else if (metricType === "custom") {
          specialCalculation.push(metricRowVal[i].metric.value);
        } else if (metricType === "base_approxCountDistinct") {
          approxCountDistinct.push(metricRowVal[i].metric.value);
        }

        var operatorTypevalue;
        var isComparisonStatus;
        if (metricRowVal[i].changeCriteria.value === "total") {
          operatorTypevalue =
            reverseMapping[metricRowVal[i].condition.absoluteComparison.value];
        } else if (
          metricRowVal[i].changeCriteria.value === "percentageChange"
        ) {
          // console.log("iffff");
          isComparisonStatus = true;
          operatorTypevalue =
            reverseMapping[metricRowVal[i].condition.relativeComparison.value];
        }

        // metric object for AND is created

        var metricObjAnd = {
          // TODO: Need to send this metric and dimension dynamically
          metric: {
            title: metricObj._title,
            _id: metricObj.measureID,
          },

          calculationType: reverseMapping[metricRowVal[i].changeCriteria.value],
          isComparison: isComparisonStatus,
          operatorType: operatorTypevalue,
          threshold: metricRowVal[i].conditionValue.value,
          functionType: "sum",
          metricId: metricRowVal[i].metric.value,
          blockId: idVal,
          timeRange: 1,
          aggFunc: "sum",
          comparisonInfo: {
            dateRange: dateRangevalue,
            aggFunc: "sum",
            timeRange: 1,
          },
          metricType: metricTypevalue,
        };

        metricArrOr.push({ ...metricObjAnd });
      } else if (
        row.alertCriteriaMetric.value[i - 1].logicalCondition.value === "or"
      ) {
        allMetricIds.push(metricRowVal[i].metric.value);

        metricArrAnd = [];

        var operatorTypevalue;
        var isComparisonStatus;

        if (metricRowVal[i].changeCriteria.value === "total") {
          // console.log("if");
          operatorTypevalue =
            reverseMapping[metricRowVal[i].condition.absoluteComparison.value];
        } else if (
          metricRowVal[i].changeCriteria.value === "percentageChange"
        ) {
          isComparisonStatus = true;
          operatorTypevalue =
            reverseMapping[metricRowVal[i].condition.relativeComparison.value];
        }

        var metricTypevalue = metricRowVal[i].metric.value.includes("CM")
          ? "custom"
          : "default";

        const metricObj = getMetircObjFromId(metricRowVal[i].metric.value);
        const metricType = getMetricTypeForChartObject(metricObj);

        if (metricType === "base_sum") {
          yAxis.push(metricRowVal[i].metric.value);
        } else if (metricType === "custom") {
          specialCalculation.push(metricRowVal[i].metric.value);
        } else if (metricType === "base_approxCountDistinct") {
          approxCountDistinct.push(metricRowVal[i].metric.value);
        }

        // Metric object for OR is created
        var metricObjOr = {
          // TODO: Need to send this metric and dimension dynamically
          metric: {
            title: metricObj._title,
            _id: metricObj.measureID,
          },
          // dimension: {
          //   title: "Account Executive",
          //   _id: "account_executive",
          // },

          calculationType: reverseMapping[metricRowVal[i].changeCriteria.value],
          isComparison: isComparisonStatus, // Hard coded
          operatorType: operatorTypevalue,
          threshold: metricRowVal[i].conditionValue.value,
          // TODO: harcoded
          functionType: "sum",
          metricId: metricRowVal[i].metric.value,
          blockId: idVal,
          timeRange: 1,
          aggFunc: "sum",
          comparisonInfo: {
            dateRange: dateRangevalue,
            aggFunc: "sum",
            timeRange: 1,
          },

          metricType: metricTypevalue,
        };

        // Metric object OR is pushed to metric array OR

        metricArrOr.push({ ...metricObjOr });
      }
    }

    // If after traversing the loop any OR/AND array is not empty push to metric array

    if (metricArrAnd.length !== 0) {
      metricArr.push(metricArrAnd);
    }
    if (metricArrOr.length !== 0) {
      metricArr.push(metricArrOr);
    }
  }

  // ! If Alert Type is "Dimension"
  if (alertType === "dimension") {
    let dimrowVal = row.alertCriteriaDimension.value;

    var dimOperatorTypevalue;
    var isComparisonStatus;

    if (dimrowVal.changeCriteria.value === "total") {
      // console.log("if");
      isComparisonStatus = false;
      dimOperatorTypevalue =
        reverseMapping[dimrowVal.condition.absoluteComparison.value];
    } else if (dimrowVal.changeCriteria.value === "percentageChange") {
      // console.log("iffff");
      isComparisonStatus = true;
      dimOperatorTypevalue =
        reverseMapping[dimrowVal.condition.relativeComparison.value];
    }

    var dimIdvalue;

    // console.log("dimrowval");

    if (dimrowVal.dimensionRow.value === "change") {
      dimIdvalue = dimrowVal.dimensionChange.value;
    } else if (dimrowVal.dimensionRow.value === "add") {
      isComparisonStatus = true;
      dimIdvalue = dimrowVal.dimensionAdd.value;
    } else if (dimrowVal.dimensionRow.value === "delete") {
      isComparisonStatus = true;
      dimIdvalue = dimrowVal.dimensionDelete.value;
    }

    var metricTypevalue = dimrowVal.metric.value.includes("CM")
      ? "custom"
      : "default";
    const dimObj = getDimensionObjFromId(dimIdvalue);

    var dimNodeList = {
      // NOT NEEDED
      // dimension: {
      //   dimTitle: "Custom Parameter 06",
      //   dimID: dimIdvalue,
      // },

      changeType: dimrowVal.dimensionRow.value,
      metricId:
        row.alertCriteriaDimension.value.dimensionRow.value === "add" ||
        row.alertCriteriaDimension.value.dimensionRow.value === "delete"
          ? undefined
          : dimrowVal.metric.value,
      metricType:
        row.alertCriteriaDimension.value.dimensionRow.value === "add" ||
        row.alertCriteriaDimension.value.dimensionRow.value === "delete"
          ? undefined
          : metricTypevalue,
      dimensionId: dimIdvalue,
      dimensionType: dimObj._title,
      dimensionsizeType: reverseMapping[dimrowVal.group.value],
      blockId: 11,
      isComparison: isComparisonStatus,
      comparisonInfo: {
        dateRange: dateRangevalue,
        aggFunc: "sum",
        timeRange: 1,
      },
      timeRange: 1,
      calculationType: reverseMapping[dimrowVal.changeCriteria.value],
      operatorType: dimOperatorTypevalue,
      threshold:
        row.alertCriteriaDimension.value.dimensionRow.value === "add" ||
        row.alertCriteriaDimension.value.dimensionRow.value === "delete"
          ? undefined
          : dimrowVal.conditionValue.value,
      aggFunc:
        row.alertCriteriaDimension.value.dimensionRow.value === "add" ||
        row.alertCriteriaDimension.value.dimensionRow.value === "delete"
          ? undefined
          : "sum",
      // NOT NEEDED
      // functionType: "sum",

      // NOT NEEDED
      // metric: { title: "Countries", _id: dimrowVal.metric.value },
    };
  }

  let i = 1;
  let j = 0;
  for (let x = 0; x < metricArr.length; x++) {
    for (let y = 0; y < metricArr[x].length; y++) {
      j++;
      metricArr[x][y].blockId = Number(`${i}${j}`);
    }
    i++;
    j = 0;
  }

  let expression = {};
  let dimCriteria = [];

  if (alertType === "metric") {
    expression = {
      // alertType,
      metricNodeList: metricArr,
      alertType: row.alertType.value,
      dimNodeList: null,
    };
  }
  if (alertType === "dimension") {
    expression = {
      // alertType,
      dimNodeList,
      // metricNodeList: null,
      // alertType: row.alertType.value,
    };
    // dimCriteria.push(dimNodeList);
  }

  let customMetricvalue = [];
  let defaultMetricvalue = [];

  for (let i = 0; i < allMetricIds.length; i++) {
    if (allMetricIds[i].includes("CM")) {
      customMetricvalue.push(allMetricIds[i]);
    } else {
      defaultMetricvalue.push(allMetricIds[i]);
    }
  }

  // console.log("basisVal", row.basis.value);
  var xAxisvalue = [];
  if (row.basis.value === "hourly") {
    xAxisvalue.push("D001");
  } else if (row.basis.value === "daily") {
    xAxisvalue.push("D002");
  }

  const payload = {
    metadata: {
      title: v4(), //done
      img_thumbnail: "../img/chart.png", //done
      chartType: "chart", //done
      dataLimit: 5000, //done
    },
    requestParam: {
      // TODO: Hardcode as of now granulity
      granularity: globalFilters?.metricChartGranularity?.value, //done
      // DONE: get name and location from somewhere
      timeZone: {
        name: row.timeZone.value,
        location: cachedGetTimeZoneLocation,
      },
      // TODO: get start and end date from user.timeFilters.selectedDatesQE.startDate.toString()
      dateRange: {
        startDate: "1634688000000", //user.timeFilters.selectedDatesQE.startDate.toString()
        endDate: "1635202800000", //user.timeFilters.selectedDatesQE.endDate.toString()
      },
      // DONE: if day then D002, if hour then D001
      xAxis: [...xAxisvalue],
      // DONE: grab all metric IDs from alert selections (only for metric alert); bifurcate between yAxis and specialCalculation
      yAxis,
      approxCountDistinct,
      specialCalculation,
      // defaultMetricvalue
      filter: transformFiltersUiToBackend(row.dimensionFilters.value),
      // DONE: Make always empty because of no sorting on any thing
      // TODO: Pick first element of the selected metrics and based on metric type, make the necessary orderBy
      orderBy: {},
      percentCalList: [],
    },
  };

  // DONE: Add this feild into alert
  const isActive = row.active.value;
  let filter = transformFiltersUiToBackend(row.dimensionFilters.value);

  // DONE: This is the hardcoded told by backend because in Alert there is no any timeFilters

  const dimcriteria = [];
  // DONE: here it will set comparisonInfo.dateRange
  const comparisonPreset = {
    name: dateRangevalue,
  };
  let finalObj = {};

  if (alertType === "dimension") {
    finalObj = {
      _id,
      patternName,
      patternStatus,
      loginInfo,
      payload,
      lastJobSubmissionTime,
      createdOn,
      modifiedOn,
      triggerType,
      summary,
      triggerDay,
      statusToggleTime,
      triggerTime,
      recipients,
      expirationInfo,
      priority,
      alertType,
      isActive,
      filter,
      timeZone,
      dimcriteria: dimcriteria,
      isComparision: isComparisonStatus,
      comparisonPreset,
      expression,
      // Last time hardcoded
      timeRange: 1,
      userPreferredLanguage: row.userPreferredLanguage.value,

      orgViewReq,
    };
  } else {
    finalObj = {
      _id,
      patternName,
      patternStatus,
      loginInfo,
      payload,
      summary,
      lastJobSubmissionTime,
      createdOn,
      modifiedOn,
      triggerType,
      comparisonPreset,
      // Last time hardcoded
      timeRange: 1,
      triggerDay,
      statusToggleTime,
      isComparision: isComparisonStatus,
      dimcriteria,
      filter,
      timeZone,
      expirationInfo,
      recipients,
      alertType,
      triggerTime,
      priority,
      orgViewReq,
      isActive,
      expression,
      userPreferredLanguage: row.userPreferredLanguage.value,
    };
  }
  console.log("sonu wrapper", finalObj);
  return finalObj;
};

function unwrapperSummary(state) {
  let {
    name,
    basis,
    timeZone,
    alertType,
    alertCriteriaMetric,
    alertCriteriaDimension,
    comparedWith,
    dimensionFilters,
    duration,
    emailRecipients,
  } = state;
  //* Fields Required for summary
  // *1.Name
  // *2.Alert Me
  // *3.Critertia Formula
  // *4.Include Filters
  // *5.Exclude Filters
  // *6.Alert Ends In
  // *7.Email Recipients

  //1.Name
  const nameX = name.value;

  //Alert Ends In
  const alertDuration =
    duration.value === "never"
      ? "Never"
      : duration.value === "oneWeek"
      ? "1 Week"
      : "30 Days";

  //3.Email recipients
  const emailRecipientsX = emailRecipients.value.map((el) => el.name);

  //4.include and exclude filters
  const alertFilters = dimensionFilters.value;
  //Alert me

  const alertBasis = basis.value;
  const validComparedWith =
    alertBasis === "daily"
      ? comparedWith.value === "prevDay"
        ? "Previous Day"
        : comparedWith.value === "prevWeekSameDay"
        ? "Previous Week Same Day"
        : "Previous Month Same Day"
      : comparedWith.value === "prevHour"
      ? "Previous Hour"
      : comparedWith.value === "prevDaySameHour"
      ? "Previous Day Same Hour"
      : "Previous Week Same Hour";

  const alertTimeZone = timeZone.value;
  const alertMetricCriteriaObj = () =>
    alertCriteriaMetric.value.map((el) => {
      const {
        changeCriteria,
        conditionValue,
        id,
        logicalCondition,
        condition,
        metric,
      } = el;
      //*all variables named as {variable}X to remove the same name conflict
      const changeCriteriaX =
        changeCriteria.value === "total"
          ? "Total"
          : changeCriteria.value === "percentageChange"
          ? "Percentage Change"
          : "Absolute Change";
      const conditionSelecter = changeCriteria.value === "percentageChange";
      const conditionRelative =
        condition.relativeComparison.value === "increasesBy"
          ? "Increases By"
          : condition.relativeComparison.value === "decreasesBy"
          ? "Decreases By"
          : "Changes By";
      const conditionAbsolute =
        condition.absoluteComparison.value === "isLessThan"
          ? "Is Less Than"
          : condition.absoluteComparison.value === "isGreaterThan"
          ? "Is Greater Than"
          : condition.absoluteComparison.value === "isLessThanOrEqualTo"
          ? "Is Less Than Or Equal To"
          : condition.absoluteComparison.value === "isGreaterThanOrEqualTo"
          ? "Is Greater Than Or Equal To"
          : "Is Equal to";
      const conditionX = conditionSelecter
        ? conditionRelative
        : conditionAbsolute;
      const logicalConditionX = logicalCondition.value.toUpperCase();
      const metricX = metric.value;
      const conditionValueX = conditionValue.value;
      var criteriaObj = {
        id,
        changeCriteria: changeCriteriaX,
        conditionValue: conditionValueX,
        logicalCondition: logicalConditionX,
        condition: conditionX,
        metric: metricX,
      };
      return criteriaObj;
    });

  const alertDimensionCriteriaObj = () => {
    const {
      changeCriteria,
      metric,
      dimensionChange,
      dimensionAdd,
      dimensionDelete,
      condition,
      conditionValue,
      dimensionRow,
      group,
    } = alertCriteriaDimension.value;

    const changeCriteriaX =
      changeCriteria.value === "total"
        ? "Total"
        : changeCriteria.value === "percentageChange"
        ? "Percentage Change"
        : "Absolute Change";
    const metricX = metric.value;
    const groupX =
      group.value === "bottomTen"
        ? "Bottom 10"
        : group.value === "bottomTwenty"
        ? "Bottom 20"
        : group.value === "topTen"
        ? "Top 10"
        : group.value === "topTwenty"
        ? "Top 20"
        : "Any";
    const dimensionX =
      dimensionRow.value === "change"
        ? dimensionChange.value
        : dimensionRow.value === "add"
        ? dimensionAdd.value
        : dimensionDelete.value;
    const conditionSelecter = changeCriteria.value === "percentageChange";
    const conditionRelative =
      condition.relativeComparison.value === "increasesBy"
        ? "Increases By"
        : condition.relativeComparison.value === "decreasesBy"
        ? "Decreases By"
        : "Changes By";
    const conditionAbsolute =
      condition.absoluteComparison.value === "isLessThan"
        ? "Is Less Than"
        : condition.absoluteComparison.value === "isGreaterThan"
        ? "Is Greater Than"
        : condition.absoluteComparison.value === "isLessThanOrEqualTo"
        ? "Is Less Than Or Equal To"
        : condition.absoluteComparison.value === "isGreaterThanOrEqualTo"
        ? "Is Greater Than Or Equal To"
        : "Is Equal to";
    const conditionX = conditionSelecter
      ? conditionRelative
      : conditionAbsolute;
    const conditionValueX = conditionValue.value;
    const dimensionRowX = dimensionRow.value;
    const finalObj = {
      changeCriteria: changeCriteriaX,
      metric: metricX,
      group: groupX,
      dimension: dimensionX,
      condition: conditionX,
      conditionValue: conditionValueX,
      dimensionRow: dimensionRowX,
    };
    return finalObj;
  };

  const alertMeWhen = {
    comparedWith: validComparedWith,
    basis: alertBasis,
    timeZone: alertTimeZone,
  };

  //pending
  const alertCriteriaFormulaObj = alertCriteriaMetric.value.map((el, i) => {
    if (alertType.value === "metric") {
      const {
        changeCriteria,
        conditionValue,
        id,
        logicalCondition,
        condition,
        metric,
      } = el;
      if (changeCriteria.value === "percentageChange") {
        var criteriaFormulaObj = {
          formula: {
            changeCriteria:
              changeCriteria.value === "total"
                ? "Total"
                : changeCriteria.value === "percentageChange"
                ? "Percentage Change"
                : "Absolute Change",
            metric: metric.value,
            condition:
              condition.relativeComparison.value === "increasesBy"
                ? ">="
                : condition.relativeComparison.value === "decreasesBy"
                ? "<="
                : "~",
            conditionValue: conditionValue.value,
          },
          logicalCondition: logicalCondition.value.toUpperCase(),
        };
      } else {
        var criteriaFormulaObj = {
          formula: {
            changeCriteria:
              changeCriteria.value === "total"
                ? "Total"
                : changeCriteria.value === "percentageChange"
                ? "Percentage Change"
                : "Absolute Change",
            metric: metric.value,
            condition:
              condition.absoluteComparison.value === "isLessThan"
                ? "<"
                : condition.absoluteComparison.value === "isGreaterThan"
                ? ">"
                : condition.absoluteComparison.value === "isLessThanOrEqualTo"
                ? "<="
                : condition.absoluteComparison.value ===
                  "isGreaterThanOrEqualTo"
                ? ">="
                : "=",
            conditionValue: conditionValue.value,
          },
          logicalCondition: logicalCondition.value.toUpperCase(),
        };
      }

      return criteriaFormulaObj;
    }
  });
  // console.log("alertCriteriaFormulaObj", alertCriteriaFormulaObj);
  //! for creating the 2d array of criteria formulae
  var finalArrOfCriteriaFormula = [];
  var idx = 0;
  var tempArr = [];
  if (state.alertType.value === "metric") {
    for (const el of alertCriteriaFormulaObj) {
      if (el.logicalCondition === "AND") {
        tempArr.push(el);
        finalArrOfCriteriaFormula[idx++] = tempArr;
        tempArr = [];
      } else {
        tempArr.push(el);
      }
    }
  }
  // console.log("row finalArrOfCriteriaFormula", finalArrOfCriteriaFormula);
  //   console.log(arr);
  //final Object
  const summaryObj = {
    name: nameX,
    alertDuration,
    emailRecipients: emailRecipientsX,
    alertFilters,
    alertMeWhen,
    alertCriteria: {
      metric: alertMetricCriteriaObj(),
      dimension: alertDimensionCriteriaObj(),
    },
    criteriaFormula: finalArrOfCriteriaFormula,
  };
  // console.log("summaryObj", summaryObj);
  return summaryObj;
}

function unWrapperFuncSideNav(props) {
  // console.log("props", props);

  const unwrappedValue = [];

  props.map((row) => {
    const idvalue = row._id;
    const namevalue = row.patternName;

    const validTillvalue = Number(row.expirationInfo.timestamp);
    const validityDefine = row.expirationInfo.timePeriod;

    var id = {
      message: "",
      value: idvalue,
      status: "valid",
    };

    var name = {
      message: "",
      value: namevalue,
      status: "valid",
    };

    var isActive = {
      message: "",
      value: row.patternStatus === "active" ? true : false,
      status: "valid",
    };
    const isActiveData = {
      message: "",
      status: "success",
    };

    let validTill;

    if (validityDefine === "Indefinite") {
      validTill = "Never expires";
    } else {
      validTill = `Valid till ${moment(new Date(validTillvalue)).format(
        config.hardCoded.dateFormatAlertsList
      )}`;
    }

    unwrappedValue.push({
      id: id.value,
      alertTitle: name.value,
      isActive: isActive.value,
      alertSubTitle: validTill,
      isActiveData,
    });
  });
  // console.log("unwrappedValue", unwrappedValue);

  let finalObj = {
    status: "success",
    message: "",
    data: unwrappedValue,
  };

  // console.log("finalObj", finalObj);

  return finalObj;
}

const getAllPlotlyMetrics = (allData) => {
  const metricArr = allData.plotlyMetrics.map((row) => ({
    id: row._id,
    name: row._title,
    disabled: false,
  }));

  const filteredMetricArr = metricArr.sort(function (a, b) {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
  const allPlotlyMetrics = [
    {
      id: "selectMetric",
      name: "Select Metric",
      disabled: true,
    },
    ...filteredMetricArr,
  ];
  return allPlotlyMetrics;
};

const getAllPlotlyDimensions = (allData) => {
  const dimArr = allData.plotlyDimensions.map((row) => ({
    id: row._id,
    name: row._title,
    disabled: false,
  }));
  const filteredDimArr = dimArr.sort(function (a, b) {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
  const allPlotlyDimensions = [
    {
      id: "selectDimension",
      name: "Select Dimension",
      disabled: true,
    },
    ...filteredDimArr,
  ];
  return allPlotlyDimensions;
};

const getNewAlertCriteriaRow = () => ({
  id: v4(),
  changeCriteria: {
    message: "",
    value: "total",
    status: "valid",
  },
  metric: {
    message: "Please select a metric",
    value: "selectMetric",
    status: "invalid",
  },
  condition: {
    relativeComparison: {
      message: "",
      value: "increasesBy",
      status: "valid",
    },
    absoluteComparison: {
      message: "",
      value: "isLessThan",
      status: "valid",
    },
  },
  conditionValue: {
    message: "Please enter a value",
    value: "",
    status: "invalid",
  },
  logicalCondition: {
    message: "",
    value: "and",
    status: "valid",
  },
});
const getDimensionObjFromId = (dimId, alertType, allData) => {
  let dimObj;
  if (dimId && alertType === "dimension") {
    dimObj = allData.plotlyDimensions.find((el) => el._id === dimId);
    if (dimObj === undefined) dimObj = dimId;
    return dimObj;
  } else {
    if (dimId === undefined) {
      return (dimObj = "Undefined");
    }
  }
};
const getMetricObjFromId = (metricId, alertType, allData) => {
  let metricObj;
  if (metricId && alertType === "metric") {
    metricObj = allData.plotlyMetrics.find((el) => el._id === metricId);
    if (metricObj === undefined) metricObj = metricId;
    return metricObj;
  } else {
    if (metricId === undefined) {
      return (metricObj = "Undefined");
    }
  }
};
const getMetricObjByFromId = (metricId, allData) => {
  let metricObj = allData.plotlyMetrics.find((el) => el._id === metricId);
  if (metricObj === undefined || metricObj === null) {
    return null;
  } else {
    return metricObj._id;
  }
};

export {
  getDimensionObjFromId,
  getMetricObjFromId,
  getMetricObjByFromId,
  validateAlertFormData,
  unWrapperFunc,
  wrapper,
  unwrapperSummary,
  unWrapperFuncSideNav,
  alertFormData,
  userSelection,
  getAllPlotlyDimensions,
  getAllPlotlyMetrics,
  getNewAlertCriteriaRow,
  alertCriteriaFormula,
  getMetricTitleFormId,
  getCriteriaFormulae,
};

// * Import required libraries
import { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { connect } from "react-redux";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * import lib component
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

// * Import custom components
import LayoutBottom from "../../layouts/LayoutBottom/LayoutBottom";
import SigviewTypography from "../../components/Common/SigviewTypography";
import SigviewButton from "../../components/Common/SigviewButton";

// * Import styles
import signInImage from "../../../assets/images/loginFlowImg.svg";
import SigviewIcon from "../../components/Common/SigviewIcon";
import { isEmbeddedUser } from "../../utils/authUtils";

// * Import action creators
import { updateAuthError } from "../../redux/actions";

// * Define style functions
const makeSigviewStyles = (...args) => {
  const [themeColors, customStyle] = args;
  const useStyles = makeStyles({
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
    },
    leftPanel: {
      width: "50%",
      height: "100%",
      display: "inline-block",
    },
    rightPanel: {
      width: "50%",
      height: "100%",
      display: "inline-block",
      background: "#045ad9",
      borderRadius: "50px 0px 0px 50px",
    },
    errorTextBox: {
      display: "flex",
      paddingBottom: " 5px",
    },
    signInImageContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    sigmoidLogoContainer: {
      height: "50px",
      width: "100px",
      padding: "50px",
      cursor: "pointer",
    },
    signInSignUpImageContainer: {
      width: "100%",
      height: "calc(100vh - 20px)",
      background: "url(" + signInImage + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "center",
      borderRadius: "50px 0px 0px 50px",
    },
    mainSignInContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100% - 50px)",
    },
    mainSignInFormRow: {
      padding: "10px",
      width: "350px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
  });
  return useStyles;
};

const SignInImage = (props = {}) => {
  const { classes } = props;
  return (
    <Box className={classes.signInSignUpImageContainer}>
      {/* <img src={signInImage} width="80%" height="80%" /> */}
    </Box>
  );
};

const PostLogout = (props = {}) => {
  const { classes, eventHandlers, themeColors, count } = props;
  const { handleBackToHome } = eventHandlers;

  // * Define required variables
  const isEmbeddedUserFlag = isEmbeddedUser();
  const logoutCountNonZeroTitle = `You will be automatically redirected to ${
    isEmbeddedUserFlag ? "sso error" : "login"
  } page in ${count} seconds`;
  const logoutCountZeroTitle = `Redirecting to ${
    isEmbeddedUserFlag ? "SSO Error" : "Login"
  } Page...`;

  return (
    <>
      <Box className={classes.mainSignInContainer}>
        <Box
          css={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "350px",
            padding: "20px 0px 0px 0px",
          }}
        >
          <Box>
            <SigviewIcon
              iconName="check_circle"
              style={{
                fontSize: "30px !important",
                padding: "0px 10px 0px 0px",
                color: themeColors["primaryColor"],
                hoverColor: themeColors["primaryColor"],
                cursor: "default",
              }}
            />
          </Box>

          <SigviewTypography
            variant="pLargeMiddle"
            style={{
              lineHeight: "16px",
              textAlign: "left",
              padding: "10px 10px 10px 0px",
              width: "350px",
              height: "auto",
            }}
          >
            You have been successfully logged out!
          </SigviewTypography>
        </Box>
        <Box css={{ paddingLeft: "83px" }}>
          {count > 0 && (
            <SigviewTypography
              variant="pMedium"
              style={{
                lineHeight: "16px",
                textAlign: "left",
                width: "350px",
                height: "auto",
                padding: "10px",
              }}
            >
              {logoutCountNonZeroTitle}
            </SigviewTypography>
          )}
        </Box>
        <Box className={classes.mainSignInFormRow}>
          {count == 0 && (
            <SigviewTypography
              variant="containedLighterNewBlue"
              style={{
                btnWidth: "350px",
                btnHeight: "35px",
                fontSize: "12px !important",
                bgColor: "#045ad9",
                color: "#fff",
                bgHoverColor: "#fff",
                backgroundColor: "#045ad9",
              }}
            >
              {logoutCountZeroTitle}
            </SigviewTypography>
          )}
        </Box>
        {!isEmbeddedUserFlag && (
          <Box className={classes.mainSignInFormRow}>
            <SigviewButton
              variant="containedLighterNewBlue"
              style={{
                btnWidth: "350px",
                btnHeight: "35px",
                fontSize: "12px !important",
                bgColor: "#045ad9",
                color: "#fff",
                bgHoverColor: "#fff",
                backgroundColor: "#045ad9",
              }}
              onClick={handleBackToHome}
              title="Back to home"
            />
          </Box>
        )}
      </Box>
    </>
  );
};

function Logout(props = {}) {
  // * Destructure props
  const { dispatch: ReduxDispatcher, auth } = props;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define required states
  const [count, setCount] = useState(5);

  // * Define required hooks
  const history = useHistory();
  const location = useLocation();

  // * Define required side effects
  useEffect(() => {
    // * If this component mounts, it means the user has been logged out
    // Hence, do everything that needs to be done on logout here
    // 1. Clear local storage
    // 2. Change location to /logout if already not (this may happen when the user is forcefully logged out but the history.push('/logout) didn't happen') like in index.js of api services file)

    // 1. Clear local storage
    localStorage.clear();

    // 2. Change location to /logout if already not (this may happen when the user is forcefully logged out but the history.push('/logout) didn't happen') like in index.js of api services file)
    const pathname = location.pathname;
    if (pathname !== "/logout") {
      history.push("/logout");
    }

    // ! OLD IMPLEMENATION
    // ! the below code creates a memory leak issue as when prevCount is 0,
    // ! the component is unmounted and setState happens
    // var intervalId = setInterval(() => {
    //   setCount((prevCount) => {
    //     if (prevCount === 0) {
    //       handleBackToHome();
    //     } else {
    //       return prevCount - 1;
    //     }
    //   });
    // }, 1000);

    // * NEW IMPLEMENTATION
    // 1. Add an interval of redirect
    // 2. Add another useEffect for redirection on home page
    var intervalId = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // Automatic Redirection on home Link count
  useEffect(() => {
    if (count === 0) {
      handleBackToHome();
    }
  }, [count]);

  // * Define requried event handlers
  const handleBackToHome = () => {
    // Reset state to error to show sign in screen
    const payload = {
      status: "error",
      isTokenValid: false,
    };
    const action = updateAuthError(payload);
    ReduxDispatcher(action);

    if (isEmbeddedUser()) {
      history.push("/sso/loginErrorMsg");
    } else {
      if (auth.sigviewUserType === "nonSigview") {
        history.push("/openxSignIn");
      } else {
        history.push("/sign-in");
      }
    }
  };

  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  const commonChildrenProps = {
    classes,
    eventHandlers: { handleBackToHome },
    themeColors,
    count,
  };

  return (
    <>
      <LayoutBottom>
        <Box className={classes.root}>
          <Box className={classes.leftPanel}>
            <PostLogout {...commonChildrenProps}></PostLogout>
          </Box>
          <Box className={classes.rightPanel}>
            <SignInImage {...commonChildrenProps} />
          </Box>
        </Box>
      </LayoutBottom>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  changeFlag: state.changeFlag,
  auth: state.auth,
});

export default connect(mapStateToProps)(Logout);

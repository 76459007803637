// * Import required libraies
import {
  React,
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import orderBy from "lodash.orderby";
import { Drawer } from "@material-ui/core";

// * Import custom components
import SigviewButton from "../Common/SigviewButton";
import SigviewSearchField from "../Common/SigviewSearchField";
import SigviewTypography from "../Common/SigviewTypography";
import DimensionDndChecklist from "./DimensionDndChecklist";

// * Import data/utils

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * Define style functions
const makeSigviewStyles = (...args) => {
  const [themeColors, customStyle] = args;
  const useStyles = makeStyles({
    root: {
      width: "350px",
      height: "100%",
      margin: "0 auto",
      boxSizing: "border-box",
    },
    header: {
      height: "40px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0px 15px",
      boxSizing: "border-box",
      borderBottom: `1px solid ${themeColors["secondaryColorLightest"]}`,
    },
    body: { height: "calc(100% - 80px)", boxSizing: "border-box" },
    dimContainer: {
      height: "100%",
      // backgroundColor: "red",
      boxSizing: "border-box",
      padding: "10px 0px",
    },
    dimChecklistContainer: {
      height: "calc(100% - 25px)",
      boxSizing: "border-box",
      padding: "10px 15px",
      overflow: "auto",
    },
    dimChecklistContainerWithoutSearch: {
      height: "100%",
      boxSizing: "border-box",
      padding: "10px 15px",
      overflow: "auto",
    },
    metricContainer: {
      height: "40%",
      backgroundColor: themeColors["secondaryColorLightest"],
      boxSizing: "border-box",
    },
    metricTitleContainer: {
      height: "35px",
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box",
      padding: "0px 15px",
    },
    searchContainer: {
      height: "25px",
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box",
      padding: "0px 15px",
    },
    metricChecklistContainer: {
      height: "calc(100% - 60px)",
      boxSizing: "border-box",
      padding: "10px 15px",
      overflow: "auto",
    },
    footer: {
      height: "40px",
      display: "flex",
      padding: "0px 15px",
      justifyContent: "flex-end",
      alignItems: "center",
      boxSizing: "border-box",
    },
  });
  return useStyles;
};

// * Define required utility functions
const manipulateSelections = (initialSelections) => {
  const modSelections = initialSelections.map((row) => ({
    id: row.id,
    metrics: row.metricsList.map((kpi) => kpi._id),
  }));
  return modSelections;
};

function AddDimensionDrawer(props = {}) {
  const {
    data = {},
    initialSelections = [],
    onChange = () => {},
    onCloseHandler = () => {},
    isOpen,
    customStyle = {},
    dimOpenByDefault = [],
  } = props;
  const { dimData = [], metricData = [] } = data;

  // * Define required hooks/contexts
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Define required states
  const [selections, setSelections] = useState(
    manipulateSelections(initialSelections)
  );
  const [dimSearch, setDimSearch] = useState("");
  const [openAccordions, setOpenAccordions] = useState(dimOpenByDefault); // This state is introduced to control opening of a dimension from dimension table
  const originalSelections = useRef(manipulateSelections(initialSelections));

  // * Define required side effects
  // ! Commented this as it creates a bug where the banner change resets this state
  // useEffect(() => {
  //   setSelections(manipulateSelections(initialSelections));
  // }, [initialSelections]);
  useEffect(() => {
    setOpenAccordions(dimOpenByDefault);
  }, [dimOpenByDefault]);
  // Reset all accordions to close when drawer closes
  useEffect(() => {
    if (!isOpen) setOpenAccordions([]);
    if (isOpen) setSelections(manipulateSelections(initialSelections));
  }, [isOpen]);

  // * Define required handler functions
  const handleOnClose = useCallback(() => {
    onCloseHandler(false);
    // Reset selections
    setSelections(originalSelections.current);
  }, []);

  const handleDimensionChange = (value) => setSelections(value);
  const handleDimSearchFieldChange = (event, value) => setDimSearch(value);
  const handleDone = () => {
    onChange(selections);
    originalSelections.current = selections;
    onCloseHandler(false);
  };

  // * Define required variables
  const useSigviewStyles = makeSigviewStyles(themeColors, customStyle);
  const classes = useSigviewStyles();
  // Modify Dim Data
  var modifiedDimData = useMemo(() => {
    return [...dimData];
  }, [...dimData]);
  modifiedDimData = useMemo(
    () =>
      modifiedDimData.filter(
        (row) =>
          !["day", "hour", "month", "week"].includes(row.title.toLowerCase())
      ),
    [modifiedDimData]
  );
  modifiedDimData = useMemo(
    () =>
      modifiedDimData.map((row) => {
        return { id: row.id, name: row.title, disabled: false, description: row.description};
      }),
    [modifiedDimData]
  );
  modifiedDimData = useMemo(
    () => orderBy(modifiedDimData, (item) => item.name, ["asc"]),
    [modifiedDimData]
  );
  modifiedDimData = useMemo(
    () =>
      modifiedDimData.filter((row) =>
        row.name.toLowerCase().includes(dimSearch.toLowerCase())
      ),
    [dimSearch]
  );

  // Modify Metric Data
  var modifiedMetricData = useMemo(() => [...metricData], [...metricData]);
  modifiedMetricData = useMemo(
    () =>
      modifiedMetricData.map((row) => ({
        id: row._id,
        name: row._title,
        disabled: false,
      })),
    [modifiedMetricData]
  );
  modifiedMetricData = useMemo(
    () => orderBy(modifiedMetricData, (item) => item.name, ["asc"]),
    [modifiedMetricData]
  );

  const isSearchVisible = dimData.length > 1;
  var dimChecklistClassName = classes.dimChecklistContainer;
  if (!isSearchVisible) {
    dimChecklistClassName = classes.dimChecklistContainerWithoutSearch; // This is to cater to CSS
  }

  // * DEBUGGER
  // console.groupCollapsed("AddDimensionDrawer.js");
  // console.log("initialSelections", initialSelections);
  // console.log("selections", selections);
  // console.log("data", data);
  // console.log("metricData", metricData);
  // console.groupEnd();

  return (
    <>
      <Drawer anchor="left" open={isOpen} onClose={handleOnClose}>
        <Box className={classes.root}>
          {/* HEADER */}
          <Box className={classes.header}>
            {isSearchVisible && (<SigviewTypography
              variant="pLarge"
              style={{ height: "max-content" }}
            >
              Add Dimension Tables
            </SigviewTypography>
            )}
            {!isSearchVisible && (<SigviewTypography
              variant="pLarge"
              style={{ height: "max-content" }}
            >
              Edit Dimension Tables
            </SigviewTypography>
            )}
            <span
              className="material-icons sigview-close-icon"
              onClick={handleOnClose}
            >
              close
            </span>
          </Box>

          {/* BODY */}
          <Box className={classes.body}>
            <Box className={classes.dimContainer}>
              {isSearchVisible && (
                <Box className={classes.searchContainer}>
                  <SigviewSearchField
                    onChange={handleDimSearchFieldChange}
                    placeholder="Search a Dimension"
                  />
                </Box>
              )}
              <Box
                className={`${dimChecklistClassName} sigview-styled-scroller-thin`}
              >
                {modifiedDimData.length > 0 && (
                  <DimensionDndChecklist
                    data={modifiedDimData}
                    selections={selections}
                    setSelections={setSelections}
                    onChange={handleDimensionChange}
                    metricData={modifiedMetricData}
                    openAccordions={openAccordions}
                    setOpenAccordions={setOpenAccordions}
                  />
                )}
                {modifiedDimData.length === 0 && (
                  <Box className="no-data-container">
                    <Box className="no-data-image"></Box>
                    <Box component="p" className="no-data">
                      No Dimensions Available
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          {/* FOOTER */}
          <Box className={classes.footer}>
            <SigviewButton
              title="Done"
              variant="contained"
              onClick={handleDone}
            />
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default AddDimensionDrawer;

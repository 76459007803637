import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import isEqual from "lodash.isequal";
import React, { memo, useContext } from "react";
import { connect } from "react-redux";
import { ThemeContext } from "../../contexts/ThemeContext";
import { getCommonMetricsFromAllDimensions } from "../../utils/analyzeUtils";
import SigviewDndChecklist from "../Common/SigviewDndChecklist";
import SigviewIcon from "../Common/SigviewIcon";
import SigviewTooltip from "../Common/SigviewTooltip";
import SigviewTypography from "../Common/SigviewTypography";

function areEqual(prevProps, nextProps) {
  let prev = prevProps;
  let next = nextProps;
  //   prev = delete prev.children;
  //   next = delete next.children;

  var prev2 = {};
  var next2 = {};

  if (
    (prev.selections.find((el) => el.id === prev.item.id) &&
      next.selections.find((el) => el.id === next.item.id)) ||
    (prev.selections.find((el) => el.id !== prev.item.id) &&
      next.selections.find((el) => el.id !== next.item.id))
  ) {
    // delete prev.selections;
    // delete next.selections;

    prev = { ...prev, selections: [] };
    next = { ...next, selections: [] };
    // console.log("qqqqqq", prev, next);
  }

  //   console.log("SigviewTypography arequal", isEqual(prev, next), prev, next);
  return isEqual(prev, next);
}

// * Define required variables
const makeSigviewStyles = (...args) => {
  const [themeColors, style = {}] = args;
  const useStyles = makeStyles(() => ({
    root: {
      display: "flex",
      flexDirection: "column",
      color: themeColors["sidenavItemColor"],
      // backgroundColor: themeColors["sidenavBgColor"],
      height: "100%",
      // padding: "10px 12px",
      boxSizing: "border-box",
    },
    itemBase: { display: "flex", alignItems: "center", width: "100%" },
    checkboxItemLabel: {
      fontSize: "12px",
      color: themeColors["secondaryColor"],
      paddingLeft: "0px",
      paddingRight: "2px",
      boxSizing: "border-box",
      width: "calc(100% - 18px - 16px)",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      display: "inline-block",
      overflow: "hidden",
      cursor: "pointer",
    },
    checkboxItemLabelSelected: { color: themeColors["primaryColor"] },
    disabledItem: {
      opacity: 0.5,
      pointerEvents: "none",
    },
    accordionRoot: {
      "&.MuiAccordion-rounded": {
        borderBottom: `1px solid ${themeColors["secondaryColorLightest"]}`,
      },
      "&.Mui-expanded": { marginBottom: "5px !important" },
      "&.Mui-expanded.MuiAccordion-rounded": {
        borderBottom: `0px solid transparent !important`,
      },
      "&.MuiAccordion-rounded:last-child": {
        borderBottomLeftRadius: "0px !important",
        borderBottomRightRadius: "0px !important",
        borderTopRightRadius: "0px !important",
        borderTopLeftRadius: "0px !important",
      },
      "&.MuiAccordion-rounded:first-child": {
        borderBottomLeftRadius: "0px !important",
        borderBottomRightRadius: "0px !important",
        borderTopRightRadius: "0px !important",
        borderTopLeftRadius: "0px !important",
      },
      "& .MuiAccordionSummary-root": {
        padding: "0px 7px !important",
      },
      "& .MuiButtonBase-root": {
        minHeight: "25px !important",
      },
      "& .MuiAccordionSummary-content": {
        margin: "5px 0px !important",
        width: "100%",
      },
    },
  }));
  return useStyles;
};

function ChecklistRowDim(props = {}) {
  const { state: themeState } = useContext(ThemeContext);

  const themeColors = themeState.themes[themeState.activeTheme];
  // * Destructure props
  const {
    item = {},
    variant = "selected",
    openAccordions = [],
    setOpenAccordions = () => {},
    provided = {},
    selectedMetrics = [],
    selections = [],
    setSelections = () => {},
    metricData = [],
  } = props;

  // Styling
  const SigviewCheckbox = withStyles(() => ({
    root: {
      opacity: 1,
      color: `${themeColors["primaryColor"]} !important`,
      padding: "0px !important",
      marginRight: "5px",
      "& .MuiSvgIcon-root": {
        width: 18,
        height: 18,
      },
      "&.MuiCheckbox-colorSecondary.Mui-checked:hover": {
        backgroundColor: "transparent",
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  }))(Checkbox);
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  const droppableStyle = {
    padding: "0px",
    backgroundColorDuringDragging: "transparent",
    backgroundColor: "transparent",
  };
  const draggableStyle = {
    backgroundColorDuringDragging: themeColors["secondaryColorLightest"],
    backgroundColor: themeColors["secondaryColorLightest"],
    marginRight: "0px",
    marginBottom: "0px",
    borderRadius: "3px",
    border: `1px solid ${themeColors["secondaryColorLighter"]}`,
  };

  const onCheckboxChange = (event, itemId, flag) => {
    if (flag) {
      // Add New Entry
      setSelections((selections) => {
        const newEntry = {
          id: itemId,
          metrics: getCommonMetricsFromAllDimensions(selections),
        };
        const newSelections = [...selections, newEntry];
        return newSelections;
      });
    } else {
      // Remove Old Entry
      setSelections((selections) =>
        selections.filter((row) => itemId !== row.id)
      );
    }
  };

  // * Define required event handlers
  const handleCheckboxChange = (event) => {
    if (!item.disabled)
      onCheckboxChange(event, item.id, variant === "remaining");
  };

  const handleAccChange = () => {
    setOpenAccordions((oldOpenAccordions) => {
      const isAccExpanded = openAccordions[item.id] === true;
      if (isAccExpanded) {
        let { [item.id]: keyToBeRemoved, ...newOpenAccordions } =
          oldOpenAccordions;
        return newOpenAccordions;
      } else {
        let newOpenAccordions = { ...oldOpenAccordions, [item.id]: true };
        return newOpenAccordions;
      }
    });
  };

  const handleDimMetricsChange = (value) => {
    const newSelections = selections.map((row) =>
      row.id === item.id ? { ...row, metrics: value } : row
    );
    const areEqual = isEqual(selections, newSelections);
    if (!areEqual) {
      setSelections(newSelections);
    }
  };

  // * Define required variables
  var labelClassName = classes.checkboxItemLabel;
  if (variant === "selected")
    labelClassName += ` ${classes.checkboxItemLabelSelected}`;
  if (variant === "remaining")
    labelClassName += ` ${classes.checkboxItemLabelRemaining}`;
  if (item.disabled) labelClassName += ` ${classes.disabledItem}`;
  const checkboxClassName = item.disabled ? classes.disabledItem : "";
  const accExpanded = openAccordions[item.id] === true;

  return (
    <Box>
      <Accordion expanded={accExpanded} className={classes.accordionRoot}>
        <AccordionSummary
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className={classes.itemBase}>
            <SigviewCheckbox
              checked={variant === "selected"}
              value="checkedG"
              inputProps={{ "aria-label": "secondary checkbox" }}
              disabled={item.disabled}
              className={checkboxClassName}
              onClick={handleCheckboxChange}
            />
            <Box
              component="label"
              title={item.name}
              className={labelClassName}
              onClick={handleCheckboxChange}
            >
              {item.name}
            </Box>
            {item.description && (
              <SigviewTooltip title={item.description} placement="bottom">
                <span>
                  <SigviewIcon
                    className={`material-icons-round`}
                    iconName="info"
                    style={{ padding: "0px 0px 0px 5px" }}
                  />
                </span>
              </SigviewTooltip>
            )}
            {variant === "selected" && (
              <SigviewTooltip
                title={
                  accExpanded
                    ? "Click To Hide Metrics"
                    : "Click To Show Metrics"
                }
                placement="bottom"
              >
                <Box>
                  <SigviewIcon
                    iconName={accExpanded ? "expand_less" : "expand_more"}
                    onClick={handleAccChange}
                    style={{
                      fontSize: "25px !important",
                      padding: "0 0 0 10px !important",
                      color: themeColors["secondaryColorLight"],
                      hoverColor: themeColors["secondaryColorLight"],
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </SigviewTooltip>
            )}
            {variant === "selected" && (
              <span {...provided.dragHandleProps}>
                <SigviewIcon
                  className="material-icons-outlined"
                  iconName="menu"
                  style={{
                    fontSize: "16px !important",
                    display: "inline-flex",
                    padding: "0px 0px 0px 0px",
                    color: themeColors["secondaryColorLight"],
                    hoverColor: themeColors["secondaryColorLight"],
                    cursor: "grab",
                  }}
                />
              </span>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {variant === "selected" && accExpanded && (
            <Box css={{ width: "100%" }}>
              <SigviewTypography
                variant="pMedium"
                style={{
                  height: "max-content",
                  color: themeColors["secondaryColor"],
                  fontWeight: 500,
                  padding: "0px 0px 5px 0px",
                }}
              >
                Select Metrics
              </SigviewTypography>
              <SigviewDndChecklist
                data={metricData}
                initialSelections={selectedMetrics}
                onChange={handleDimMetricsChange}
              />
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

export default connect(mapStateToProps)(memo(ChecklistRowDim, areEqual));

// * Import required libraries
import { useContext } from "react";

// * Import contexts
import { ThemeContext } from "../../contexts/ThemeContext";

// * import lib component
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

// * Import custom components
import LayoutBottom from "../../layouts/LayoutBottom/LayoutBottom";
import SigviewTypography from "../../components/Common/SigviewTypography";

// * Import styles
import signInImage from "../../../assets/images/loginFlowImg.svg";
import SigviewIcon from "../../components/Common/SigviewIcon";
import { useHistory } from "react-router";

// * Define style functions
const makeSigviewStyles = (...args) => {
  const [themeColors, customStyle] = args;
  const useStyles = makeStyles({
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
    },
    leftPanel: {
      width: "50%",
      height: "100%",
      display: "inline-block",
    },
    rightPanel: {
      width: "50%",
      height: "100%",
      display: "inline-block",
      background: "#045ad9",
      borderRadius: " 50px 0px 0px 50px",
    },
    errorTextBox: {
      display: "flex",
      paddingBottom: " 5px",
    },
    signInSignUpImageContainer: {
      width: "100%",
      height: "calc(100vh - 20px)",
      background: "url(" + signInImage + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "center",
      borderRadius: "50px 0px 0px 50px",
    },
    signInImageContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    sigmoidLogoContainer: {
      height: "50px",
      width: "100px",
      padding: "50px",
      cursor: "pointer",
    },
    mainSignInContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100% - 50px)",
    },
    mainSignInFormRow: {
      padding: "10px",
      width: "350px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
  });
  return useStyles;
};
function SignInOpenXLoginError(props = {}) {
  const { ssoSignInErrorMessage = "Something went wrong" } = props;
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  const useSigviewStyles = makeSigviewStyles(themeColors);
  const classes = useSigviewStyles();
  const commonChildrenProps = {
    classes,
    ssoSignInErrorMessage,
  };

  const history = useHistory();
  const SignInImage = (props = {}) => {
    const { classes } = props;
    return (
      <Box className={classes.signInSignUpImageContainer}>
        {/* <img src={signInImage} width="80%" height="80%" /> */}
      </Box>
    );
  };

  const SSOErrorMgs = (props = {}) => {
    const { classes, ssoSignInErrorMessage = "" } = props;
    return (
      <Box className={classes.mainSignInContainer}>
        <Box>
          <SigviewTypography
            variant="pLargest"
            style={{ padding: "10px 10px 30px 10px" }}
          >
            Login Failed
          </SigviewTypography>

          <Box className={classes.errorTextBox}>
            {/* <Box>
              <SigviewIcon
                className={`material-icons-round`}
                iconName="fiber_manual_record"
                style={{
                  padding: "0px 5px 0px 0px",
                }}
              />
            </Box> */}
            <Box>
              <a
                // href="https://sso.openx.com/"

                onClick={() => {
                  history.push("/openxSignIn");
                }}
                style={{
                  color: "#045ad9",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Return to SSO to logout
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <>
      <LayoutBottom>
        <Box className={classes.root}>
          <Box className={classes.leftPanel}>
            <SSOErrorMgs {...commonChildrenProps}></SSOErrorMgs>
          </Box>
          <Box className={classes.rightPanel}>
            <SignInImage {...commonChildrenProps} />
          </Box>
        </Box>
      </LayoutBottom>
    </>
  );
}
export default SignInOpenXLoginError;

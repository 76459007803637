// ! IMPORTANT NOTES
// Replica of common components
// Caution:
// 1. This is a fast fix to solve website performance
// 2. This may not adhere to our kit guidelines

// Import required libraries
import React from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import orderBy from "lodash/orderBy";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alingItems: "center",
  },
  vr: {
    height: "16px",
    width: "1px",
    margin: "0px 10px",
    backgroundColor: theme.palette.misc["secondaryColorLighter"],
  },
}));

function SigviewVROrg(props) {
  const classes = useStyles();
  const {  user } = props;
  const activeOrgId = user?.metadata?.data?.activeOrg;
  let allOrgs = user?.metadata?.data?.orgInfoList || [];
  allOrgs = orderBy(allOrgs, ["name"], ["asc"]);
  const allOrgsModified = allOrgs.map((row) => ({
    ...row,
    id: row.name,
    name: row.name,
    disabled: false,
  }));

  return (
    <>
    {(activeOrgId !== "Kayzen" ||  allOrgsModified.length > 1) && <div className={classes.root}>
      <div className={classes.vr}></div>
    </div>}
    </>
  );
}
SigviewVROrg.propTypes = {
    user: PropTypes.object,
    match: PropTypes.object,
  };
  
  const mapStateToProps = (state) => ({
    user: state.user,
  });

export default connect(mapStateToProps) (SigviewVROrg);

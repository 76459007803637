// Import required libraries
import React from "react";
import PropTypes from "prop-types";
import orderBy from "lodash.orderby";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

// Import custom components
import SigviewTextField from "../Common/SigviewTextField";
import SigviewRadioGroup from "../Common/SigviewRadioGroup";
import SigviewSingleSelect from "../Common/SigviewSingleSelect";
import SigviewButtonGroup from "../Common/SigviewButtonGroup";
import SigviewCheckboxGroup from "../Common/SigviewCheckboxGroup";
import SigviewSimpleText from "../Common/SigviewSimpleText";
import SigviewRadioDropdownCombo from "../Common/SigviewRadioDropdownCombo";
import SigviewInputTags from "../Common/SigviewInputTags";
import SigviewMultiSelectDnD from "../Common/SigviewMultiSelectDnD";
import SelectedFilters from "../GlobalFilters/SelectedFilters";
import TimeFilters from "../TimeFilters/TimeFilters";
import SigviewButton from "../Common/SigviewButton";
import SigviewSwitch from "../Common/SigviewSwitch";
import SigviewTooltip from "../Common/SigviewTooltip";
import SigviewComboBox from "../Common/SigviewComboBox";
import Loader from "../Loader/Loader";

// Import styles
import "./ReportManager.scss";

// Import config

// Import action creators and reducers
import { updateUserScreen } from "../../redux/actions";

// Import utils
import { validateEmailAddress, getSigviewUserType } from "../../utils/utils";
import { masterTrackGaEvent } from "../../services/ga";
import {
  realTimeCprFrequencyFilter,
  realTimeCprTimeRange,
  isBucketAssociatedReport,
} from "../../utils/reportUtils";

// Import data
import allTimezones from "../../../assets/data/allTimezones.json";
import triggerFrequencyArr from "../../../assets/data/triggerFrequencyArr.json";
import allWeekdays from "../../../assets/data/allWeekdays.json";
import triggerTimeArr from "../../../assets/data/triggerTimeArr.json";

const reportTypesArr = [
  { id: "no", name: "Saved", disabled: false },
  { id: "yes", name: "Scheduled", disabled: false },
  { id: "no", name: "Download", disabled: false },
];

const languageOptionsArr = [
  {
    id: "en",
    name: "English",
    disabled: false,
  },
  {
    id: "ja",
    name: "Japanese",
    disabled: false,
  },
];

const ascDescArr = [
  { id: "asc", name: "Ascending" },
  { id: "desc", name: "Descending" },
];

const sortedByTypeArr = [
  {
    id: "id_only",
    name: "Value",
    idStartsWithList: ["D", "C", "M"],
  },
  {
    id: "id_trueDelta",
    name: "Absolute Change",
    idStartsWithList: ["C", "M"],
  },
  {
    id: "id_deltaPercentage",
    name: "Percentage Change",
    idStartsWithList: ["C", "M"],
  },
];

const asteriskStyle = { color: "red" };

function ReportManagerForm(props) {
  const { t } = useTranslation();
  const {
    user,
    reportFormData,
    setReportFormDataSync,
    allSelectedMetricsDimensions,
    filteredTimeRangeScheduledArr,
    ReduxDispatcher,
    allData,
    reportFormError,
    activeReportFormData,
    activeReport,
    buttonParams,
    uiFeatureList,
    disabledAddNewReport,
    bucketList = [],
    variant = "report",
    usersData,
  } = props;

  const pathname = window.location.pathname;
  const rootPage = pathname.split("/")[1];
  //Defining Variables
  const sigviewUserType = getSigviewUserType();
  const isLanguageSelectionAvailable = sigviewUserType !== "sigview"; //hardCoded
  const isQuarterlyVisible = sigviewUserType === "sigview";
  const allTimezonesFinal = allTimezones.map((row) => ({
    ...row,
    id: row.name,
    name: row.name,
    disabled: false,
  }));

  // If view is REAL-TIME-CPR frequency shuld be only daily or weekly
  let ModifiedTriggerFrequencyArr = triggerFrequencyArr;
  if (user.reqMetadata.view === "REAL-TIME-CPR") {
    ModifiedTriggerFrequencyArr = triggerFrequencyArr.filter((el) =>
      realTimeCprFrequencyFilter.includes(el.id)
    );
  }
  // Quarterly will be visible only for openx users and not for sigview users
  if (isQuarterlyVisible) {
    ModifiedTriggerFrequencyArr = triggerFrequencyArr.filter(
      (el) => el.id !== "quarterly"
    );
  }

  //If value is not present in the list, don't show the component to avoid MUI warning
  if (
    user.reqMetadata.organization === "OpenX" &&
    user.reqMetadata.view === "REAL-TIME-CPR"
  ) {
    var realTimeCPRTimeRangeScheduledArr = filteredTimeRangeScheduledArr.filter(
      (el) => realTimeCprTimeRange.includes(el.id)
    );
    var selectedTimeRange = realTimeCPRTimeRangeScheduledArr.find(
      (row) => row.id === reportFormData.triggerTimeRangeNumber
    );
  } else {
    var selectedTimeRange = filteredTimeRangeScheduledArr.find(
      (row) => row.id === reportFormData.triggerTimeRangeNumber
    );
  }
  const timeRangeScheduledReportOnComparisonText =
    selectedTimeRange?.compareWithPresetName;
  const isComparisonAvailable =
    selectedTimeRange?.isComparisonAvailable &&
    reportFormData.reportRowCount !== "all_records"; //hard coded
  const isComparisonOn = reportFormData.isComparisonOn;
  const timeRangeScheduledReportOnComparisonTextFlag =
    timeRangeScheduledReportOnComparisonText?.length && isComparisonOn
      ? true
      : false;
  const triggerTypeCurrObject = triggerFrequencyArr.find(
    (row) => row.id === reportFormData.triggerType
  );
  const triggerTypeHelperText = triggerTypeCurrObject.helperText;
  const weeklyRadioButtonFlag = reportFormData.triggerType === "weekly"; //hard coded
  const triggerTypeHelperTextFlag = ["weekly", "daily"].includes(
    reportFormData.triggerType
  )
    ? false
    : true; //hard coded
  const orderByShowFlag = allSelectedMetricsDimensions.length ? true : false;
  const selectedReportType =
    reportFormData.scheduleFlag === "yes" ? "scheduled" : "saved";
  let filteredReportCountFileType = reportFormData.reportCountFileType
    .filter((row) => row.reportTypeList.includes(selectedReportType))
    .filter((row) =>
      row.id === "all_records" ? uiFeatureList.fullReport : row
    )
    .filter((row) =>
      row.id === "five_thousand_records" ? uiFeatureList.download : row
    );
  //Hard coded: if userType is sigview, all_records (full report) is not available
  if (sigviewUserType === "sigview" && selectedReportType === "scheduled") {
    filteredReportCountFileType = filteredReportCountFileType.filter(
      (row) => row.id !== "all_records"
    );
  }
  if (sigviewUserType === "nonSigview" && selectedReportType === "scheduled") {
    filteredReportCountFileType = filteredReportCountFileType.map((el) =>
      el.id === "fifty_thousand_records"
        ? {
            ...el,
            dropDownData: el.dropDownData.some(
              (el) => el.id === "writeToBucket"
            )
              ? [...el.dropDownData]
              : [
                  ...el.dropDownData,
                  {
                    id: "sendLink",
                    name: "Send as a link",
                    disabled: false,
                  },
                  {
                    id: "writeToBucket",
                    name: "Write to storage",
                    disabled: false,
                  },
                ],
          }
        : el
    );
  }
  const emailRecordVolTypes = ["fifty_thousand_records", "all_records"];
  const showEmailComponent =
    selectedReportType === "saved" &&
    emailRecordVolTypes.includes(reportFormData.reportRowCount);
  const sortedByTypeArrFiltered = sortedByTypeArr
    .filter((row) => {
      if (reportFormData.orderById.length)
        return row.idStartsWithList.includes(
          reportFormData.orderById.slice(0, 1)
        );
      else return true;
    })
    .filter((row) => {
      if (isComparisonOn) {
        return true;
      } else {
        if (row.id === "id_only") return true;
        else return false;
      }
    });
  const calendarDaysLimits = user.uiLimitsList.daysLimitCalendarReports;
  const timeFiltersSettings = {
    compareCalendarRowLimit: 1,
    isMultiCompareRowAllowed: false,
    isComparisonAvailable: reportFormData.reportRowCount !== "all_records",
    isChangeTypeDropdownAvailable: false,
    disabled: false,
  };
  const calendarComparisonData = [
    {
      id: "compareWith",
      name: "Compare With",
      checked: reportFormData.isComparisonOn,
    },
  ];
  //If value is not present in the list, don't show the component to avoid MUI warning
  const orderByIdValid = allSelectedMetricsDimensions.find(
    (row) => row.id === reportFormData.orderById
  );
  const orderByTypeValid = sortedByTypeArrFiltered.find(
    (row) => row.id === reportFormData.orderByType
  );
  const metricListErrorStyle = {
    visibility: reportFormError.message.metricsList.flag ? "visible" : "hidden",
    //background: "#f5f7ff",
  };
  const dimensionListErrorStyle = {
    visibility: reportFormError.message.dimensionsList.flag
      ? "visible"
      : "hidden",
  };
  const isFullReportOn = reportFormData.reportRowCount === "all_records"; //hard coded
  const atleastOneFilterSelected =
    reportFormData.dimensionFilters.length > 0 ||
    reportFormData.metricFilters.length;

  // Required for DnD component
  // Dimensions
  const dimensionIdAccessor = "_id";
  const dimensionLabelAccessor = "dimTitle";
  const selectedDimensionsIds = reportFormData.dimensionsList.map(
    (row) => row[dimensionIdAccessor]
  );
  const unselectedDimensions = allData.plotlyDimensions
    .filter((row) => !selectedDimensionsIds.includes(row[dimensionIdAccessor]))
    .filter((row) => row[dimensionLabelAccessor].toLowerCase() !== "week"); //hard coded: remove week from the list
  const dimensionsDndData = [
    // NOT ORDERDERING THE SELECTED DIMENSIONS; They will in the order they are selected
    // ...orderBy(
    //   reportFormData.dimensionsList,
    //   [dimensionLabelAccessor],
    //   ["asc"]
    // ),
    ...reportFormData.dimensionsList,
    //HARD CODED: Placing timestamp dimensions first
    ...orderBy(
      unselectedDimensions.filter(
        (row) => row.dataType.toLowerCase() === "datetime"
      ),
      [dimensionLabelAccessor],
      ["asc"]
    ),
    //HARD CODED: Placing non-timestamp dimensions later
    ...orderBy(
      unselectedDimensions.filter(
        (row) => row.dataType.toLowerCase() === "string"
      ),
      [dimensionLabelAccessor],
      ["asc"]
    ),
  ];
  // Metrics
  const metricIdAccessor = "_id";
  const metricLabelAccessor = "measureTitle";
  const selectedMetricsIds = reportFormData.metricsList.map(
    (row) => row[metricIdAccessor]
  );
  const unselectedMetrics = allData.plotlyMetrics.filter(
    (row) => !selectedMetricsIds.includes(row[metricIdAccessor])
  );
  const metricsDndData = [
    // NOT ORDERDERING THE SELECTED DIMENSIONS; They will in the order they are selected
    ...reportFormData.metricsList,
    //HARD CODED: Placing timestamp dimensions first
    ...orderBy(unselectedMetrics, [metricLabelAccessor], ["asc"]),
  ];

  let reportTypesArrFinal = [...reportTypesArr];
  let reportTypeName = undefined;
  if (activeReportFormData.reportPayload) {
    reportTypeName = `${activeReport.type}`;
    if (activeReport.type === "download") {
      reportTypesArrFinal = reportTypesArr
        .filter((row) => row.name === "Download")
        .map((row) => ({ ...row, disabled: true }));
    } else {
      reportTypesArrFinal = reportTypesArr
        .filter((row) => row.name !== "Download")
        .map((row) => ({ ...row, disabled: true }));
    }
  } else {
    reportTypesArrFinal = reportTypesArr.filter(
      (row) => row.name !== "Download"
    );
  }
  const dimensionFiltersSettings = {
    isAdvancedFilterVisible: true,
    isExcludeAllowed: true,
    disabledItemsList: [], // ["D044"] --> This needs to be a array of ids
  };

  //Defining change handlers

  const handleAddFilters = () => {
    const handleApplyFilters = (payload, filterType) => {
      switch (filterType) {
        case "dimensions":
          setReportFormDataSync(
            "dimensionFilters",
            payload.newDimensionFilters
          );
          break;
        case "metrics":
          setReportFormDataSync("metricFilters", payload.newMetricFilters);
          break;
        case "time":
          setReportFormDataSync("timeFilters", payload);
          break;
      }
    };

    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "GlobalFilters",
      action: "Open Filters",
      label: "Sidenav",
    });
    const commonGlobalFilterProps = {
      isOpen: true, //global filters dialog open close
      showTimeFilters: false,
      showMetricFilters: uiFeatureList.metricFilters,
      activeFilterType: "dimensions",
      isAdFiltersOpen: false, //advanced filters dialog open close
      timeFilters: reportFormData.timeFilters, //for initializing filters
      dimensionFilters: reportFormData.dimensionFilters, //for initializing filters
      metricFilters: reportFormData.metricFilters, //for initializing filters
      settings: dimensionFiltersSettings,
      filtersDimData: allData.plotlyDimensions,
      filtersMetricsData: allData.plotlyMetrics,
      selections: {},
    };
    //Open Global Filters
    const newGlobalFiltersProps = {
      ...commonGlobalFilterProps,
      activeDimensionFilter: {},
      handleApplyFilters, //It will take 2 parameters, payload and filterType (dimensions or metric or time); We have 2 separate actions for both
    };

    const action = updateUserScreen("globalFilters", newGlobalFiltersProps);
    ReduxDispatcher(action);
  };

  const handleDimFilterClick = (filterRow) => {
    //Open Global Filters
    const newGlobalFiltersProps = {
      isOpen: true, //global filters dialog open close
      showTimeFilters: false,
      showMetricFilters: uiFeatureList.metricFilters,
      activeFilterType: "dimensions",
      isAdFiltersOpen: false, //advanced filters dialog open close
      activeDimensionFilter: filterRow.metadata,
      timeFilters: reportFormData.timeFilters, //for initializing filters
      dimensionFilters: reportFormData.dimensionFilters, //for initializing filters
      metricFilters: reportFormData.metricFilters, //for initializing filters
      handleApplyFilters: (payload, filterType) => {
        switch (filterType) {
          case "dimensions":
            setReportFormDataSync(
              "dimensionFilters",
              payload.newDimensionFilters
            );
            break;
          case "metrics":
            setReportFormDataSync("metricFilters", payload.newMetricFilters);
            break;
          case "time":
            setReportFormDataSync("timeFilters", payload);
            break;
        }
      }, //It will take 2 parameters, payload and filterType (dimensions or metric or time); We have 2 separate actions for both
    };
    ReduxDispatcher(updateUserScreen("globalFilters", newGlobalFiltersProps));
  };

  const handleDimFilterDelete = (filterRow) => {
    const newDimFilters = reportFormData.dimensionFilters.filter(
      (filter) => filter.id !== filterRow.id
    );
    setReportFormDataSync("dimensionFilters", newDimFilters);
  };

  const handleMetricFilterClick = (metricRow) => {
    //Open Global Filters
    const newGlobalFiltersProps = {
      isOpen: true, //global filters dialog open close
      showTimeFilters: false,
      showMetricFilters: uiFeatureList.metricFilters,
      activeFilterType: "metrics",
      isAdFiltersOpen: false, //advanced filters dialog open close
      activeDimensionFilter: {},
      timeFilters: reportFormData.timeFilters, //for initializing filters
      dimensionFilters: reportFormData.dimensionFilters, //for initializing filters
      metricFilters: reportFormData.metricFilters, //for initializing filters
      handleApplyFilters: (payload, filterType) => {
        switch (filterType) {
          case "dimensions":
            setReportFormDataSync(
              "dimensionFilters",
              payload.newDimensionFilters
            );
            break;
          case "metrics":
            setReportFormDataSync("metricFilters", payload.newMetricFilters);
            break;
          case "time":
            setReportFormDataSync("timeFilters", payload);
            break;
        }
      }, //It will take 2 parameters, payload and filterType (dimensions or metric or time); We have 2 separate actions for both
    };
    ReduxDispatcher(updateUserScreen("globalFilters", newGlobalFiltersProps));
  };

  const handleMetricFilterDelete = (metricRow) => {
    const newDimFilters = reportFormData.metricFilters.filter(
      (filter) => filter.id !== metricRow.id
    );
    setReportFormDataSync("metricFilters", newDimFilters);
  };

  const handleMetricsListChange = (newValue) => {
    setReportFormDataSync("metricsList", newValue);
  };

  const handleDimensionsListChange = (newValue) => {
    setReportFormDataSync("dimensionsList", newValue);
  };

  const handleClearAllFilters = () => {
    setReportFormDataSync(undefined, {
      dimensionFilters: [],
      metricFilters: [],
    });
  };

  const validateEmail = (text) => {
    const recipientsList = reportFormData.recipientsList.map((row) => row.name);
    if (!validateEmailAddress(text))
      return { isValid: false, message: "Invalid email address" };
    if (recipientsList.includes(text))
      return { isValid: false, message: "Duplicate email address" };
    return { isValid: true, message: "" };
  };

  const renderOptionAutoCompleteMetric = (props) => {
    const { option, allSelectedIds, idAccessor, labelAccessor } = props;
    const selectedFlag = allSelectedIds.includes(option[idAccessor]);
    const metricType = option._id.startsWith("M") ? "metric" : "customMetric";
    let infoTooltipStatus = false;
    if (
      user.reqMetadata.organization === "Kayzen" &&
      user.reqMetadata.view === "Inventory_Price"
    ) {
      infoTooltipStatus =
        (option.displayFormula !== undefined &&
          option.displayFormula.startsWith("(Price Digest")) ||
        (option.displayFormula !== undefined &&
          option.displayFormula.startsWith("(price digest"));
    }
    return (
      <>
        <i
          className={`material-icons auto-complete-option-icon ${
            selectedFlag ? "" : "invisible"
          } `}
        >
          check_circle
        </i>
        <span
          className="quantity-selector-option-title"
          title={option[labelAccessor]}
        >
          {option[labelAccessor]}
        </span>
        {!infoTooltipStatus && (
          <SigviewTooltip title={option.displayFormula} top="-12px">
            <i className="material-icons-round info-icon">info</i>
          </SigviewTooltip>
        )}
      </>
    );
  };

  const renderOptionAutoCompleteDimension = (props) => {
    const { option, allSelectedIds, idAccessor, labelAccessor } = props;
    const selectedFlag = allSelectedIds.includes(option[idAccessor]);
    let infoTooltipStatus = false;
    if (
      user.reqMetadata.organization === "Kayzen" &&
      user.reqMetadata.view === "Inventory_Price"
    ) {
      infoTooltipStatus =
        (option.displayFormula !== undefined &&
          option.displayFormula.startsWith("(Price Digest")) ||
        (option.displayFormula !== undefined &&
          option.displayFormula.startsWith("(price digest"));
    }
    return (
      <>
        <i
          className={`material-icons auto-complete-option-icon ${
            selectedFlag ? "" : "invisible"
          } `}
        >
          check_circle
        </i>
        <span
          className="quantity-selector-option-title"
          title={option[labelAccessor]}
        >
          {option[labelAccessor]}
        </span>
        {option.description && !infoTooltipStatus && (
          <SigviewTooltip title={option.description} top="-12px">
            <i className="material-icons-round info-icon">info</i>
          </SigviewTooltip>
        )}
      </>
    );
  };

  const allowTimeRangeForRealTimeCPR =
    selectedTimeRange !== undefined &&
    user.reqMetadata.organization === "OpenX" &&
    user.reqMetadata.view === "REAL-TIME-CPR";
  const allowAllTimeRangeForAllView =
    selectedTimeRange !== undefined &&
    user.reqMetadata.view !== "REAL-TIME-CPR";

  return (
    <>
      {!reportTypeName && (
        <div className="report-manager-form-row">
          <div className="report-manager-form-item-container">
            <div className="label-container">
              <p className="label">{t("Report Type:")}</p>
            </div>
            <div className="input-item-container-RadioBtn">
              {reportTypeName ? (
                <p className="simple-text">{reportTypeName}</p>
              ) : (
                <SigviewRadioGroup
                  data={reportTypesArrFinal}
                  value={reportFormData.scheduleFlag}
                  onChange={(newValue) => {
                    setReportFormDataSync("scheduleFlag", newValue);
                  }}
                  style={{ rootFlexDirection: "row" }}
                  iconFlag={true}
                  iconProps={{ className: "material-icons", iconName: "add" }}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <div className="report-manager-form-row">
        <div
          className={`report-manager-form-item-container report-name ${
            reportTypeName ? "clone-visible" : "full"
          }`}
        >
          <div className="label-container" style={{ paddingTop: "4px" }}>
            <p className="label">{t("Name:")}</p>
          </div>
          <div
            className={`input-item-container border-less report-name-container ${
              reportTypeName ? "clone-visible" : "full"
            }`}
          >
            <SigviewTextField
              value={reportFormData.reportName}
              border="1px solid #edf1ff"
              backgroundColor="#fff"
              onChange={(newValue) => {
                if (newValue !== " ") {
                  // newValue = newValue.trim();
                  setReportFormDataSync("reportName", newValue);
                }
              }}
              placeholder="Enter String"
              // error={reportFormError.message.reportName.flag}
              // helperText={reportFormError.message.reportName.message}
              minWidth="150px"
              maxWidth="100%"
              height="28pxpx"
              margin={{ right: "5px" }}
              fontSize="12px"
              readOnly={false}
            />
          </div>
        </div>

        {reportTypeName && buttonParams.showAddToSavedReportsButton && (
          <div className="report-manager-form-item-container label-less">
            <div
              className={`input-item-container border-less label-less report-clone-menu-container`}
              id="reportFormCloneButton"
            >
              <SigviewTooltip
                title={
                  disabledAddNewReport
                    ? "Max Limit Reached For Saved Reports"
                    : ""
                }
                placement="bottom"
              >
                <Box>
                  <SigviewButton
                    variant="containedBlueLighter"
                    onClick={buttonParams.handleAddToSavedReport}
                    title="Add to Saved Reports"
                    customClassName={`${rootPage}AddToSavedReports-GA`}
                    disabled={
                      reportFormError.flag ||
                      disabledAddNewReport ||
                      isBucketAssociatedReport(reportFormData)
                    }
                    iconFlag={true}
                    iconProps={{ className: "material-icons", iconName: "add" }}
                    wrapperHeight="26px"
                    style={{
                      width: "100%",
                      color: "#267ee6",
                      bgColor: "#fff",
                      buttonHeight: "28px",
                    }}
                  />
                </Box>
              </SigviewTooltip>
            </div>
          </div>
        )}
        {reportTypeName && buttonParams.showAddToScheduledReportsButton && (
          <div className="report-manager-form-item-container label-less">
            <div
              className={`input-item-container border-less label-less report-clone-menu-container`}
              id="reportFormCloneButton"
            >
              <SigviewTooltip
                title={
                  disabledAddNewReport
                    ? "Max Limit Reached For Scheduled Reports"
                    : ""
                }
                placement="bottom"
              >
                <Box>
                  <SigviewButton
                    variant="containedBlueLighter"
                    onClick={buttonParams.handleAddToScheduledReports}
                    title="Add to Scheduled Reports"
                    customClassName={`${rootPage}AddToScheduledReports-GA`}
                    disabled={reportFormError.flag || disabledAddNewReport}
                    iconFlag={true}
                    iconProps={{ className: "material-icons", iconName: "add" }}
                    style={{
                      width: "100%",
                      color: "#267ee6",
                      bgColor: "#fff",
                    }}
                  />
                </Box>
              </SigviewTooltip>
            </div>
          </div>
        )}
      </div>
      {reportFormError.message.reportName.flag && (
        <div
          style={{
            color: "red",
            fontSize: "10px",
            position: "relative",
            top: "-7px",
            paddingLeft: "100px",
          }}
        >
          {reportFormError.message.reportName.message}
        </div>
      )}
      {reportFormData.scheduleFlag === "yes" && (
        <div className="report-manager-form-row">
          <div className="report-manager-form-item-container">
            <div className="label-container">
              <p className="label">{t("Report Status:")}</p>
            </div>
            <div
              className="input-item-container transparent"
              style={{ backgroundColor: "#f4f7ff" }}
            >
              <SigviewSwitch
                checked={reportFormData.reportStatus === "active"}
                customClassName="scheduleReportON-OFF-GA"
                onChange={(event, checked) => {
                  setReportFormDataSync(
                    "reportStatus",
                    checked ? "active" : "inactive"
                  );
                }}
              />
            </div>
          </div>
          {reportFormData.scheduleFlag === "yes" && (
            <>
              <div className="report-manager-form-item-container">
                <div className="label-container" style={{ width: "65px" }}>
                  <p className="label">{t("Frequency:")}</p>
                </div>
                <div className="input-item-container border-less">
                  <SigviewSingleSelect
                    value={reportFormData.triggerType}
                    data={ModifiedTriggerFrequencyArr}
                    onChange={(newValue) => {
                      setReportFormDataSync("triggerType", newValue);
                    }}
                    minWidth="140px"
                    height="28px"
                    fontSize="11px"
                    border="1px solid #edf1ff"
                    // margin={{ right: "5px" }}
                    disabled={false}
                  />
                </div>
              </div>
              {weeklyRadioButtonFlag && (
                <div className="report-manager-form-item-container label-less">
                  <div className="input-item-container border-less label-less">
                    <SigviewButtonGroup
                      value={reportFormData.triggerDay}
                      data={allWeekdays}
                      onChange={(newValue) => {
                        setReportFormDataSync("triggerDay", newValue);
                      }}
                      style={{
                        border: "1px solid #edf1ff",
                        height: "27px",
                      }}
                      disabled={false}
                    />
                    {/* <SigviewSingleSelect
                      value={reportFormData.triggerDay}
                      data={allWeekdays}
                      onChange={(newValue) => {
                        setReportFormDataSync("triggerDay", newValue);
                      }}
                      minWidth="100px"
                    /> */}
                  </div>
                </div>
              )}
              {triggerTypeHelperTextFlag && (
                <div className="report-manager-form-item-container label-less">
                  <div className="input-item-container label-less border-less">
                    <SigviewSimpleText
                      value={triggerTypeHelperText}
                      style={{
                        maxWidth: "max-content",
                        fontSize: "11px",
                        height: "28px",
                        border: "1px solid #edf1ff",
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="report-manager-form-item-container">
                <div className="label-container" style={{ width: "110pxpx" }}>
                  <p className="label">{t("Trigger Timezone:")}</p>
                </div>
                <div className="input-item-container border-less">
                  <SigviewSingleSelect
                    value={reportFormData.triggerTimeZone}
                    data={allTimezonesFinal}
                    onChange={(newValue) => {
                      setReportFormDataSync("triggerTimeZone", newValue);
                    }}
                    minWidth="80px"
                    height="28px"
                    fontSize="11px"
                    border="1px solid #edf1ff"
                    // margin={{ right: "5px" }}
                    theme={user.theme}
                    themeColors={user.themeColors}
                    disabled={false}
                  />
                </div>
              </div>
              <div className="report-manager-form-item-container">
                <div className="label-container" style={{ width: "75px" }}>
                  <p className="label">{t("Trigger Time:")}</p>
                </div>
                <div className="input-item-container border-less">
                  <SigviewSingleSelect
                    value={reportFormData.triggerTime}
                    data={triggerTimeArr}
                    onChange={(newValue) => {
                      setReportFormDataSync("triggerTime", newValue);
                    }}
                    minWidth="80px"
                    height="28px"
                    border="1px solid #edf1ff"
                    fontSize="11px"
                    theme={user.theme}
                    themeColors={user.themeColors}
                    disabled={false}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {reportFormData.scheduleFlag === "no" && (
        <div className="report-manager-form-row">
          <div
            className="report-manager-form-item-container full"
            id="reportFormTimeRange"
          >
            <div className="label-container">
              <p className="label">{t("Time Range:")}</p>
            </div>
            <div className="input-item-container">
              <TimeFilters
                user={user}
                allData={allData}
                initialTimeFilters={reportFormData.timeFilters}
                onChange={(newValue) => {
                  setReportFormDataSync("timeFilters", newValue);
                }}
                googleAnalytics={{ category: "ReportManager" }}
                calendarDaysLimits={calendarDaysLimits}
                settings={timeFiltersSettings}
              />
            </div>
          </div>
        </div>
      )}
      {reportFormData.scheduleFlag === "yes" && (
        <div className="report-manager-form-row" id="reportFormTimeRange">
          <div className="report-manager-form-item-container">
            <div className="label-container">
              <p className="label">{t("Time Range:")}</p>
            </div>
            <div className="input-item-container border-less">
              {allowTimeRangeForRealTimeCPR && (
                <SigviewSingleSelect
                  value={reportFormData.triggerTimeRangeNumber}
                  data={realTimeCPRTimeRangeScheduledArr}
                  onChange={(newValue) => {
                    setReportFormDataSync("triggerTimeRangeNumber", newValue);
                  }}
                  minWidth="80px"
                  height="28px"
                  border="1px solid #edf1ff"
                  fontSize="11px"
                  // margin={{ right: "5px" }}
                  theme={user.theme}
                  themeColors={user.themeColors}
                />
              )}
              {allowAllTimeRangeForAllView && (
                <SigviewSingleSelect
                  value={reportFormData.triggerTimeRangeNumber}
                  data={filteredTimeRangeScheduledArr}
                  onChange={(newValue) => {
                    setReportFormDataSync("triggerTimeRangeNumber", newValue);
                  }}
                  minWidth="80px"
                  height="28px"
                  border="1px solid #edf1ff"
                  fontSize="11px"
                  // margin={{ right: "5px" }}
                  theme={user.theme}
                  themeColors={user.themeColors}
                  disabled={false}
                />
              )}
            </div>
          </div>
          <div className="report-manager-form-item-container label-less">
            <div className="input-item-container border-less label-less">
              <SigviewSingleSelect
                value={reportFormData.triggerTimeRangeTimeZone}
                data={allTimezonesFinal}
                onChange={(newValue) => {
                  setReportFormDataSync("triggerTimeRangeTimeZone", newValue);
                }}
                minWidth="80px"
                fontSize="11px"
                border="1px solid #edf1ff"
                height="28px"
                // margin={{ right: "5px" }}
                theme={user.theme}
                themeColors={user.themeColors}
                disabled={false}
              />
            </div>
          </div>
          {isComparisonAvailable && (
            <div className="report-manager-form-item-container label-less">
              <div
                className="input-item-container label-less border-less transparent"
                style={{
                  padding: "0px 5px",
                  border: "1px solid #edf1ff !important",
                }}
              >
                <SigviewCheckboxGroup
                  data={calendarComparisonData}
                  customID="ReportCompare-GA"
                  onChange={(event, id, checked) => {
                    setReportFormDataSync("isComparisonOn", checked);
                  }}
                  // style={{ height: "24px" }}
                  disabled={false}
                />
              </div>
            </div>
          )}
          {isComparisonAvailable &&
            timeRangeScheduledReportOnComparisonTextFlag && (
              <div className="report-manager-form-item-container label-less">
                <div className="input-item-container label-less border-less">
                  <SigviewSimpleText
                    value={timeRangeScheduledReportOnComparisonText}
                    style={{
                      maxWidth: "max-content",
                      fontSize: "11px",
                      height: "28px",
                      border: "1px solid #edf1ff",
                    }}
                  />
                </div>
              </div>
            )}
        </div>
      )}
      <div className="report-manager-form-row">
        {((variant || atleastOneFilterSelected) && (
          <div
            className="report-manager-form-item-container"
            id="reportFormFilters"
          >
            <div className="label-container">
              <p className="label">{t("Filters:")}</p>
            </div>
            {atleastOneFilterSelected ? (
              <div className="input-item-container transparent filterBox">
                <div
                  className={`selected-filter-item-container child-container selectedFilter ReportOpenFilterSelectedDimension-GA`}
                  id="reportManagerInstance"
                  title="Add filters"
                >
                  <i
                    className="material-icons add-filters-icon selected-filter-item-title child-element add-Reportfilters-icon"
                    onClick={handleAddFilters}
                  >
                    add
                  </i>
                </div>
                <SelectedFilters
                  isReadOnly={false}
                  onClick={(row, type) => {
                    switch (type) {
                      case "dimension":
                        handleDimFilterClick(row);
                        break;
                      case "metric":
                        handleMetricFilterClick(row);
                        break;
                    }
                  }}
                  onDelete={(row, type) => {
                    switch (type) {
                      case "dimension":
                        handleDimFilterDelete(row);
                        break;
                      case "metric":
                        handleMetricFilterDelete(row);
                        break;
                    }
                  }}
                  showClearAllButton={true}
                  onClearAll={handleClearAllFilters}
                  // rowHeader="Applied Filters:"
                  selectedDimensionFilters={reportFormData.dimensionFilters}
                  selectedMetricFilters={reportFormData.metricFilters}
                  style={{ maxHeight: "62px", margin: "3px" }}
                  divId="filtersMenuBarRow"
                  allData={allData}
                  childrenPlacement="start"
                ></SelectedFilters>
              </div>
            ) : (
              <div className="input-item-container-globalFilter ${rootPage}ReportAddFilter-GA">
                <p
                  className={`info-title add-filters-text   ${rootPage}ReportAddFilter-GA`}
                  onClick={handleAddFilters}
                >
                  {t("Click to add filters")}
                </p>
              </div>
            )}
          </div>
        )) || <></>}
      </div>
      {/* <div
        className="report-manager-form-row two-equal"
        id="reportFormMetricDimSelector"
      >
        <div
          className="report-manager-form-item-container"
          style={{ width: "100%" }}
        >
          <div className="label-container" style={{ width: "100%" }}>
            <p className="label">{t("Dimensions & Metrics:")}</p>
          </div>
        </div>
      </div> */}
      <div
        className="report-manager-form-row two-equal"
        style={{ marginBottom: "10px !important" }}
        id="reportFormMetricDimSelector"
      >
        <div className="report-manager-form-item-containerBigBox">
          <div className="input-item-container transparent metrics-dimensions-selector-container">
            <div className="metrics-dimensions-selector-row-container">
              <SigviewMultiSelectDnD
                data={dimensionsDndData}
                value={reportFormData.dimensionsList}
                onChange={handleDimensionsListChange}
                title={t("Add Dimensions")}
                idAccessor={dimensionIdAccessor}
                labelAccessor={dimensionLabelAccessor}
                variant="vertical"
                googleAnalytics={{ category: "ReportManager" }}
                user={user}
                renderOptionAutoComplete={renderOptionAutoCompleteDimension}
                disabled={false}
                bgColor="#eaf3ff"
                borderColor="none"
                customPadding="7px 6px"
                customClassName={`${rootPage}ReportAddDimension-GA`}
              />
              <div className="error-message" style={{ height: "10px" }}>
                <p style={dimensionListErrorStyle}>
                  {t(reportFormError.message.dimensionsList.message)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="report-manager-form-item-containerBigBox">
          <div
            className="input-item-container transparent label-less metrics-dimensions-selector-container"
            id="reportFormMetricDimSelector"
          >
            <div className="metrics-dimensions-selector-row-container">
              <SigviewMultiSelectDnD
                data={metricsDndData}
                value={reportFormData.metricsList}
                onChange={handleMetricsListChange}
                title={t("Add Metrics")}
                idAccessor={metricIdAccessor}
                labelAccessor={metricLabelAccessor}
                variant="vertical"
                googleAnalytics={{ category: "ReportManager" }}
                user={user}
                renderOptionAutoComplete={renderOptionAutoCompleteMetric}
                disabled={false}
                bgColor="#eaf3ff"
                borderColor="none"
                customPadding="7px 6px"
                customClassName={`${rootPage}ReportAddMetric-GA`}
              />
              <div className="error-message" style={{ height: "10px" }}>
                <p style={metricListErrorStyle}>
                  {t(reportFormError.message.metricsList.message)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isFullReportOn && (
        <div className="report-manager-form-row">
          <div
            className="report-manager-form-item-container"
            id="reportFormOrderBy"
          >
            <div className="label-container">
              <p className="label">{t("Data Sorted By:")}</p>
            </div>
            {!orderByShowFlag && (
              <div
                className="input-item-container transparent"
                style={{
                  padding: "5px",
                  background: "#f5f7ff",
                  border: "none",
                }}
              >
                <p className="info-title" style={{ paddingBottom: "2px" }}>
                  {t("Please select an attribute")}
                </p>
              </div>
            )}
            {orderByShowFlag && (
              <div className="input-item-container border-less">
                {orderByIdValid && (
                  <SigviewSingleSelect
                    value={reportFormData.orderById}
                    data={allSelectedMetricsDimensions}
                    onChange={(newValue) => {
                      setReportFormDataSync("orderById", newValue);
                    }}
                    border="1px solid #edf1ff"
                    minWidth="80px"
                    height="28px"
                    fontSize="11px"
                    disabled={false}
                  />
                )}
              </div>
            )}
          </div>
          {orderByShowFlag &&
            orderByTypeValid &&
            sortedByTypeArrFiltered.length > 1 && (
              <div className="report-manager-form-item-container label-less">
                <div className="input-item-container label-less border-less">
                  <SigviewSingleSelect
                    value={reportFormData.orderByType}
                    data={sortedByTypeArrFiltered}
                    onChange={(newValue) => {
                      setReportFormDataSync("orderByType", newValue);
                    }}
                    minWidth="80px"
                    border="1px solid #edf1ff"
                    height="28px"
                    disabled={false}
                  />
                </div>
              </div>
            )}
          {orderByShowFlag && (
            <div className="report-manager-form-item-container label-less">
              <div className="input-item-container label-less transparent">
                <SigviewRadioGroup
                  data={ascDescArr}
                  value={reportFormData.orderBy}
                  onChange={(newValue) => {
                    setReportFormDataSync("orderBy", newValue);
                  }}
                  style={{ rootFlexDirection: "row" }}
                  disabled={false}
                />
              </div>
            </div>
          )}
          {isLanguageSelectionAvailable && (
            <div
              className="report-manager-form-item-container"
              id="reportFormLanguagePreference"
            >
              <div className="label-container" style={{ width: "120px" }}>
                <p className="label">{t("Preferred Language:")}</p>
              </div>
              <div className="input-item-container border-less">
                <SigviewSingleSelect
                  value={reportFormData.preferredLanguage}
                  data={languageOptionsArr}
                  onChange={(newValue) => {
                    setReportFormDataSync("preferredLanguage", newValue);
                  }}
                  minWidth="80px"
                  height="28px"
                  border="1px solid #edf1ff"
                  disabled={false}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="report-manager-form-row">
        <div
          className="report-manager-form-item-container"
          id="reportFormRecordVolume"
          style={{ padding: "5px 0px" }}
        >
          <div className="label-container">
            <p className="label">{t("Records Volume:")} </p>
          </div>
          <div className="input-item-container transparent">
            <SigviewRadioDropdownCombo
              data={filteredReportCountFileType}
              selectedRadio={reportFormData.reportRowCount}
              onRadioChange={(newValue) => {
                setReportFormDataSync("reportRowCount", newValue);
              }}
              onDropdownChange={(newValue) => {
                setReportFormDataSync("reportCountFileType", newValue);
              }}
              secondDropdownData={bucketList}
              disabled={false}
            />
          </div>
        </div>
      </div>
      {(showEmailComponent || reportFormData.scheduleFlag === "yes") && (
        <div className="report-manager-form-row">
          {variant === "adminReport" && (
            <div className="report-manager-form-item-container">
              <div className="label-container">
                <p className="label">
                  {t("Owner's Email:")}{" "}
                  <span style={{ ...asteriskStyle }}>*</span>
                </p>
              </div>
              <div className="input-item-container transparent">
                {usersData.status === "success" && (
                  <SigviewComboBox
                    data={usersData.result.map((el) => el.email)}
                    value={reportFormData.recipientsList[0].id}
                    onChange={(e, newValue) => {
                      let oldRecipientList = reportFormData.recipientsList;
                      oldRecipientList.shift();

                      oldRecipientList = oldRecipientList.filter(
                        (el) => el.id !== newValue
                      );
                      let modifiedRecipientList = [
                        { id: newValue, name: newValue },
                        ...oldRecipientList,
                      ];

                      setReportFormDataSync(
                        "recipientsList",
                        modifiedRecipientList
                      );
                    }}
                  />
                )}
                {usersData.status === "loading" && <Loader />}
              </div>
            </div>
          )}
          <div className="report-manager-form-item-container">
            <div className="label-container">
              <p className="label">{t("Email Recipients:")}</p>
            </div>
            <div className="input-item-container transparent">
              <SigviewInputTags
                data={reportFormData.recipientsList}
                border="none"
                inputValidationFunction={validateEmail}
                onChange={(newValue) => {
                  setReportFormDataSync("recipientsList", newValue);
                }}
                variant={variant}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

ReportManagerForm.propTypes = {
  user: PropTypes.object,
};

export default ReportManagerForm;

// * Import required libraies
import React, { useContext, useMemo } from "react";
import { connect, useSelector } from "react-redux";

// * Import lib components
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

// * Import custom components
// Common Kit
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
// WsDimensionTableItem Specific
import WsDimTableUI from "../../../components/VizTypes/WsDimTable/WsDimTableUI";
import WsDimTableData from "../../../components/VizTypes/WsDimTable/WsDimTableData";
import WsDimHeader from "../../../components/VizTypes/WsDimTable/WsDimHeader";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * Import redux utils
// Selectors
import {
  selectDimTableSelections,
  selectDimTableData,
  selectDimTableFilterDetails,
} from "../../../redux/selectors/standaloneWsSelectors";
// Actions
import { reloadWsDimensionTableData } from "../../../redux/actions";

// * Define required functions
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflow: "hidden",
    backgroundColor: "white",
    borderRadius: "5px",
    border: "1px solid transparent",
    "&:hover": {
      //border: `1px solid ${theme.palette.misc["secondaryColorLighter"]}`,
      boxShadow: "0px 0px 7px 0px #d7d9e2",
      "& $dimTableMenuOptions": { display: "flex" },
    },
  },
  dimTableContainer: {
    height: "85%",
    backgroundColor: "white !important",
  },
  headerContainer: {
    width: "100%",
    height: "30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    borderBottom: "1px solid #F5F5F5 !important",
    color: theme.palette.misc["secondaryColor"],
    cursor: "move",
  },
  leftHeader: {
    width: "calc(100% - 100px)",
    padding: "7px 10px 7px 3px",
    display: "flex",
    fontFamily: "Fira Sans",
    fontWeight: "600",
    fontSize: "13px !important",
    alignItems: "center",
    flexGrow: 1,
    flexBasis: 0,
  },
  headerCustomIcon: {
    cursor: "pointer",
    padding: "0px 5px",
    color: `${theme.palette.misc["secondaryColorLight"]} !important`,
    "&:hover": {
      color: `${theme.palette.misc["primaryColor"]} !important`,
    },
  },
  leftFilterIcon: {
    height: "16px",
    width: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dimTableMenuOptions: {
    // width: "100px",
    display: "none",
    justifyContent: "flex-end",
    flexShrink: 0,
  },
}));

function WsDimensionTableItem(props) {
  // * Destructure props
  const {
    // REDUX PROPS
    dispatch: ReduxDispatcher,
    // PARENT PROPS
    wsForm,
    id,
    user,
  } = props;

  // * Define required hooks
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];

  // * Destructure REDUX props
  const dimensionTableDate = useSelector((state) =>
    selectDimTableData(state, id)
  );
  const selections = useSelector((state) =>
    selectDimTableSelections(state, id)
  );
  const dimTableFilterDetails = useSelector((state) =>
    selectDimTableFilterDetails(state, id, themeColors)
  );

  // * Define required static variables
  const classes = useStyles();

  // * Define ONLY ABSOLUTE NECEASSRY MEMOIZED STATES
  const childrenProps = {
    id: id,
    data: dimensionTableDate,
    selections,
    filterAppliedOnDimTable: dimTableFilterDetails.filterAppliedOnDimTable,
    filterAppliedOnDimTableType:
      dimTableFilterDetails.filterAppliedOnDimTableType,
    themeColors,
  };
  const memoizedStringedChildrenProps = useMemo(
    () => JSON.stringify(childrenProps),
    [
      dimensionTableDate,
      selections,
      dimTableFilterDetails.filterAppliedOnDimTable,
      dimTableFilterDetails.filterAppliedOnDimTableType,
    ]
  );

  // * Define required event handlers
  const handleWsDimensionTableDataReload = (event) => {
    const payload = { dimId: id };
    const action = reloadWsDimensionTableData(payload);
    ReduxDispatcher(action);
  };

  // ! This is to check when create workspace flow
  const createCrudType =
    user.screen.activeWorkspace.wsCategory !== "shared" &&
    user.screen.activeWorkspace.crudType === "create";

  return (
    <Box className={classes.root}>
      <WsDimHeader
        id={id}
        classes={classes}
        dimensionTableDate={dimensionTableDate}
      />

      {createCrudType ? (
        <Box
          className={classes.dimTableContainer}
          style={{
            padding: dimensionTableDate.status === "error" ? "0px 10px" : "",
          }}
        >
          {/* DATA */}
          {/* <WsDimTableData id={id} /> */}
          {/* UI */}
          {dimensionTableDate.status === "loading" && (
            <Box
              css={{
                height: "100%",
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {user?.reqMetadata?.userPreferredLanguage === "ja" ? (
                <img
                  height="70%"
                  width="60%"
                  src="https://storage.googleapis.com/sigview-icons/illustrations/DimensionTable-JP.svg"
                ></img>
              ) : (
                <img
                  height="70%"
                  width="60%"
                  src="https://storage.googleapis.com/sigview-icons/illustrations/DimensionTable.svg"
                ></img>
              )}
            </Box>
          )}
        </Box>
      ) : (
        <Box
          className={classes.dimTableContainer}
          style={{
            padding: dimensionTableDate.status === "error" ? "0px 10px" : "",
          }}
        >
          {/* DATA */}
          <WsDimTableData id={id} />

          {/* UI */}
          {dimensionTableDate.status === "loading" && <Loader />}
          {dimensionTableDate.status === "error" && (
            <ErrorHandler
              message={dimensionTableDate.message}
              reloadFlag={true}
              onReload={handleWsDimensionTableDataReload}
              layout="inGrid"
            />
          )}
          {dimensionTableDate.status === "success" && (
            <>
              <WsDimTableUI
                wsForm={wsForm}
                memoizedStringedChildrenProps={memoizedStringedChildrenProps}
              />
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(WsDimensionTableItem);

// * Import required libraries
import { useState, useEffect, useReducer, useContext, useRef } from "react";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";

// * Import contexts
import { ThemeContext } from "../../../contexts/ThemeContext";

// * import lib component
import { Box } from "@material-ui/core";

// * Import custom components
import SigviewTextField from "../../../components/Common/SigviewTextField";
import SigviewTypography from "../../../components/Common/SigviewTypography";
import SigviewMultiSelectDnD from "../../../components/Common/SigviewMultiSelectDnD";
import SigviewButton from "../../../components/Common/SigviewButton";
import SigviewSingleSelect from "../../../components/Common/SigviewSingleSelect";
import Loader from "../../../components/Loader/Loader";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import SigviewProgressBar from "../../../components/Common/SigviewProgressBar";
import SigviewTextFieldAsync from "../../../components/Common/SigviewTextFieldAsync";
import SigviewHoverPopup from "../../../components/Common/SigviewHoverPopup";
import SigviewIcon from "../../../components/Common/SigviewIcon";

// * Import utils, config & static data
import {
  defaultMetricState as defaultSelections,
  unwrapperGroupDataDnd,
  isSelectionsInvalid,
  wrapperMetricForm,
  wrapperGroup,
  validateAttributeName,
  validateBackendName,
  validateAttributeDescription,
} from "../../../utils/attributeUtils";
import { config } from "../../../config/config";

// * Import redux utils
import useReducerLogger from "../../../utils/useReducerLogger";
import {
  updateAdminForm,
  replaceAdminForm,
  updateUserScreen,
  replaceAttributeMetricForm,
  updateAttributeMetricForm,
  updateData,
  updateAttributeMetricFormWholeKey,
} from "../../../redux/actions";
import attributeMetricReducer from "../../../redux/reducers/attributeMetric";

//  * Import APIs
import {
  readAttributeGroupsByOrgViewId,
  createAttribute,
  readAdminByOrg,
  updateAttributeGroup,
  updateAttribute,
  nameCheckAttribute,
} from "../../../services/api";
import DimensionMetricViewDetails from "./DimensionMetricViewDetails";

// TODO Same code changes as DimensionForm.js

function MetricForm(props = {}) {
  const { t } = useTranslation();
  const { state: themeState } = useContext(ThemeContext);
  const themeColors = themeState.themes[themeState.activeTheme];
  //  * Destructure props
  const {
    initialSelections = defaultSelections,
    groupsData = [],
    viewsData,
    ReduxDispatcher,
    setShowForm = () => {},
    availableGroupData = [],
    activeAttributeType,
  } = props;

  // * Define required states
  const [selections, dispatch] = useReducer(
    useReducerLogger(attributeMetricReducer),
    initialSelections
  );

  const [groupData, setGroupData] = useState({
    result: [],
    status: "loading",
    messsage: "",
  });

  const originalSelections = useRef({});

  const [name, setName] = useState({
    status: "success",
    value: selections.name.value,
    originalValue: selections.name.value,
    message: "",
  });

  const [description, setDescription] = useState({
    status: "success",
    value: selections.actualPayload.value.description,
    originalValue: selections.actualPayload.value.description,
    message: "",
  });

  const [backendName, setBackendName] = useState({
    status: "success",
    value: selections.backendName.value,
    originalValue: selections.backendName.value,
    message: "",
  });

  // * Define required side effects

  useEffect(() => {
    const payload = { ...initialSelections };
    const action = replaceAttributeMetricForm(payload);

    setName((prevState) => ({
      ...prevState,
      value: initialSelections.name.value,
      message: "",
    }));

    setBackendName((prevState) => ({
      ...prevState,
      value: initialSelections.backendName.value,
      message: "",
    }));

    setDescription((prevState) => ({
      ...prevState,
      value: initialSelections.actualPayload.value.description,
      message: "",
    }));

    originalSelections.current = { ...initialSelections };

    dispatch(action); // to update selections based on new clicked attribute
  }, [initialSelections.id.value]);

  // Fetch  groupData and set groupData
  useEffect(() => {
    if (selections.crudType === "update") {
      setGroupData({
        result: [],
        status: "loading",
        messsage: "",
      });
      // Make fetch call using axios
      const readAllGroupsViews = readAttributeGroupsByOrgViewId({
        view: selections.activeView.value,
        id: selections.id.value,
      });

      readAllGroupsViews
        .then((data) => {
          setGroupData({
            result: data?.result?.data,
            status: "success",
            messsage: "",
          });
        })
        .catch((json) => {
          setGroupData({
            result: [],
            status: "error",
            messsage: "API failed",
          });
        });
    }
  }, [selections.id.value]);

  useEffect(() => {
    if (groupData.status === "success") {
      const payload = {
        key: "groups",
        value: unwrapperGroupDataDnd(groupData.result),
      };

      originalSelections.current = {
        ...originalSelections.current,
        groups: {
          message: "",
          value: payload.value,
          status: "valid",
        },
      };

      const action = updateAttributeMetricForm(payload);
      dispatch(action);
    }
  }, [groupData]);

  // * Define requried event handlers

  const handleNameChange = (event, value) => {
    setName((prevState) => ({
      ...prevState,
      value: value,
      message: "",
    }));
  };

  const handleDescriptionChange = (event, value) => {
    setDescription((prevState) => ({
      ...prevState,
      value: value,
      message: "",
    }));
  };
  // const handleNameChange = (value) => {
  //   const payload = {
  //     key: "name",
  //     value,
  //   };
  //   const action = updateAttributeMetricForm(payload);
  //   dispatch(action);
  // };

  const handleBackendNameChange = (event, value) => {
    setBackendName((prevState) => ({
      ...prevState,
      value: value,
      message: "",
    }));
  };

  // const handleBackendNameChange = (value) => {
  //   const payload = {
  //     key: "backendName",
  //     value,
  //   };
  //   const action = updateAttributeMetricForm(payload);
  //   dispatch(action);
  // };
  const handleGroupsChange = (value) => {
    const payload = {
      key: "groups",
      value,
    };
    const action = updateAttributeMetricForm(payload);
    dispatch(action);
  };
  const handleDataTypeChange = (value) => {
    if (value === "String") {
      let payload = {
        key: "dataUnitType",
        value,
      };
      let action = updateAttributeMetricForm(payload);
      dispatch(action);

      payload = {
        key: "dataUnitSymbol",
        value,
      };

      action = updateAttributeMetricForm(payload);
      dispatch(action);
    }
    if (value === "percent") {
      let payload = {
        key: "dataUnitType",
        value,
      };
      let action = updateAttributeMetricForm(payload);
      dispatch(action);

      payload = {
        key: "dataUnitSymbol",
        value: config.hardCoded.attributes.metricDataSymbol[value][0].id,
      };

      action = updateAttributeMetricForm(payload);
      dispatch(action);
    }
    if (value === "currency") {
      let payload = {
        key: "dataUnitType",
        value,
      };
      let action = updateAttributeMetricForm(payload);
      dispatch(action);

      payload = {
        key: "dataUnitSymbol",
        value: config.hardCoded.attributes.metricDataSymbol[value][0].id,
      };

      action = updateAttributeMetricForm(payload);
      dispatch(action);
    }
  };
  const handleDataSymbolChange = (value) => {
    const payload = {
      key: "dataUnitSymbol",
      value,
    };
    const action = updateAttributeMetricForm(payload);
    dispatch(action);
  };
  const handleMeasureTypeChange = (value) => {
    const payload = {
      key: "measureType",
      value,
    };
    const action = updateAttributeMetricForm(payload);
    dispatch(action);
  };

  const handleSave = () => {
    ReduxDispatcher(updateUserScreen("isDashboardLoading", true));
    // TODO: Make API call to save attribute
    let payload = wrapperMetricForm({ ...selections });
    let fetchProps = {
      payload,
      type: "metric",
    };
    console.log("payload", payload);
    const updateAttributePromise = createAttribute(fetchProps);
    updateAttributePromise
      .then(() => {
        const readAllAdminViewsPromise = readAdminByOrg({});
        readAllAdminViewsPromise
          .then((data) => {
            let newData = {
              result: data.result.data,
              status: "success",
              messsage: "",
            };
            ReduxDispatcher(updateData("views", newData));

            ReduxDispatcher(updateUserScreen("isDashboardLoading", false));

            setShowForm(false);
          })
          .catch((json) => {
            let newData = {
              result: [],
              status: "error",
              messsage: "Views API failed",
            };
            ReduxDispatcher(updateData("views", newData));
            ReduxDispatcher(updateUserScreen("isDashboardLoading", false));
          });
      })
      .catch((json) => {
        ReduxDispatcher(updateUserScreen("isDashboardLoading", false));
      });
  };
  const handleUpdate = () => {
    let isGroupChanged = !isEqual(
      originalSelections.current.groups.value,
      selections.groups.value
    );
    ReduxDispatcher(updateUserScreen("isDashboardLoading", true)); // TODO: Make API call to save attribute
    let payload = wrapperMetricForm({ ...selections });
    let fetchProps = {
      payload,
      type: "metric",
    };
    console.log("payload", payload);
    const updateAttributePromise = updateAttribute(fetchProps);
    updateAttributePromise
      .then(() => {
        if (isGroupChanged) {
          let payload = wrapperGroup({ ...selections });
          let fetchProps = {
            payload,
          };
          const updateAttributeGroupPromise = updateAttributeGroup(fetchProps);
          updateAttributeGroupPromise
            .then(() => {
              const readAllAdminViewsPromise = readAdminByOrg({});
              readAllAdminViewsPromise
                .then((data) => {
                  let newData = {
                    result: data.result.data,
                    status: "success",
                    messsage: "",
                  };
                  ReduxDispatcher(updateData("views", newData));

                  ReduxDispatcher(
                    updateUserScreen("isDashboardLoading", false)
                  );

                  setShowForm(false);
                })
                .catch((json) => {
                  let newData = {
                    result: [],
                    status: "error",
                    messsage: "Views API failed",
                  };
                  ReduxDispatcher(updateData("views", newData));
                  ReduxDispatcher(
                    updateUserScreen("isDashboardLoading", false)
                  );
                });
            })
            .catch((json) => {
              ReduxDispatcher(updateUserScreen("isDashboardLoading", false));
            });
        } else {
          const readAllAdminViewsPromise = readAdminByOrg({});
          readAllAdminViewsPromise
            .then((data) => {
              let newData = {
                result: data.result.data,
                status: "success",
                messsage: "",
              };

              ReduxDispatcher(updateData("views", newData));

              // update screen
              const activeTab = "attributes";
              const value = { activeNav: "admin", activeTab };
              var action = updateUserScreen(null, value);
              ReduxDispatcher(action);

              ReduxDispatcher(updateUserScreen("isDashboardLoading", false));

              setShowForm(false);
            })
            .catch((json) => {
              let newData = {
                result: [],
                status: "error",
                messsage: "Views API failed",
              };
              ReduxDispatcher(updateData("views", newData));
              ReduxDispatcher(updateUserScreen("isDashboardLoading", false));
            });
        }
      })
      .catch((json) => {
        ReduxDispatcher(updateUserScreen("isDashboardLoading", false));
      });
  };

  const handleClickAway1 = () => {
    const { status: nameStatus, message: nameMessage } = validateAttributeName(
      name.value
    );

    // 1. If name is same as before, don't call the API
    if (originalSelections.current.name.value === name.value) {
      setName((prevState) => ({
        ...prevState,
        value: originalSelections.current.name.value,
        originalValue: originalSelections.current.name.value,
      }));
      const payload = {
        key: "name",
        value: { ...originalSelections.current.name },
      };
      const action = updateAttributeMetricFormWholeKey(payload);
      dispatch(action);
    } else {
      if (nameStatus === "invalid") {
        setName((prevState) => ({
          ...prevState,
          status: "error",
          message: nameMessage,
        }));
        var payload = {
          key: "name",
          value: {
            message: nameMessage,
            status: "invalid",
            value: name.value,
          },
        };
        var action = updateAttributeMetricFormWholeKey(payload);
        dispatch(action);
        // var snackbarPayload = {
        //   open: true,
        //   message: nameMessage,
        // };
        // var action = updateUserScreen("snackbar", snackbarPayload);
        // ReduxDispatcher(action);
      } else {
        // 3. If the group name is valid, call the API and reset state based on response
        setName((prevState) => ({
          ...prevState,
          status: "loading",
        }));
        console.log("name", name);

        // API to check group name availability
        const fetchProps = {
          orgViewReq: {
            organization: "OpenX",
            view: selections.activeView.value,
          },
          title: name.value,
          attributeType: "metric",
        };
        const nameCheckAttributePromise = nameCheckAttribute(fetchProps);
        nameCheckAttributePromise
          .then((reponseData) => {
            if (reponseData.status.statusCode === "200") {
              setName((prevState) => ({
                ...prevState,
                status: "success",
                originalValue: prevState.value,
              }));
              var payload = {
                key: "name",
                value: {
                  message: nameMessage,
                  status: "valid",
                  value: name.value,
                },
              };
              var action = updateAttributeMetricFormWholeKey(payload);
              dispatch(action);
            } else {
              // let snackbarPayload = {
              //   open: true,
              //   message: "Requested group name failed",
              // };
              // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
            }
          })
          .catch((json) => {
            setName((prevState) => ({
              ...prevState,
              status: "error",
              message: json.error,
            }));

            var payload = {
              key: "name",
              value: {
                message: json.error,
                status: "invalid",
                value: name.value,
              },
            };
            var action = updateAttributeMetricFormWholeKey(payload);
            dispatch(action);

            // let snackbarPayload = {
            //   open: true,
            //   message: json.error,
            // };
            // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
          });
      }
    }
  };
  const handleClickAway2 = () => {
    // 1. If group name is same as before, don't call the API
    // 2. If the group name is invalid, revert the name to original and no need to call API
    // 3. If the group name is valid, call the API and reset state based on response
    const { status: backendNameStatus, message: backendNameMessage } =
      validateBackendName(backendName.value);
    // 1. If group name is same as before, don't call the API
    if (originalSelections.current.backendName.value === backendName.value) {
      setBackendName((prevState) => ({
        ...prevState,
        value: originalSelections.current.backendName.value,
        originalValue: originalSelections.current.backendName.value,
      }));
      const payload = {
        key: "backendName",
        value: { ...originalSelections.current.backendName },
      };
      const action = updateAttributeMetricFormWholeKey(payload);
      dispatch(action);
    } else {
      if (backendNameStatus === "invalid") {
        // 2. If the group name is invalid, revert the name to original and no need to call API
        setBackendName((prevState) => ({
          ...prevState,
          status: "error",
          message: backendNameMessage,
        }));
        var payload = {
          key: "backendName",
          value: {
            message: backendNameMessage,
            status: "invalid",
            value: backendName.value,
          },
        };
        var action = updateAttributeMetricFormWholeKey(payload);
        dispatch(action);
        // var snackbarPayload = {
        //   open: true,
        //   message: backendNameMessage,
        // };
        // var action = updateUserScreen("snackbar", snackbarPayload);
        // ReduxDispatcher(action);
      } else {
        // 3. If the group name is valid, call the API and reset state based on response
        setBackendName((prevState) => ({
          ...prevState,
          status: "loading",
        }));

        // API to check group name availability
        const fetchProps = {
          orgViewReq: {
            organization: "OpenX",
            view: selections.activeView.value,
          },
          backendName: backendName.value,
          attributeType: "metric",
        };
        const nameCheckAttributePromise = nameCheckAttribute(fetchProps);
        nameCheckAttributePromise
          .then((reponseData) => {
            if (reponseData.status.statusCode === "200") {
              setBackendName((prevState) => ({
                ...prevState,
                status: "success",
                originalValue: prevState.value,
              }));
              var payload = {
                key: "backendName",
                value: {
                  message: backendNameMessage,
                  status: backendName.status,
                  value: backendName.value,
                },
              };
              var action = updateAttributeMetricFormWholeKey(payload);
              dispatch(action);
            } else {
              // let snackbarPayload = {
              //   open: true,
              //   message: "Requested group name failed",
              // };
              // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
            }
          })
          .catch((json) => {
            setBackendName((prevState) => ({
              ...prevState,
              status: "error",
              message: json.error,
            }));

            var payload = {
              key: "backendName",
              value: {
                message: json.error,
                status: "invalid",
                value: backendName.value,
              },
            };
            var action = updateAttributeMetricFormWholeKey(payload);
            dispatch(action);

            // let snackbarPayload = {
            //   open: true,
            //   message: json.error,
            // };
            // ReduxDispatcher(updateUserScreen("snackbar", snackbarPayload));
          });
      }
    }
  };
  const handleClickAway3 = () => {
    const { status: descStatus, message: descMessage } =
      validateAttributeDescription(description.value);

    if (
      originalSelections.current.actualPayload.value.description ===
      description.value
    ) {
      setDescription((prevState) => ({
        ...prevState,
        value: originalSelections.current.actualPayload.value.description,
        originalValue:
          originalSelections.current.actualPayload.value.description,
      }));
      const updatedActualPayload = {
        ...originalSelections.current.actualPayload,
        value: {
          ...originalSelections.current.actualPayload.value,
          description:
            originalSelections.current.actualPayload.value.description,
        },
      };
      const payload = {
        key: "actualPayload",
        value: updatedActualPayload,
      };
      const action = updateAttributeMetricFormWholeKey(payload);
      dispatch(action);
    } else {
      if (descStatus === "invalid") {
        setDescription((prevState) => ({
          ...prevState,
          status: "error",
          message: descMessage,
        }));
        var payload = {
          key: "actualPayload",
          value: {
            message: descMessage,
            status: "invalid",
            value: description.value,
          },
        };
        var action = updateAttributeMetricFormWholeKey(payload);
        dispatch(action);
      } else {
        setDescription((prevState) => ({
          ...prevState,
          status: "success",
          originalValue: prevState.value,
        }));
        const updatedActualPayload = {
          ...originalSelections.current.actualPayload,
          value: {
            ...originalSelections.current.actualPayload.value,
            description: description.value,
          },
        };
        var payload = {
          key: "actualPayload",
          value: updatedActualPayload,
        };
        var action = updateAttributeMetricFormWholeKey(payload);
        dispatch(action);
      }
    }
  };
  const isValid =
    isSelectionsInvalid({
      name: selections.name,
      backendName: selections.backendName,
      dataUnitType: selections.dataUnitType,
      measureType: selections.measureType,
    }).status === "valid";

  const areSelectionsEqual = isEqual(originalSelections.current, selections);

  const isUpdateDisabled = areSelectionsEqual ? true : !isValid ? true : false;

  // Styles

  const box1 = {
    padding: "0px 0px 0px 20px",
    height: "50px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
  };
  const box2 = {
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // padding: "50px 0px 0px 0px",
    // width: "500px",
    // paddingBottom: "150px",
    height: "calc(100% - 100px)",
    boxSizing: "border-box",
    width: "100%",
    overflow: "auto",
  };
  const fieldBox = {
    display: "flex",
    alignItems: "center",
    padding: "10px 20px",
    width: "100%",
    boxSizing: "border-box",
    // justifyContent: "space-between",
  };
  const jpStyle = {
    fontSize: "16px",
    color: "#46596a",
    height: "25px",
    fontWeight: "400",
    letterSpacing: "0.0125em",
    lineHeight: "1.5",
  };
  const fieldBox2 = {
    display: "flex",
    alignItems: "center",
    padding: "10px 20px",
    width: "520px",
    boxSizing: "border-box",
    justifyContent: "space-between",
  };
  const formTitleStyling = {
    height: "max-content",
    padding: "0px 10px 0px 0px",
  };
  const buttonContainer = {
    height: "50px",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px 10px 0px",
    justifyContent: "flex-end",
    boxSizing: "border-box",
  };

  const typographyStyle = {
    padding: "5px 10px 0px 0px",
  };
  const buttonStyle = { width: "auto", margin: { left: "3px", right: "3px" } };

  const textFieldCustomStyle = {
    textFieldFontSize: "12px",
    wrapperBorder: `1px solid ${themeColors["secondaryColorLighter"]}`,
    wrapperWidth: "300px",
    wrapperHeight: "25px",
    textFieldFontSize: "10px",
    margin: "0px 5px 0px 0px",
    placeholderFontSize: "10px",
  };
  const descriptionFieldCustomStyle = {
    textFieldFontSize: "12px",
    wrapperBorder: `1px solid ${themeColors["secondaryColorLighter"]}`,
    wrapperWidth: "400px",
    wrapperHeight: "25px",
    margin: "0px 10px 0px 0px",
    textFieldFontSize: "10px",
    placeholderFontSize: "10px",
  };

  return (
    <>
      <Box css={box1}>
        {(selections.crudType === "create" && (
          <p style={jpStyle}>
            {t("Add New Metric")} {t("for")} {selections.activeView?.value}
          </p>
        )) || (
          <p style={jpStyle}>
            {t("Update Metric:")} {selections.name?.value} {t("for")}{" "}
            {selections.activeView?.value}
            {/* </SigviewTypography> */}
          </p>
        )}
      </Box>

      <Box css={box2} className="sigview-styled-scroller">
        {selections.crudType === "update" && (
          <Box css={fieldBox}>
            <SigviewTypography style={typographyStyle}>
              {t("Groups:")}
            </SigviewTypography>

            {groupData.status === "loading" &&
              selections.crudType === "update" && (
                <Box
                  css={{
                    width: "400px",
                    paddingLeft: "110px",
                  }}
                >
                  <SigviewProgressBar />
                </Box>
              )}
            {groupData.status === "error" &&
              selections.crudType === "update" && (
                <ErrorHandler
                  message={t("Error in fetching group data")}
                  reloadFlag={true}
                  onReload={() => {}}
                />
              )}
            {groupData.status === "success" && (
              <Box
                css={{
                  paddingLeft: "97px",
                  width: "calc(100% - 140px)",
                }}
              >
                <SigviewMultiSelectDnD
                  title=""
                  data={groupsData.filter((row) =>
                    availableGroupData.includes(row._id)
                  )}
                  value={selections.groups?.value}
                  onChange={handleGroupsChange}
                />
              </Box>
            )}
          </Box>
        )}

        <Box css={{ ...fieldBox2, width: "524px" }}>
          <SigviewTypography style={typographyStyle}>Name:</SigviewTypography>
          <Box
            css={{
              display: "flex",
            }}
          >
            <SigviewTextFieldAsync
              value={name.value}
              onChange={handleNameChange}
              status={name.status}
              width="300px"
              onClickAway={handleClickAway1}
              customStyle={textFieldCustomStyle}
              tooltipTitle="Type and press enter to check if name is valid"
              errorFlag={selections.name.status === "invalid"}
              errorMessage={selections.name.message}
            />
            <Box
              css={{ height: "25px", display: "flex", alignItems: "center" }}
            >
              <SigviewHoverPopup
                title={t("Name Rules")}
                data={config.hardCoded.attributeNameRules}
              >
                <SigviewIcon className="material-icons-round" iconName="info" />
              </SigviewHoverPopup>
            </Box>
          </Box>
        </Box>

        <Box css={{ ...fieldBox2, width: "524px" }}>
          <SigviewTypography style={typographyStyle}>
            {t("Backend Name:")}
          </SigviewTypography>

          <Box
            css={{
              display: "flex",
            }}
          >
            <SigviewTextFieldAsync
              status={backendName.status}
              value={backendName.value}
              onChange={handleBackendNameChange}
              width="300px"
              onClickAway={handleClickAway2}
              readOnly={selections.crudType === "update"}
              customStyle={textFieldCustomStyle}
              tooltipTitle="Type and press enter to check if backend name is valid"
              errorFlag={selections.backendName.status === "invalid"}
              errorMessage={selections.backendName.message}
            />
            <Box
              css={{ height: "25px", display: "flex", alignItems: "center" }}
            >
              <SigviewHoverPopup
                title={t("Backend Name Rules")}
                data={config.hardCoded.attributeBackendNameRules}
              >
                <SigviewIcon className="material-icons-round" iconName="info" />
              </SigviewHoverPopup>
            </Box>
          </Box>
        </Box>

        <Box css={{ ...fieldBox2, width: "500px" }}>
          <SigviewTypography style={typographyStyle}>
            {t("Data Unit Type:")}
          </SigviewTypography>
          <SigviewSingleSelect
            value={selections?.dataUnitType?.value}
            minWidth="300px"
            data={config.hardCoded.attributes.metricDatatype}
            onChange={handleDataTypeChange}
            disabled={selections.crudType === "update"}
          />
        </Box>

        {(selections?.dataUnitType?.value === "percent" ||
          selections?.dataUnitType?.value === "currency") && (
          <Box css={{ ...fieldBox2, width: "501px" }}>
            <SigviewTypography style={typographyStyle}>
              {t("Data Unit Symbol:")}
            </SigviewTypography>
            <SigviewSingleSelect
              value={selections?.dataUnitSymbol?.value}
              minWidth="300px"
              data={
                config.hardCoded.attributes.metricDataSymbol[
                  selections?.dataUnitType?.value
                ]
              }
              onChange={handleDataSymbolChange}
              disabled={selections.crudType === "update"}
            />
          </Box>
        )}
        <Box css={{ ...fieldBox2, width: "500px" }}>
          <SigviewTypography style={typographyStyle}>
            {t("Measure Type:")}
          </SigviewTypography>
          <SigviewSingleSelect
            value={selections?.measureType?.value}
            minWidth="300px"
            data={config.hardCoded.attributes.measureType}
            disabled={selections.crudType === "update"}
            onChange={handleMeasureTypeChange}
          />
        </Box>

        <DimensionMetricViewDetails
          activeAttributeType={activeAttributeType}
          selections={selections}
        ></DimensionMetricViewDetails>

        <Box css={{ ...fieldBox2, width: "610px" }}>
          <SigviewTypography style={typographyStyle}>
            Description:
          </SigviewTypography>

          <Box
            css={{
              display: "flex",
            }}
          >
            <SigviewTextFieldAsync
              value={description.value}
              onChange={handleDescriptionChange}
              status={description.status}
              onClickAway={handleClickAway3}
              customStyle={descriptionFieldCustomStyle}
              tooltipTitle="Type and press enter to add description"
              errorFlag={selections.actualPayload.status === "invalid"}
              errorMessage={selections.actualPayload.message}
            />
            <Box
              css={{ height: "25px", display: "flex", alignItems: "center" }}
            >
              <SigviewHoverPopup
                title={t("Description Rules")}
                data={config.hardCoded.attributeDescriptionRules}
              >
                <SigviewIcon className="material-icons-round" iconName="info" />
              </SigviewHoverPopup>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box css={buttonContainer}>
        <SigviewButton
          title={selections.crudType === "create" ? "Save" : "Update"}
          variant="contained"
          onClick={selections.crudType === "create" ? handleSave : handleUpdate}
          style={{ ...buttonStyle, width: "max-content" }}
          disabled={isUpdateDisabled}
          customClassName={`${
            selections.crudType === "create"
              ? `Add-${name.value}-GA  add-new-metric-GA`
              : `Edit-${name.value}-GA  update-metric-GA`
          }`}
        />
      </Box>
    </>
  );
}

export default MetricForm;

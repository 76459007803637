import {
  getMeasureObjByOriginalID,
  getDimensionObjByID,
} from "../../utils/plotlyUtils";
import { sigviewFormat, capitalize } from "../../utils/utils";

const selectPrimaryCalendarData = (state, id) => {
  const wsForm = state.standaloneWs;
  const startEpoch = wsForm.timeFilters.value.selectedDatesQE.startDate;
  const endEpoch = wsForm.timeFilters.value.selectedDatesQE.endDate;
  const finalMetricChartId = `${id}_chart_${startEpoch}_${endEpoch}`;
  const dataQEAll = wsForm.dataQE.value;
  const primaryCalendarData = dataQEAll[finalMetricChartId];
  return primaryCalendarData;
};

const selectSecondaryCalendarData = (state, id) => {
  const wsForm = state.standaloneWs;
  const dataQEAll = wsForm.dataQE.value;
  const startEpoch = wsForm.timeFilters.value.selectedDatesQE.startDate;
  const endEpoch = wsForm.timeFilters.value.selectedDatesQE.endDate;
  const finalMetricChartId = `${id}_chart_${startEpoch}_${endEpoch}`;
  var secondaryCalendarData = [];
  for (const [key, value] of Object.entries(dataQEAll)) {
    if (key.startsWith(`${id}_chart_`) && key !== finalMetricChartId) {
      secondaryCalendarData.push(value);
    }
  }
  return secondaryCalendarData;
};

const selectMetricChartType = (state) =>
  state.standaloneWs.metricChartType.value;

const selectLoadingStatusForWsMetricChart = (state, metricId) => {
  const wsForm = state.standaloneWs;
  const dataQEAll = wsForm.dataQE.value;
  var allStatusForCurrMetric = [];
  for (const [key, value] of Object.entries(dataQEAll)) {
    if (key.startsWith(`${metricId}_chart_`)) {
      allStatusForCurrMetric.push(value.status);
    }
  }
  let partialLoading = allStatusForCurrMetric.some((el) => el === "loading");
  let partialSuccess = allStatusForCurrMetric.some((el) => el === "success");
  let fullLoading = allStatusForCurrMetric.every((el) => el === "loading");
  let fullSuccess = allStatusForCurrMetric.every((el) => el === "success");
  let anyError = allStatusForCurrMetric.some((el) => el === "error");

  return { partialLoading, partialSuccess, fullLoading, fullSuccess, anyError };
};

const selectMetricAccessor = (state, id) => {
  const plotlyMetrics = state.data.plotlyMetrics;
  const metricObj = getMeasureObjByOriginalID(id, plotlyMetrics);
  const cmPattern = /CM[0-9]{3}/g;
  const isCmFlag = cmPattern.test(id);
  const accessor = isCmFlag ? id : metricObj["measureID"];
  return accessor;
};

const selecteMetricChartGranularity = (state) =>
  state.standaloneWs.metricChartGranularity.value;

const selectSelectedTimezone = (state) =>
  state.standaloneWs.timeFilters.value.selectedTimezone;

const selectReloadEpoch = (state, id) =>
  state.standaloneWs.reloadEpochs.value[id];

const selectMetricObjectFromPlotlyMetrics = (state, id) => {
  const plotlyMetrics = state.data.plotlyMetrics;
  const metricObj = getMeasureObjByOriginalID(id, plotlyMetrics);
  return metricObj;
};

const selectMetricSelections = (state, id) => {
  const wsForm = state.standaloneWs;
  const selectionsObject = wsForm.selectedKpis.value[id];
  const granularity = wsForm.metricChartGranularity.value;
  const granDimObject = getDimensionObjByID(
    granularity,
    wsForm.utils.value.plotlyDimensions
  );
  let selections = {
    chartType: wsForm.metricChartType.value,
    dimensionFilters: wsForm.dimensionFilters.value || [],
    timeFilters: wsForm.timeFilters.value || {},
    metricFilters: [], // HARD CODED
    dimensionsList: [granDimObject],
    metricsList: selectionsObject.metricsList || [],
    orderById: selectionsObject.orderById || "",
    orderBy: selectionsObject.orderBy || "",
    orderByType: selectionsObject.orderByType || "",
    percentCalList: selectionsObject.percentCalList || [],
    granularity: granularity,
  };

  return selections;
};

const selectMetricChartYaxisRangeType = (state) => {
  // TODO as it's currently not in standaloneWs but making it configurable for metric charts
  return "auto"; // "auto" or "dynamic"
};

const selectMetricChartHeaderDetails = (state, id, themeColors, user) => {
  const plotlyMetrics = state.data.plotlyMetrics;
  const wsForm = state.standaloneWs;
  const selectionsObject = wsForm.selectedKpis.value[id];
  const dataQEAll = wsForm.dataQE.value;
  const metricData = dataQEAll[id];
  const metricObj = getMeasureObjByOriginalID(id, plotlyMetrics);
  const cmPattern = /CM[0-9]{3}/g;
  const isCmFlag = cmPattern.test(id);
  let infoTooltipStatus = false;

  if (
    user.reqMetadata.organization === "Kayzen" &&
    user.reqMetadata.view === "Inventory_Price"
  ) {
    infoTooltipStatus =
      (metricObj["displayFormula"] !== undefined &&
        metricObj["displayFormula"].startsWith("(Price Digest")) ||
      (metricObj["displayFormula"] !== undefined &&
        metricObj["displayFormula"].startsWith("(price digest"));
  }
  var obj = {};
  obj["isCmFlag"] = isCmFlag;
  obj["title"] = selectionsObject.metricsList[0]["_title"];
  obj["value"] = metricData.result.displayValue;
  obj["actualValue"] = dataQEAll[id].result.value;
  obj["totalValueStatus"] = metricData.status;
  obj["tickerdeltaPercentageStatus"] = `${
    dataQEAll[`${id}_deltaPercentage`].status
  }`;
  obj["tickertrueDeltaStatus"] = `${dataQEAll[`${id}_trueDelta`].status}`;
  obj["tickerChange"] = `${dataQEAll[`${id}_trueDelta`].result.displayValue} ${
    dataQEAll[`${id}_deltaPercentage`].result.displayValue
  }`;
  obj["tickerTotalCompareValue"] = `${
    dataQEAll[`${id}_percentageTotalValue`].result.displayValue
  } `;
  obj["tickertype"] =
    Number(dataQEAll[`${id}_trueDelta`].result.value) < 0
      ? "negative"
      : "positive";
  obj["isDeleteVisible"] = Object.keys(wsForm.selectedKpis.value).length > 1;
  // obj["infoFlag"] = infoTooltipStatus ? false : isCmFlag;
  // obj["infoTooltip"] = isCmFlag ? metricObj["displayFormula"] : "";
  obj["infoFlag"] = infoTooltipStatus ? false : true;
  obj["infoTooltip"] = metricObj["displayFormula"];
  obj["tickerIconName"] =
    obj["tickertype"] === "positive" ? "arrow_drop_up" : "arrow_drop_down";
  obj["showTickerChange"] = wsForm.timeFilters.value.isComparisonOn;
  obj["tickerStatus"] =
    obj["tickertrueDeltaStatus"] === "success" &&
    obj["tickerdeltaPercentageStatus"] === "success"
      ? true
      : false;
  obj["formattedValue"] = sigviewFormat(obj["actualValue"]);
  obj["tickerColor"] =
    obj["tickertype"] === "negative"
      ? themeColors["negChangeColor"]
      : themeColors["posChangeColor"];
  return obj;
};

const selectMetricCounterData = (state, id) => {
  const wsForm = state.standaloneWs;
  const dataQEAll = wsForm.dataQE.value;
  const metricCounterData = dataQEAll[id];
  return metricCounterData;
};

const selectDimTableData = (state, id) => {
  const wsForm = state.standaloneWs;
  const dataQEAll = wsForm.dataQE.value;
  const dimTableData = dataQEAll[id];
  return dimTableData;
};

const selectIsDimTableDeleteVisible = (state) =>
  Object.keys(state.standaloneWs.selectedDimensions.value).length > 1;

const selectDimTableSelections = (state, id) => {
  const wsForm = state.standaloneWs;
  const selectionsObject = wsForm.selectedDimensions?.value[id] || [];
  const selections = {
    chartType: "table", //! HARD CODED TO TABLE FOR ANALYZE selectionsObject.chartType,
    dimensionFilters: wsForm.dimensionFilters.value || [],
    timeFilters: wsForm.timeFilters.value || {},
    metricFilters: [], // HARD CODED
    dimensionsList: selectionsObject?.dimensionsList || [],
    metricsList: selectionsObject?.metricsList || [],
    orderById:
      selectionsObject.orderById === "_dimension"
        ? id === undefined
          ? selectionsObject?.dimensionsList[0]?._id
          : id
        : selectionsObject.orderById,
    orderBy: selectionsObject.orderBy || "asc",
    orderByType:
      selectionsObject.orderById === "_dimension"
        ? "id_only"
        : selectionsObject.orderByType,
    percentCalList: selectionsObject.percentCalList || [],
    dataLimit: wsForm.dataLimit.value,
    granularity: wsForm.metricChartGranularity.value,
  };

  return selections;
};

const selectDimTableFilterDetails = (state, id, themeColors) => {
  const selections = selectDimTableSelections(state, id);
  const filterAppliedOnDimTableRow = selections.dimensionFilters.find(
    (row) => row.metadata._id === selections.dimensionsList[0]._id
  );
  const filterAppliedOnDimTable =
    filterAppliedOnDimTableRow === undefined ? false : true;
  var filterAppliedOnDimTableType = "include";
  var isAdvancedFiltersApplied = false;
  if (filterAppliedOnDimTable) {
    filterAppliedOnDimTableType = filterAppliedOnDimTableRow.filterType;
    isAdvancedFiltersApplied =
      filterAppliedOnDimTableRow.advancedFilters.length > 0;
  }
  const filterIconColor =
    filterAppliedOnDimTableType === "include"
      ? themeColors["primaryColor"]
      : themeColors["secondaryColor"];
  const filterIconTooltip = `${capitalize(
    filterAppliedOnDimTableType
  )} Filters`;
  const isFullViewDisabled =
    isAdvancedFiltersApplied || filterAppliedOnDimTableType === "exclude";
  const fullViewTooltip = isFullViewDisabled
    ? "Full view disabled for exclude and advanced filters"
    : "Full View";

  const dimTableFilterDetails = {
    filterAppliedOnDimTable,
    filterAppliedOnDimTableRow,
    filterAppliedOnDimTableType,
    isAdvancedFiltersApplied,
    filterIconColor,
    filterIconTooltip,
    fullViewTooltip,
    isFullViewDisabled,
  };

  return dimTableFilterDetails;
};

export {
  selectPrimaryCalendarData,
  selectSecondaryCalendarData,
  selectMetricChartType,
  selectLoadingStatusForWsMetricChart,
  selectMetricAccessor,
  selecteMetricChartGranularity,
  selectSelectedTimezone,
  selectMetricObjectFromPlotlyMetrics,
  selectMetricChartYaxisRangeType,
  selectMetricChartHeaderDetails,
  selectMetricCounterData,
  selectMetricSelections,
  selectDimTableSelections,
  selectDimTableData,
  selectDimTableFilterDetails,
  selectReloadEpoch,
  selectIsDimTableDeleteVisible,
};

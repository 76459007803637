// * Import required libraries
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";

// * Import lib components
import { Box } from "@material-ui/core";

// * Import custom components
import AuthLoader from "../AuthLoader";

// * Import utils
import { config } from "../../config/config";

// * Import required contexts

// * Import hooks

// * Import APIs
import { sigviewSignInSso } from "../../services/api";

// * Import action creators
import { updateAuthLoading, updateChangeFlag } from "../../redux/actions";

// * Define required static variables
const initialIframeDetails = {
  status: "loading",
  result: { iframeUrl: "" },
  message: "",
};

function SiginInOpenX(props = {}) {
  const { dispatch: ReduxDispatcher = () => {} } = props;
  const history = useHistory();
  const location = useLocation();

  // * Define required states
  const [iframeDetails, setIframeDetails] = useState(initialIframeDetails);

  // * Define required side effects
  // Function to set valid iframeUrl based on window.location.pathname
  useEffect(() => {
    // * Make iframeUrl based on realm and instance_uid
    var search = location.search;
    var iframeUrl = "";
    var pathnameId = "";
    const openxIframeBaseUrl = config.hardCoded.openxIframeBaseUrl;
    if (search.indexOf("realm") > -1) {
      pathnameId = search.split("realm=").pop();
      iframeUrl = `${openxIframeBaseUrl}?realm=${pathnameId}`;
    } else if (search.indexOf("instance_uid") > -1) {
      pathnameId = search.split("instance_uid=").pop();
      iframeUrl = `${openxIframeBaseUrl}?instance_uid=${pathnameId}`;
    } else {
      iframeUrl = `${openxIframeBaseUrl}`;
    }
    setIframeDetails({ status: "success", message: "", result: { iframeUrl } });
  }, []);

  // Function to add event listener to
  useEffect(() => {
    function handleSignIn(token) {
      var decodedJwt = jwt_decode(token);
      console.log("decodedJwt", decodedJwt);
      const email = decodedJwt.email.toLowerCase();
      const clientId = decodedJwt.clientId;
      const cryptoKey = decodedJwt.cryptokey;
      const userType = decodedJwt.userType;
      const name = decodedJwt.name;
      const instances = decodedJwt.instances;
      const payload = {
        email,
        clientId,
        cryptoKey,
        userType,
        token: token,
      };
      const headers = {
        Authorization: cryptoKey,
        clientid: decodedJwt.clientId,
        useremail: email,
        userType,
      };
      const fetchProps = {
        payload,
        headers,
      };
      const sigviewSignInSsoPromise = sigviewSignInSso(fetchProps);
      sigviewSignInSsoPromise
        .then((response) => {
          if (response.status === 401) throw new Error();
          return response.json();
        })
        .then((responseData) => {
          console.log("responseData1", responseData);
          if (responseData.statusCode === "200") {
            // ! Currently, embeddedInfo.isEmbeddedUser is true only for non-openx embedded users (like kayzen
            // Update embeddedInfo in redux
            // var data = {
            //   embeddedInfo: {
            //     isEmbeddedUser: true,
            //     email: params.get("email"),
            //     clientId: params.get("clientId"),
            //     cryptoKey: params.get("cryptoKey"),
            //     status: "success",
            //     message: "",
            //   },
            // };
            // handleUpdateUserInfo(data);

            // * For OpenX users, we do:
            // 1. Set values to local storage
            // 2. Re-trigger authForm
            // 3. Redirect to home page

            // 1. Set to values to local storage
            const satellizer_token = responseData.token;
            const openxUserDataInfo = [
              {
                token: decodedJwt.token,
                name: name,
                instances: instances[0],
                userType: userType,
              },
            ];
            localStorage.setItem(
              "openxUserData",
              JSON.stringify(openxUserDataInfo)
            );
            localStorage.setItem("satellizer_token", satellizer_token);
            localStorage.setItem("user_email", email);
            const payload = {
              status: "success",

              xAuthToken: {
                "X-Auth-Token": satellizer_token,
                instances: "",
              },
              isTokenPresent: true,
              isTokenValid: true,
            };
            const action = updateAuthLoading(payload);
            ReduxDispatcher(action);

            // // 2. Re-trigger authForm
            // setTimeout(() => {
            //   ReduxDispatcher(updateChangeFlag("authFormEpoch", Date.now()));
            // }, 0);

            // 3. Redirect to home page
            history.push(config.hardCoded.rootNonSigviewUrlOnLogin);
          } else if (responseData.statusCode === "401") {
            throw new Error();
          }
        })
        .catch((json) => {
          console.error("UI ERROR");
          console.groupCollapsed("DETAIL");
          console.log("Component ---> SignInOpenX.js");
          console.log("Function --> handleSignIn");
          console.log("json", json);
          console.groupEnd();

          // ! Currently, embeddedInfo.isEmbeddedUser is true only for non-openx embedded users (like kayzen)
          // Update embeddedInfo in redux
          //   var data = {
          //     embeddedInfo: {
          //       isEmbeddedUser: true,
          //       status: "error",
          //       message: "",
          //     },
          //   };
          //   handleUpdateUserInfo(data);

          // Redirect to sso error page
          history.push("/openxLoginErrorPage");
        });
    }

    function receiveMessageFromOpenxSSO(event) {
      const runFlag =
        event.data !== "" &&
        event.data !== undefined &&
        event.data.value !== undefined;
      if (runFlag) {
        if (event.data.value !== "Internal Server Error") {
          var token = event.data.value;
          handleSignIn(token);
        } else {
          history.push("/openxLoginErrorPage");
        }
      }
    }

    // Add event listener to capture message from OpenX SSO SignIn
    window.addEventListener("message", receiveMessageFromOpenxSSO, true);

    return () => {
      // Remove event listener on unmount
      window.removeEventListener("message", receiveMessageFromOpenxSSO, true);
    };
  }, []);

  // * Define required static variables
  const openxIframeContainerStyle = { width: "100vw", height: "100vh" };

  // * DEBUGGER
  // console.groupCollapsed("SignInOpenX.js");
  // console.log("iframeDetails", iframeDetails);
  // console.groupEnd();

  return (
    <>
      {iframeDetails.status === "loading" && <AuthLoader />}
      {iframeDetails.status === "success" && (
        <>
          <Box css={openxIframeContainerStyle}>
            <iframe
              id="iFrameLink"
              title="Login Openx"
              src={iframeDetails.result.iframeUrl}
              width="100%"
              height="100%"
            />
          </Box>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(SiginInOpenX);

// Import required libraries
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

// Import custom components
import Loader from "../Loader/Loader";
import SigviewButton from "../Common/SigviewButton";
import SigviewSingleSelect from "../Common/SigviewSingleSelect";
import ErrorHandler from "../ErrorHandler/ErrorHandler";

// Import config
import { config } from "../../config/config";

// Import action creators and reducers

// Import utils
import { wrapperSavedReport } from "../../utils/reportUtils";
import { masterTrackGaEvent } from "../../services/ga";

// Import data

// Import API functions
import { getData, getComparisonData } from "../../services/api";

// Import plotly utils
import { unwrapperGetDataToTableData } from "../../utils/plotlyUtils";
import {
  makeReportManagerQuery,
  getAllColumnTitlesFromReportFormData,
} from "../../utils/reportUtils";
import { checkIfElementIsOverflowing } from "../../utils/utils";

// Defining required constants
const initialData = { status: "loading", message: "", data: [] };
const chartContainerId = "reportManagerPreviewTableContainer";
const initalChartContainerDim = {
  width: document.getElementById(chartContainerId)?.offsetWidth || 20,
  height: document.getElementById(chartContainerId)?.offsetHeight - 10 || 20,
};
const rowCountArr = [
  { id: "10", name: "10" },
  { id: "25", name: "25" },
  { id: "50", name: "50" },
  { id: "100", name: "100" },
];

function ReportManagerPreview(props) {
  const { t } = useTranslation();
  const {
    user,
    allData,
    reportFormData,
    activeReportFormData,
    activeReport,
    globalFilters,
  } = props;

  //Defining required state
  const [sampleReportData, setSampleReportData] = useState(initialData);
  const [reloadData, setReloadData] = useState(false);
  const [chartContainerDim, setChartContainerDim] = useState(
    initalChartContainerDim
  );
  const [rowCount, setRowCount] = useState("10");
  const [queryExpanded, setQueryExpanded] = useState(false);
  const [showQueryToggleButton, setShowQueryToggleButton] = useState(false);

  //Fetch data
  useEffect(() => {
    //Making fetch call
    const wrapperProps = {
      activeReportFormData: {
        reportFormData: reportFormData,
        reportPayload: activeReportFormData.reportPayload,
      },
      user: user,
      allData: allData,
      activeReport,
      globalFilters: globalFilters,
    };
    const fetchPayload = wrapperSavedReport(wrapperProps);
    //Make a copy of payload
    const payloadCopy = { ...fetchPayload.recentDownloadRecord.payload };

    //Make fetch call using axios
    const source = axios.CancelToken.source();
    const payload = {
      _id: "",
      emailId: user?.reqMetadata?.email,
      orgViewReq: {
        organization: user?.reqMetadata?.organization,
        view: user?.reqMetadata?.view,
      },
      chartObject: {
        ...payloadCopy,
        metadata: { ...payloadCopy.metadata, dataLimit: parseInt(rowCount) },
      },
    };
    if (
      reportFormData.isComparisonOn &&
      reportFormData.reportRowCount !== "all_records"
    ) {
      let fetchProps = {
        payload,
        cancelToken: source.token,
      };
      const getComparisonDataPromise = getComparisonData(fetchProps);
      getComparisonDataPromise
        .then((responseDate) => {
          try {
            setSampleReportData({
              status: "success",
              message: "",
              data: unwrapperGetDataToTableData({
                payload,
                allData,
                rawData: responseDate.result.data,
                user,
                isComparisonOn: reportFormData.isComparisonOn,
                abbrFlag: true,
              }),
            });
          } catch (error) {
            console.groupCollapsed("UI ERROR");
            console.log(error);
            console.groupEnd();
            setSampleReportData({
              status: "error",
              message: config.hardCoded.uiErrorMessage,
              data: [],
            });
          }
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            setSampleReportData({
              status: "error",
              message: json.error,
              data: [],
            });
          }
        });
    } else {
      let fetchProps = {
        payload,
        cancelToken: source.token,
      };
      const getDataPromise = getData(fetchProps);
      getDataPromise
        .then((responseDate) => {
          try {
            setSampleReportData({
              status: "success",
              message: "",
              data: unwrapperGetDataToTableData({
                payload,
                allData,
                rawData: responseDate.result.data,
                user,
                isComparisonOn: false,
                abbrFlag: true,
              }),
            });
          } catch (error) {
            console.groupCollapsed("UI ERROR");
            console.log(error);
            console.groupEnd();
            setSampleReportData({
              status: "error",
              message: config.hardCoded.uiErrorMessage,
              data: [],
            });
          }
        })
        .catch((json) => {
          if (json.error !== config.hardCoded.queryCancelled) {
            setSampleReportData({
              status: "error",
              message: json.error,
              data: [],
            });
          }
        });
    }
    return () => {
      setSampleReportData(initialData);
      //Cancel all previous fetch calls
      if (source) source.cancel();
    };
  }, [reloadData]);

  //Add resize event listener
  useEffect(() => {
    function handleTableResize() {
      setChartContainerDim({
        width: document.getElementById(chartContainerId)?.offsetWidth || 20,
        height:
          document.getElementById(chartContainerId)?.offsetHeight - 10 || 20,
      });
    }
    window.addEventListener("resize", handleTableResize);
    //Call the function as well
    handleTableResize();

    return function cleanupListener() {
      window.removeEventListener("resize", handleTableResize);
    };
  }, [sampleReportData]);

  //Update showQueryToggleButton based on if the query is overflowing
  useEffect(() => {
    const queryContainer = document.getElementById(
      "reportManagerPreviewSqlQueryContainer"
    );
    setShowQueryToggleButton(checkIfElementIsOverflowing(queryContainer));
  }, []);

  //Defining requried functions
  const buildQuery = () => {
    const query = makeReportManagerQuery(reportFormData, allData);
    const obj = {
      __html: `<span class="report-manager-sql-query">${query}</span>`,
    };
    return obj;
  };

  const handleQueryToggleChange = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ReportManager",
      action: "PreviewQuery",
      label: queryExpanded ? "ShowLess" : "ShowMore",
    });
    setQueryExpanded((prevState) => !prevState);
  };

  const handleQueryRowCountChange = (newValue) => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ReportManager",
      action: "PreviewSampleRecords",
      label: newValue,
    });
    setRowCount(newValue);
  };

  const handleExecute = () => {
    // Google Analytics Event - Master
    masterTrackGaEvent({
      category: "ReportManager",
      action: "PreviewExecuteQuery",
      label: rowCount,
    });
    setReloadData(!reloadData);
  };

  //Defining requried variables
  const tableData = sampleReportData.data;
  const tableStyle = {
    maxWidth: chartContainerDim.width,
    maxHeight: "500px",
  };
  const allColumns = getAllColumnTitlesFromReportFormData(reportFormData);
  const queryExpandedClass = queryExpanded ? "expanded" : "collapsed";
  const queryToggleButtonText = queryExpanded ? "Show Less..." : "Show More...";

  //DEBUGGING
  // console.groupCollapsed("REPORT MANAGER PREVIEW");
  // console.log("props", props);
  // console.log("sampleReportData", sampleReportData);
  // console.log("reportFormData", reportFormData);
  // console.groupEnd();
  const renderTableHeader = () => {
    return (
      <thead>
        <tr>
          {tableData.headers.map((header) => (
            <th className="sigview-table-head-cell" key={header.id}>
              {header.name}
            </th>
          ))}
        </tr>
      </thead>
    );
  };
  const renderTableRows = () => {
    return tableData.data.map((row) => (
      <tr className="sigview-table-row" key={row.id}>
        {row.colData.map((cell) => {
          const isChangeMetric = ["change", "per_change"].includes(cell.type);
          const changeType = isChangeMetric
            ? parseFloat(cell.actualValue) < 0
              ? "neg-change"
              : "pos-change"
            : "";
          const iconName = isChangeMetric
            ? changeType === "neg-change"
              ? "arrow_drop_down"
              : "arrow_drop_up"
            : "";
          const className = `sigview-table-cell ${cell.type} ${changeType}`;
          return (
            <td key={cell.id} className={className}>
              {isChangeMetric ? (
                <div className="cell-change-container">
                  <span className="material-icons cell-change-icon">
                    {iconName}
                  </span>
                  <span className="cell-change-value">{cell.name}</span>
                  <span className="cell-change-icon-placeholder"></span>
                </div>
              ) : (
                <span className="sigview-table-cell-value">{cell.name}</span>
              )}
            </td>
          );
        })}
      </tr>
    ));
  };

  return (
    <div className="report-manager-preview-container">
      <div
        className="report-manager-form-row-preview full"
        id="reportMangerQueryContainer"
      >
        <div className="report-manager-form-item-container full">
          <div className="label-container">
            <p className="label">{t("Query:")}</p>
          </div>
          <div className="input-item-container full padded">
            <div
              className={`report-manager-preview-sql-query-container ${queryExpandedClass}`}
              id="reportManagerPreviewSqlQueryContainer"
              dangerouslySetInnerHTML={buildQuery()}
            ></div>
            {showQueryToggleButton && (
              <div className="report-manager-form-ad-hoc-container">
                <SigviewButton
                  variant="containedLighter"
                  onClick={handleQueryToggleChange}
                  title={queryToggleButtonText}
                  customClassName={`${
                    queryToggleButtonText === "Show More..."
                      ? "ReportPreviewQueryShowMore-GA"
                      : "ReportPreviewQueryShowLess-GA"
                  }`}
                  // style={{  margin: { left: "3px", right: "3px" } }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="report-manager-form-row-preview">
        <div
          className="report-manager-form-item-container"
          id="sampleRowCountForReportManagerQuery"
        >
          <div className="label-container">
            <p className="label">{t("Sample Row Count:")}</p>
          </div>
          <div
            className="input-item-container transparent"
            style={{
              background: "#f5f7ff",
              border: "none",
            }}
          >
            <SigviewSingleSelect
              value={rowCount}
              data={rowCountArr}
              onChange={handleQueryRowCountChange}
              border="1px solid #edf1ff"
              minWidth="100px"
              height="26px"
              fontSize="11px"
              // margin={{ right: "5px" }}
            />
          </div>
        </div>
        <div className="report-manager-form-item-container label-less">
          <div className="input-item-container label-less transparent">
            <SigviewButton
              variant="contained"
              onClick={handleExecute}
              title="Execute"
              disabled={false}
              iconFlag={true}
              iconProps={{
                className: "material-icons-round",
                iconName: "play_arrow",
              }}
              style={{ width: "auto" }}
              customClassName="ReportPreviewExecuteQuery-GA"
            />
          </div>
        </div>
      </div>

      <div className="report-manager-form-row-preview full" id="reportPreview">
        <div className="report-manager-form-item-container full">
          <div className="label-container">
            <p className="label">{t("Report Preview:")}</p>
          </div>
          <div className="input-item-container full transparent">
            <>
              <div id={chartContainerId}>
                <div
                  className="sigview-table-container report-manager-preview"
                  style={tableStyle}
                >
                  <table className="sigview-table">
                    {sampleReportData.status === "success" &&
                      tableData.headers.length > 0 &&
                      renderTableHeader()}
                    <tbody>
                      {sampleReportData.status === "loading" && (
                        <tr className="sigview-table-row">
                          <td colSpan={allColumns.length} className="padded">
                            <Loader />
                          </td>
                        </tr>
                      )}
                      {sampleReportData.status === "error" && (
                        <tr className="sigview-table-row">
                          <td colSpan={allColumns.length} className="padded">
                            <ErrorHandler
                              message={sampleReportData.message}
                              reloadFlag={true}
                              onReload={() => setReloadData(!reloadData)}
                              layout="reportScreen"
                            />
                          </td>
                        </tr>
                      )}
                      {sampleReportData.status === "success" &&
                      tableData.data.length > 0
                        ? renderTableRows()
                        : sampleReportData.status === "success" && (
                            <tr>
                              <td colSpan={allColumns.length}>
                                No data available
                              </td>
                            </tr>
                          )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
      {/* <div className="feature-wip-container">
        <section className="feature-wip-container-image"></section>
        <p className="feature-wip-container-title">
          This feature is not yet available. Thank you for your patience.
        </p>
      </div> */}
    </div>
  );
}

export default ReportManagerPreview;
